import { getDateInLondonTimezone } from './getDateInLondonTimezone'
import { sortLatestDeal } from './sortLatestDeal'
import { Moment } from 'moment'

export interface Termsheet {
  termsheetId: string
  dealId: string
  draft: string
  offeringType: string
  distribution: string
  pricingDate: string
  itemPricedAtDate: string
  offeringSharePriceRange: string
  subscriptionRatio: string
  closeOfBookbuildingDate: string
  endOfSubscriptionPeriodDate: string
  issuerName: string
  publishingBankName: string
  dateTimeDealRecived?: string
  updateAtDate?: string
  itemCreatedAtDateClient?: string
  itemCreatedAtDate?: string
  available?: boolean
}

export interface SortedTermsheet {
  id: number
  name: string
  date: Moment | null
  taasCreatedAtDate: Moment | null
  termsheetObject: Termsheet
}

const findTermsheetDateString = (termsheet: Termsheet): string | null => termsheet.updateAtDate ?? termsheet.itemCreatedAtDateClient ?? termsheet.itemCreatedAtDate ?? null
const findTaasCreatedAtDate = (termsheet: Termsheet): string | null => termsheet.dateTimeDealRecived ?? termsheet.itemCreatedAtDateClient ?? termsheet.itemCreatedAtDate ?? null

export const generateTermsheetOptionsArray = (termsheetArray: Termsheet[]): SortedTermsheet[] => {
  // 1) Sort deals by updateAtDate
  // 2) if updateAtDate not available sort by itemCreatedAtDate
  // 3) display sorted array in termsheet dropdown
  return sortLatestDeal(termsheetArray).map((item, index) => {
    const dateStr = findTermsheetDateString(item)
    const taasDateStr = findTaasCreatedAtDate(item)
    return {
      id: index,
      name: item.publishingBankName ?? `Public ${index + 1}`,
      date: dateStr === null ? null : getDateInLondonTimezone(dateStr),
      taasCreatedAtDate: taasDateStr === null ? null : getDateInLondonTimezone(taasDateStr),
      termsheetObject: item
    }
  })
}
