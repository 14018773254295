import { API } from './aws-api'

export interface StockExchangeDto {
  name: string
  marketIdentifierCode: string
  countryAlpha3ISOCode: string
  sortOrder?: number
  enableSaudiDistribution?: boolean
}
export interface StockExchange {
  displayName: string
  marketIdentifierCode: string
  countryAlpha3ISOCode: string
  sortOrder?: number
  enableSaudiDistribution?: boolean
}

const stockExchangeDtoToStockExchange = (exchange: StockExchangeDto): StockExchange => ({
  displayName: exchange.name,
  marketIdentifierCode: exchange.marketIdentifierCode,
  countryAlpha3ISOCode: exchange.countryAlpha3ISOCode,
  sortOrder: exchange?.sortOrder,
  enableSaudiDistribution: exchange?.enableSaudiDistribution
})

export async function getStockExchanges (): Promise<StockExchange[]> {
  const exchanges = await API<StockExchangeDto[]>().get({ path: '/v2/stock-exchanges' })
  return exchanges.map(stockExchangeDtoToStockExchange)
}
