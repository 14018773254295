/* eslint-disable react/no-unused-prop-types */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react'
import PropTypes from 'prop-types'

import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'

import { ThemeProvider } from '@material-ui/core'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { datePickerTheme } from './datePickerTheme'
import { removeDoubleStringify } from '../../utils/removeDoubleStringify'

export function DatePickerComponent ({ handleDateChange, selectedDate, isPickerOpen, setIsPickerOpen }) {
  const [isOpen, setIsOpen] = React.useState(false)

  const open = isPickerOpen || isOpen

  const handleOpen = () => (isPickerOpen ? setIsPickerOpen(true) : setIsOpen(true))

  const handleClose = () => (isPickerOpen ? setIsPickerOpen(false) : setIsOpen(false))

  const value = selectedDate === 'TBA' ? null : removeDoubleStringify(selectedDate)

  const handleChange = (e) => handleDateChange(e.toUTCString())

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <ThemeProvider theme={datePickerTheme}>
        <DatePicker
          autoOk
          open={open}
          onOpen={handleOpen}
          onClose={handleClose}
          format='dd-MMM-yyyy'
          value={value || null}
          onChange={handleChange}
          inputProps={{
            disabled: true
          }}
        />
        <ExpandMoreIcon onClick={() => setIsOpen(true)} />
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  )
}

DatePickerComponent.propTypes = {
  handleDateChange: PropTypes.func.isRequired,
  selectedDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isPickerOpen: PropTypes.bool,
  setIsPickerOpen: PropTypes.func,
  name: PropTypes.any,
  defaultValue: PropTypes.any
}
