
import React, { useEffect } from 'react'

export function ConfigLoadErrorPage (): JSX.Element {
  useEffect(() => {
    const timer = setInterval(() => {
      window.location.reload()
    }, 30000)
    return () => clearInterval(timer)
  }, [])

  return (
    <div className='mt-24 text-center'>
      <h2>This page isn't working right now.</h2>
    </div>
  )
}
