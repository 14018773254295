import React from 'react'
import { FilterIcon } from '@heroicons/react/outline'
import { observer } from 'mobx-react-lite'
import { useStores } from 'store/rootStore'
import { Button } from '@ppui/ui-components'
import { useFilterStoreInit } from 'lib/hooks'

export const CountryFilterButton: React.FC = observer((): JSX.Element => {
  useFilterStoreInit()
  const { filterStore, countriesStore } = useStores()
  const isFilterApplied = filterStore.isFilterApplied
  if (!filterStore.isFilterReady || countriesStore.isCountriesEmpty) return <></>
  return (
    <Button
      theme={isFilterApplied ? 'secondary' : 'primary'}
      onClick={() => { filterStore.isFilterOpen = !filterStore.isFilterOpen }}
      label={isFilterApplied ? 'Filters Applied' : 'Filter'}
      shadow
      size='big'
      type='button'
      leftIcon={<FilterIcon />}
      dataQa='CountryFilter-Filter'
    />
  )
})
