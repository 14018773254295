import React, { useCallback } from 'react'
import { FilterColumnProps } from 'components/CountryFilter/types'
import { PlusIcon, MinusIcon } from '@heroicons/react/solid'
import { CountryItem, FilterDisplay } from 'store/types'
import { FilterItem } from 'components/CountryFilter/FilterItem'
import { partialObject } from 'ramda'
import { observer } from 'mobx-react-lite'
import { useStores } from 'store/rootStore'
import { useCountryFilter } from 'components/CountryFilter/useCountryFilter'

const FilterColumn: React.FC<FilterColumnProps> = observer(({
  parentId,
  selectedRegion,
  subRegion,
  isOpen,
  setIsOpen,
  version
}) => {
  const subRegId = `${parentId}-${btoa(subRegion)}`
  const { countriesStore, dealsOrTermsheetsStore } = useStores()
  const { selectedCountries, selectedSubregions, onChange: onCheckboxClick } = useCountryFilter()
  const countries = countriesStore.getCountriesBySubRegion(subRegion)
  const countriesWithActiveDeals = dealsOrTermsheetsStore.getAmountOfActiveDealsByCountriesAndRegion(selectedRegion, version)

  const activeCountries = countriesStore.filterCountriesByDisplayType(
    countries,
    countriesStore.getFilterPredicateByActiveDeals(countriesWithActiveDeals)
  )
  const expandedCountries = countriesStore.filterCountriesByDisplayType(countries, FilterDisplay.EXPANDED)
  const allVisibleCountries = isOpen ? [...activeCountries, ...expandedCountries] : [...activeCountries]

  const renderElem = useCallback((countries: CountryItem[]) => countries.map(({ code, name }) => {
    const id = `${parentId}-${code}`
    return (
      <FilterItem
        id={id}
        key={id}
        version={version}
        value={selectedCountries.includes(code)}
        name={name}
        type='countries'
        onCheckboxClick={partialObject(onCheckboxClick as never, { subRegion })}
        code={code}
      />
    )
  }), [onCheckboxClick, parentId, selectedCountries, subRegion, version])

  if (activeCountries.length === 0 && expandedCountries.length === 0) return null
  return (
    <div>
      <span className='font-bold'>
        <FilterItem
          id={subRegId}
          key={subRegId}
          version={version}
          value={selectedSubregions.includes(subRegion)}
          name={subRegion}
          type='subRegions'
          onCheckboxClick={partialObject(onCheckboxClick as never, { allVisibleCountries })}
          code={subRegion}
        />
      </span>

      <div className='flex flex-col mb-1'>
        {renderElem(activeCountries)}
        {isOpen && renderElem(expandedCountries)}
      </div>
      {expandedCountries.length > 0 &&
        (isOpen
          ? <MinusIcon
              data-qa='Collapsed-Icon'
              className='h-3 mr-1 text-gray-500 cursor-pointer'
              onClick={() => setIsOpen(false)}
            />
          : <PlusIcon
              data-qa='Expanded-Icon'
              className='h-3 mr-1 text-gray-500 cursor-pointer'
              onClick={() => setIsOpen(true)}
            />)}
    </div>
  )
})

export default FilterColumn
