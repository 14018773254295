import { SyndicateBankerDto } from 'services/organizations'
/* eslint-disable @typescript-eslint/strict-boolean-expressions */

export function getSyndicateDeskOptions (bankers: SyndicateBankerDto[]): any {
  if (bankers == null || bankers.length === 0) {
    return {
      'Not available': {
        designatedBankerEmail: '---',
        designatedBankerPhone: '---'
      }
    }
  }

  const bankersObject = {}
  bankers.forEach((banker: SyndicateBankerDto) => {
    bankersObject[banker?.name] = {
      designatedBankerEmail: banker?.email,
      designatedBankerPhone: banker?.phone
    }
  })

  return bankersObject
}
