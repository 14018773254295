import { DateTime } from 'luxon'
import { DealOrTermsheetDTO } from 'services/deals'
import { IPO, ABO, SPAC, OTH, RI, EQL } from 'routes/DashboardPage/types'
import { OFFERING_TYPE } from 'constants/index'

const parseAndFormatDate = (date: string | null): string => {
  const parsedDate = DateTime.fromHTTP(date ?? '')
  return parsedDate.isValid ? parsedDate.toFormat('dd-MMM-yyyy') : 'TBA'
}

function sharedTransform (deal: DealOrTermsheetDTO): {
  uid: string
  id: string
  available: boolean
  draft: boolean
  issuerName: string
  publishingBanksNames: string[]
  pricingDate: string | null
  region: string
  isOrderExists: boolean
  dealV2Id: string
  termsheetId?: string
} {
  return {
    uid: deal.termsheetId ?? deal.dealId,
    id: deal.dealId,
    available: deal.canViewRelatedTermsheet ?? true,
    draft: deal.draft ?? false,
    issuerName: deal.issuerName,
    pricingDate: deal.pricingDate ?? null,
    publishingBanksNames: deal.publishingBanksNames ?? [],
    region: deal.region,
    isOrderExists: deal.isOrderExists,
    dealV2Id: deal.dealV2Id,
    termsheetId: deal.termsheetId
  }
}

export function IPOTransform (raw: DealOrTermsheetDTO): IPO {
  return {
    ...sharedTransform(raw),
    type: 'IPO',
    closeOfBookbuildingDate: parseAndFormatDate(raw.closeOfBookbuildingDate)
  }
}

export function EQLTransform (raw: DealOrTermsheetDTO): EQL {
  return {
    ...sharedTransform(raw),
    type: 'EQL',
    closeOfBookbuildingDate: parseAndFormatDate(raw.closeOfBookbuildingDate)
  }
}

export function SPACTransform (raw: DealOrTermsheetDTO): SPAC {
  return {
    ...sharedTransform(raw),
    type: 'SPAC',
    closeOfBookbuildingDate: parseAndFormatDate(raw.closeOfBookbuildingDate)
  }
}

export function ABOTransform (raw: DealOrTermsheetDTO): ABO {
  return {
    ...sharedTransform(raw),
    type: 'ABO',
    closeOfBookbuildingDate: parseAndFormatDate(raw.closeOfBookbuildingDate)
  }
}

export function OTHTransform (raw: DealOrTermsheetDTO): OTH {
  return {
    ...sharedTransform(raw),
    type: 'OTH',
    closeOfBookbuildingDate: parseAndFormatDate(raw.closeOfBookbuildingDate)
  }
}

export function RITransform (raw: DealOrTermsheetDTO): RI {
  return {
    ...sharedTransform(raw),
    type: 'RI',
    endOfSubscriptionPeriodDate: parseAndFormatDate(raw.endOfSubscriptionPeriodDate)
  }
}

export const OFFERING_TYPE_TRANSFORM_MAPPING = {
  [OFFERING_TYPE.IPO]: IPOTransform,
  [OFFERING_TYPE.ABO]: ABOTransform,
  [OFFERING_TYPE.OTH]: OTHTransform,
  [OFFERING_TYPE.SPAC]: SPACTransform,
  [OFFERING_TYPE.RI]: RITransform,
  [OFFERING_TYPE.EQL]: EQLTransform
}
