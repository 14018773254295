import { Dialog, Transition } from '@headlessui/react'
import { XCircleIcon } from '@heroicons/react/solid'
import classNames from 'classnames'
import React, { Fragment } from 'react'

export interface ModalProps{
  className?: string
  children: React.ReactNode
  openHook: [boolean, (bool: boolean) => void ]
  closeButton?: JSX.Element
}

export interface CloseButtonProps{
  className?: string
  setOpen: (boolean) => void
}

const CloseButton = ({ setOpen, ...props }: CloseButtonProps): JSX.Element => (
  <button
    type='button'
    className='bg-white rounded-md text-black-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-indigo-500'
    onClick={() => setOpen(false)}
  >
    <span className='sr-only'>Close</span>
    <XCircleIcon className='h-6 w-6' aria-hidden='true' />
  </button>)

export function CenteredModal ({ openHook, children, className, ...props }: ModalProps): JSX.Element {
  const [open, setOpen] = openHook

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as='div' auto-reopen='true' className='fixed z-1301 inset-0 overflow-y-auto ' onClose={setOpen}>
        <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className='hidden sm:inline-block sm:align-middle sm:h-screen' aria-hidden='true'>
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            enterTo='opacity-100 translate-y-0 sm:scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
          >
            <div
              className={
                classNames(
                  'inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6',
                  className
                )
            }
            >
              <div className='hidden sm:block absolute top-0 right-0 pt-4 pr-4'>
                <CloseButton setOpen={setOpen} />
              </div>
              {children}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
