import { Transition } from '@headlessui/react'
import React, { Fragment, useEffect } from 'react'
import { XIcon } from '@heroicons/react/solid'

interface NotificationProps{
  children: React.ReactNode
  show: boolean
  setShow: React.Dispatch<React.SetStateAction<boolean>>
  backgroundColor?: string
  autoDismiss?: number
}

export const Notification = ({ children, show, setShow, backgroundColor, autoDismiss }: NotificationProps): JSX.Element => {
  const bgColor = backgroundColor ?? 'bg-white-50'

  useEffect(() => {
    if (autoDismiss !== undefined) {
      const dismissTimer = setInterval(() => {
        setShow(false)
      }, autoDismiss)
      return () => clearInterval(dismissTimer)
    }
  }, [autoDismiss, setShow])

  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live='assertive'
        className='z-1301 fixed bottom-0 left-1/2 mb-12 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start '
      >
        <div className={`w-full flex flex-col items-center space-y-4 sm:items-end ${bgColor}`}>
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={show}
            as={Fragment}
            enter='transform ease-out duration-300 transition'
            enterFrom='translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2'
            enterTo='translate-y-0 opacity-100 sm:translate-x-0'
            leave='transition ease-in duration-100'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className={`max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden ${bgColor}`}>
              <div className='p-4'>
                <div className='flex items-start'>
                  {children}
                  <div className='ml-4 shrink-0 flex'>
                    <button
                      className='bg-green-50 rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                      onClick={() => {
                        setShow(false)
                      }}
                    >
                      <span className='sr-only'>Close</span>
                      <XIcon className='h-5 w-5' aria-hidden='true' />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  )
}
