import styled from '@emotion/styled'
import {
  offeringSharePriceRange,
  offeringSizeNrShares,
  offeringSizeNrSharesPrimary,
  offeringSizeNrSharesSecondary,
  offeringSizeValue,
  issuePrice,
  couponRange,
  conversionPremium,
  yieldToMaturity
} from 'config/formTypes/inputFields'
import { useSaudiArabiaListingExchanges } from 'lib/useSaudiArabiaListingExchanges'
import React, { useEffect, useMemo, useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { AutocompleteHooked } from '../../../components/AutocompleteComponent'
import { DropdownHooked } from '../../../components/Dropdowns/DropdownHooked'
import { TextField } from '../../../components/TextField/MainTextField'
import { TermsheetState } from '../../../constants'
import { GreenshoeSharesTotal } from '../../../contexts/modal-context/TermsheetModals/GreenshoeSharesTotal'
import { useDealFormContext } from '../context'
import { AutocompleteMultiSelectHooked } from '../Step2Form/components/AutocompleteMultiSelectHooked'
import { ListingExchange } from './ListingExchange'
import { OtherSyndicateBanks } from './OtherSyndicateBanks'
import { PrimarySecondaryTotal } from './PrimarySecondaryTotal'
import { Shareholders } from './Shareholders'
import { SyndicateBankerField } from './SyndicateBankerField'

export const allowedFieldTypes = {
  // Standard types
  text: 'text',
  section: 'section',
  space: 'space',
  dropdown: 'dropdown',
  dropdownSeparateLabelValue: 'dropdownSeparateLabelValue',
  autoComplete: 'autoComplete',
  autoCompleteMultiSelect: 'autoCompleteMultiSelect',
  // Custom types
  sellingShareholders: 'sellingShareholders',
  syndicateBanker: 'syndicateBanker',
  continueStep1Button: 'continueStep1Button',
  otherSyndicateBanks: 'otherSyndicateBanks',
  primarySecondaryTotal: 'primarySecondaryTotal',
  greenshoeSharesTotal: 'greenshoeSharesTotal',
  otherSecurityType: 'otherSecurityType',
  listingExchange: 'listingExchange',
  finalOfferingSizeValue: 'finalOfferingSizeValue',
  state: 'state',
  distributionIntoSaudiArabia: 'distributionIntoSaudiArabia',
  offeringSizeNrSharesPrimaryFinal: 'offeringSizeNrSharesPrimaryFinal',
  finalOfferingSizeNrSharesSecondary: 'finalOfferingSizeNrSharesSecondary',
  finalBaseDealSizeShares: 'finalBaseDealSizeShares',
  finalOfferingSharePrice: 'finalOfferingSharePrice',
  finalIssuePrice: 'finalIssuePrice',
  finalCoupon: 'finalCoupon',
  finalConversionPremium: 'finalConversionPremium',
  finalYieldToMaturity: 'finalYieldToMaturity'
}

export const FieldSelector = ({ type, optionsArray, formTooltipText, ...rest }: any): JSX.Element | null => {
  const { register, watch, control, errors, setValue } = useFormContext()
  const name = rest.name
  const reactFormPickerValue = useWatch({
    control,
    name: name
  })
  const { formValues } = useDealFormContext()
  const formValue = formValues[name]

  useEffect(() => {
    if (formValue !== reactFormPickerValue) {
      console.warn(`Value for ${name as string} is different, setting a default value`, {
        name,
        formValue
      })
      setValue(name, formValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const watchAllFields = watch()
  const [showDistributionIntoSaudiArabia, setShowDistributionIntoSaudiArabia] = useState<boolean>(false)

  // show distributionIntoSaudiArabia field and set its value to undefined if
  // unselected saudiArabiaListingExchanges stock exchanges

  const saudiArabiaListingExchanges: string[] = useSaudiArabiaListingExchanges()

  useEffect(() => {
    if (watchAllFields?.listingExchange !== undefined && watchAllFields?.listingExchange.length > 0) {
      setShowDistributionIntoSaudiArabia(watchAllFields?.listingExchange.some(e =>
        saudiArabiaListingExchanges.includes(e.displayName)))
    } else {
      setShowDistributionIntoSaudiArabia(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchAllFields?.listingExchange])

  const mappedTextFields = useMemo(() => ({
    [allowedFieldTypes.finalOfferingSizeValue]: offeringSizeValue.name,
    [allowedFieldTypes.offeringSizeNrSharesPrimaryFinal]: offeringSizeNrSharesPrimary.name,
    [allowedFieldTypes.finalBaseDealSizeShares]: offeringSizeNrShares.name,
    [allowedFieldTypes.finalOfferingSharePrice]: offeringSharePriceRange.name,
    [allowedFieldTypes.finalOfferingSizeNrSharesSecondary]: offeringSizeNrSharesSecondary.name,
    [allowedFieldTypes.finalIssuePrice]: issuePrice.name,
    [allowedFieldTypes.finalCoupon]: couponRange.name,
    [allowedFieldTypes.finalConversionPremium]: conversionPremium.name,
    [allowedFieldTypes.finalYieldToMaturity]: yieldToMaturity.name
  }), [])

  if (Object.keys(mappedTextFields).includes(type)) {
    return (
      <TextField
        errors={errors} inputRef={register}
        defaultValue={formValues[mappedTextFields[type]]} {...rest}
      />
    )
  }
  const getStateName = (state: TermsheetState): string | undefined => {
    if (state === TermsheetState.PRICED) {
      return TermsheetState.PRICED
    } else if (state === TermsheetState.WITHDRAWN) {
      return TermsheetState.WITHDRAWN
    }
  }

  switch (type) {
    case allowedFieldTypes.text:
      return <TextField errors={errors} inputRef={register} formTooltipText={formTooltipText} {...rest} />

    case allowedFieldTypes.section:
      return <h2>{rest.label}</h2>

    case allowedFieldTypes.space:
      return <Space />

    case allowedFieldTypes.sellingShareholders:
      return <Shareholders control={control} register={register} errors={errors} {...rest} />

    case allowedFieldTypes.dropdown:
      return (
        <DropdownHooked
          control={control} watch={watch}
          errors={errors} optionsArray={optionsArray} formTooltipText={formTooltipText} {...rest}
        />
      )

    case allowedFieldTypes.distributionIntoSaudiArabia:
      return (
        <DropdownHooked
          control={control} watch={watch}
          errors={errors} optionsArray={optionsArray}
          show={showDistributionIntoSaudiArabia}
          isEmptyPlaceholder={false}
          {...rest}
        />
      )

    case allowedFieldTypes.state:
      return (
        (formValues.state === TermsheetState.PRICED || formValues.state === TermsheetState.WITHDRAWN)
          ? <DropdownHooked
              control={control} watch={watch}
              errors={errors} formTooltipText={formTooltipText}
              isDisabled
              optionsArray={[{
                value: getStateName(formValues.state),
                label: getStateName(formValues.state)
              }]}
              isEmptyPlaceholder={false} {...rest}
            />
          : <DropdownHooked
              control={control} watch={watch}
              errors={errors}
              optionsArray={Object.entries(TermsheetState).slice(0, -3).map((item) => ({
                value: item[1],
                label: item[0]
              }))}
              formTooltipText={formTooltipText}
              defaultValue={TermsheetState.ANNOUNCED}
              isEmptyPlaceholder={false} {...rest}
            />

      )

    case allowedFieldTypes.autoComplete:
      return (
        <AutocompleteHooked
          watch={watch}
          errors={errors}
          optionsArray={optionsArray}
          register={register}
          setValue={setValue}
          {...rest}
          defaultValue={formValues[rest.name]}
        />
      )
    case allowedFieldTypes.autoCompleteMultiSelect:
      return (
        <AutocompleteMultiSelectHooked
          watch={watch}
          errors={errors}
          optionsArray={optionsArray}
          register={register}
          setValue={setValue}
          {...rest}
          defaultValue={formValues[rest.name]}
        />
      )

    case allowedFieldTypes.listingExchange:
      return (
        <ListingExchange
          errors={errors}
          watch={watch}
          register={register}
          setValue={setValue}
          {...rest}
          defaultValue={formValues[rest.name]}
        />

      )

    case allowedFieldTypes.syndicateBanker:
      return (
        <SyndicateBankerField
          setValue={setValue} watch={watch} control={control}
          errors={errors} register={register} {...rest}
        />
      )

    case allowedFieldTypes.primarySecondaryTotal:
      return <PrimarySecondaryTotal watch={watch} />

    case allowedFieldTypes.greenshoeSharesTotal:
      return <GreenshoeSharesTotal watch={watch} />

    case allowedFieldTypes.otherSyndicateBanks:
      return <OtherSyndicateBanks setValue={setValue} watch={watch} register={register} errors={errors} />

    case allowedFieldTypes.otherSecurityType:
      return watch('securityType') === 'OTH (Other)'
        ? <TextField
            errors={errors} inputRef={register}
            formTooltipText={formTooltipText} {...rest}
          />
        : null

    default:
      return null
  }
}

const Space = styled.div`
  height: 3em;
`
