import React, { useCallback, useMemo, useState } from 'react'
import { mergeDeepRight } from 'ramda'
import { DealFormContext } from './context'

export const DealFormProvider = (props): JSX.Element => {
  const [formValues, setFormValues] = useState({})

  const stringifiedFormValues = JSON.stringify(formValues)

  const updateFormValues = useCallback(
    (newValues) => {
      const merged = mergeDeepRight(formValues, newValues)
      // eslint-disable-next-line no-console
      console.info('Updating form values', { formValues, newValues, merged })
      setFormValues(merged)
      /**
       * Probably because of specific modal implemetation values are memorized and don't update on submit
       * Thats why we return actual state for submit func
       */
      return mergeDeepRight(formValues, newValues)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stringifiedFormValues]
  )

  const value = useMemo(
    () => ({
      formValues,
      setFormValues,
      updateFormValues
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stringifiedFormValues, updateFormValues]
  )

  return <DealFormContext.Provider {...props} value={value} />
}
