import React, { useContext } from 'react'
import compose from 'ramda/src/compose'
import { DealContext } from '../../../contexts/deals-context'
import { useProfileHook } from '../../../contexts/user-auth-context'
import { ORGANIZATION_TYPES } from 'constants/organizations'
import { useTermsheetContext } from '../../../containers/TermsheetInformation/context'
import { useDealFormContext } from '../../../containers/DealForm/context'
import { HeadingButton } from '../TermsheetInformation/HeadingButton'

interface EditModeButtonProps{
  label?: string
  'data-qa'?: string
}

export function EditModeButton ({ label, 'data-qa': dataQA }: EditModeButtonProps): JSX.Element | null {
  const { updateFormValues } = useDealFormContext()
  const { selectedTermsheet } = useTermsheetContext()
  const { isEditModeOnContext, setIsEditModeOnContext } = useContext<any>(DealContext)

  const [profile] = useProfileHook()
  const { accountType } = profile
  const isAuthorized = [ORGANIZATION_TYPES.BANKER, ORGANIZATION_TYPES.PRIMARY_PORTAL_AGENT].includes(accountType)

  const handleOnClick = compose(
    () => setIsEditModeOnContext((prevValue: boolean) => !prevValue),
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    () => !isEditModeOnContext && updateFormValues(selectedTermsheet)
  )

  return isAuthorized
    ? (
      <HeadingButton onClick={handleOnClick} label={label === '' || label === undefined ? 'Edit' : label} data-qa={dataQA} />
      )
    : (
        null
      )
}
