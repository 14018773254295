import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { DealContext } from '../../contexts/deals-context'
import { RenderSideMenu } from './components/RenderSideMenu'
import { FEATURES } from 'constants/features'
import { useFeatureFlags } from 'lib/useFeatureFlags'

export function SideMenu ({ isAuthenticated }): JSX.Element|null {
  const { dealIdContext } = useContext<any>(DealContext)
  const { isFeatureFlagEnabled } = useFeatureFlags()

  return isFeatureFlagEnabled(FEATURES.showLeftHandNav)
    ? <RenderSideMenu isAuthenticated={isAuthenticated} dealIdContext={dealIdContext} />
    : null
}

SideMenu.propTypes = {
  isAuthenticated: PropTypes.bool
}
