import { joiResolver } from '@hookform/resolvers'
import { PriceDealSchema } from '../../../../containers/DealForm/formValidation'
import { useDealFormContext } from '../../../../containers/DealForm/context'
import { useForm, UseFormMethods } from 'react-hook-form'
import { useSubmitHandler } from '../../../../containers/DealForm/Step2Form/useSubmitHandler'
import { useTermsheetModalContext } from '../context'

export function usePriceDealModalForm (): { formMethods: UseFormMethods, onSubmit: any } {
  const { formValues, updateFormValues } = useDealFormContext()
  const { handlePriceDeal } = useSubmitHandler()
  const { setIsOpen, setModalSelector, setSubmitHandler } = useTermsheetModalContext()

  const formMethods = useForm({
    defaultValues: formValues,
    resolver: joiResolver(PriceDealSchema)
  })

  const onSubmit = (data: any): void => {
    setIsOpen(true)
    setModalSelector('confirmPublish')
    setSubmitHandler(() => handlePriceDeal)
    updateFormValues(data)
  }

  return { formMethods, onSubmit }
}
