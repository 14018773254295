import { allowedFieldTypes } from '../../containers/DealForm/FieldSelector'
import {
  OFFERING_TYPES,
  BANK_ROLES,
  CURRENCIES_ARRAY,
  DISTRIBUTION_TYPES,
  QIBS,
  REG_S_ONLY,
  PRIMARY_SECONDARY_OPTIONS,
  SECURITY_TYPES_LABELS,
  SECURITY_TYPES_LABELS_EQL,
  ARRAY_OF_DOMICILE, TermsheetRegion
} from '../../constants/index'

export const termsheetRowTypes = {
  issuerName: 'issuerName',
  sellingShareHolders: 'sellingShareHolders',
  otherSyndicateBanks: 'otherSyndicateBanks',
  rumpOffering: 'rumpOffering',
  singleColumnRow: 'singleColumnRow',
  fullWidthRow: 'fullWidthRow',
  listingExchange: 'listingExchange',
  distribution: 'distribution',
  distributionIntoSaudiArabia: 'distributionIntoSaudiArabia'
}

export const issuerName = {
  label: 'Issuer name',
  name: 'issuerName',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.issuerName,
  termsheetRank: { IPO: 10, ABO: 10, EQL: 10, RI: 10, FMO: 10, OTH: 11, SPAC: 10 }
  // TODO: Rewrite numbers into normal fields in templates.
}

export const issuerLegalName = {
  label: 'Issuer full legal name',
  name: 'issuerLegalName',
  type: allowedFieldTypes.text,
  termsheetRank: { IPO: 15, ABO: 15, EQL: 15, RI: 15, FMO: 15, OTH: 12, SPAC: 15 }
}

export const taasPublishAsBankerName = {
  label: 'Publishing bank',
  name: 'taasPublishAsBankerName',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow
}

export const publishingBankName = {
  label: 'Publishing bank entity',
  name: 'publishingBankName',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow
}

export const publishingBankRole = {
  label: 'Role of the Publishing bank',
  name: 'publishingBankRole',
  type: allowedFieldTypes.dropdown,
  optionsArray: BANK_ROLES,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: 5, ABO: 5, EQL: 5, RI: 5, FMO: 5, OTH: 15, SPAC: 30 }
}

export const sector = {
  label: 'Sector',
  name: 'sector',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: 16.99, ABO: 17.99, EQL: 10.01, RI: 10.01, FMO: null, OTH: 11.01, SPAC: 99.99 }
}

export const businessDescription = {
  label: 'Business description',
  name: 'businessDescription',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: 17, ABO: 18, EQL: 10.02, RI: 10.02, FMO: null, OTH: 11.02, SPAC: 100 }
}
export const underwritingSplit = {
  label: 'Underwriting split',
  name: 'underwritingSplit',
  type: allowedFieldTypes.text
}

export const syndicateBanker = {
  label: 'Designated Syndicate banker',
  name: 'designatedBankerName',
  type: allowedFieldTypes.syndicateBanker,
  termsheetRank: { IPO: 30, ABO: 40, EQL: 30, RI: 30, FMO: 30, OTH: 30 }
}

export const attachments = {
  label: 'Attachments',
  name: 'attachments'
}

export const fullBankerName = {
  label: 'Full banker name',
  name: 'fullBankerName'
}

export const designatedBankerEmail = {
  label: 'Email',
  name: 'designatedBankerEmail'
}

export const designatedBankerPhone = {
  label: 'Mobile phone',
  name: 'designatedBankerPhone'
}

export const researchfnLink = {
  label: 'Access Research',
  name: 'researchfnLink',
  type: allowedFieldTypes.text
}

export const netroadshowLink = {
  label: 'Virtual Presentation',
  name: 'netroadshowLink',
  type: allowedFieldTypes.text
}

export const prospectusRegistrationLink = {
  label: 'Prospectus/Registration Document',
  name: 'prospectusRegistrationLink',
  type: allowedFieldTypes.text
}

export const offeringType = {
  label: 'Offering type',
  name: 'offeringType',
  type: allowedFieldTypes.dropdown,
  optionsArray: Object.entries(OFFERING_TYPES)
    // create an array of objects
    .map(([key, value]) => ({
      label: value,
      value: key
    }))
    .filter((item) => item.value !== 'FMO'),
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: 1, ABO: 1, EQL: 1, RI: 43, FMO: null, OTH: 1, SPAC: 1 }
}

export const region = {
  label: 'Region',
  name: 'region',
  type: allowedFieldTypes.dropdown,
  optionsArray: Object.entries(TermsheetRegion)
    .map(([value, key]) => ({
      label: value,
      value: key
    })),
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: 1, ABO: 1, EQL: 1, RI: 43, FMO: null, OTH: 1, SPAC: 1 }
}

export const otherOfferingType = {
  label: 'Other offering type',
  name: 'otherOfferingType',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: null, ABO: null, EQL: null, RI: null, FMO: null, OTH: 1 }
}

export const sellingShareholders = {
  name: 'sellingShareholders',
  type: allowedFieldTypes.sellingShareholders,
  termsheetRowType: termsheetRowTypes.sellingShareHolders,
  termsheetRank: { IPO: 20, ABO: 56, EQL: null, RI: null, FMO: 57, OTH: 570, SPAC: 40 }
}

export const otherSyndicateBanks = {
  label: 'Other syndicate banks',
  name: 'otherSyndicateBanks',
  type: allowedFieldTypes.otherSyndicateBanks,
  termsheetRowType: termsheetRowTypes.otherSyndicateBanks,
  termsheetRank: { IPO: 35, ABO: 35, EQL: 35, RI: 35, FMO: 35, OTH: 350, SPAC: 50 }
}

export const primarySecondaryString = {
  label: 'Primary/Secondary',
  name: 'primarySecondaryString',
  type: allowedFieldTypes.dropdown,
  optionsArray: PRIMARY_SECONDARY_OPTIONS,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: 50, ABO: 50, EQL: null, RI: null, FMO: 50, OTH: 470 }
}

export const primarySecondaryTotal = {
  label: 'Total number of shares',
  name: 'primarySecondaryTotal',
  type: allowedFieldTypes.primarySecondaryTotal,
  termsheetRank: { IPO: 91, ABO: 91, EQL: null, RI: null, FMO: 81, OTH: 91 }
}

export const offeringSharePriceRange = {
  // offeringSharePriceRange is added on initial termsheet creation.
  // Is replaced by finalOfferingSharePrice after the deal was priced.
  label: 'Price range/guidance',
  name: 'offeringSharePriceRange',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: 60, ABO: 110, EQL: null, RI: null, FMO: 60, OTH: 600, SPAC: 105 }
}

export const offeringPrice = {
  label: 'Offer price / Unit price',
  name: 'offeringPrice',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: null, ABO: null, EQL: null, RI: null, FMO: null, SPAC: null }
}

export const finalOfferingSharePrice = {
  label: 'Final price',
  name: 'finalPriceLocalCcy',
  type: allowedFieldTypes.finalOfferingSharePrice,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: 60, ABO: 110, EQL: null, RI: null, FMO: 60, OTH: 478, SPAC: 110 }
}

export const unitComposition = {
  label: 'Unit Composition',
  name: 'unitComposition',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: null, ABO: null, EQL: null, RI: null, FMO: null, SPAC: 120 }
}

export const keyIndividualsFounders = {
  label: 'Key individuals / Founders',
  name: 'keyIndividualsFounders',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: null, ABO: null, EQL: null, RI: null, FMO: null, SPAC: 130 }
}

export const spacLifetime = {
  label: 'SPAC Life / timeframe',
  name: 'spacLifetime',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: null, ABO: null, EQL: null, RI: null, FMO: null, SPAC: 140 }
}

export const previousClose = {
  label: 'Previous close',
  name: 'previousClose',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: {
    IPO: null,
    ABO: 111,
    EQL: null,
    RI: null,
    FMO: null,
    OTH: 1449.01
  }
}

export const offeringSizeNrShares = {
  // offeringSizeNrShares is added on initial termsheet creation.
  // Is replaced by finalOfferingSizeNrShares after the deal was priced.
  label: 'Number of shares offered',
  name: 'offeringSizeNrShares',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: 90, ABO: 90, EQL: null, RI: 60, FMO: 80, OTH: 472, SPAC: 118 }
}

export const finalOfferingSizeNrShares = {
  label: 'Final shares in the deal',
  name: 'finalBaseDealSizeShares',
  type: allowedFieldTypes.finalBaseDealSizeShares,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: 90, ABO: 90, EQL: null, RI: 60, FMO: 80, OTH: 472, SPAC: 118 }
}

export const offeringSizeNrSharesPrimary = {
  // offeringSizeNrSharesPrimary is added on initial termsheet creation.
  // Is replaced by finalOfferingSizeNrSharesPrimary after the deal was priced.
  label: 'Primary Offering',
  name: 'offeringSizeNrSharesPrimary',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: 88, ABO: 88, EQL: null, RI: 58, FMO: 78, OTH: 472, SPAC: 118 }
  // See if fix removes primary and secondary default '0' values from the termsheet
  // defaultValue: 0,
}

export const finalOfferingSizeNrSharesPrimary = {
  label: 'Final primary shares',
  name: 'offeringSizeNrSharesPrimaryFinal',
  type: allowedFieldTypes.offeringSizeNrSharesPrimaryFinal,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: 88, ABO: 88, EQL: null, RI: 58, FMO: 78, OTH: 472, SPAC: 118 }
}

export const offeringSizeNrSharesSecondary = {
  // offeringSizeNrSharesSecondary is added on initial termsheet creation.
  // Is replaced by finalOfferingSizeNrSharesSecondary after the deal was priced.

  label: 'Secondary Offering',
  name: 'offeringSizeNrSharesSecondary',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: 89, ABO: 89, EQL: null, RI: 59, FMO: 79, OTH: 474, SPAC: 119 }

  // See if fix removes primary and secondary default '0' values from the termsheet
  // defaultValue: 0,
}

export const finalOfferingSizeNrSharesSecondary = {
  label: 'Final secondary shares',
  name: 'offeringSizeNrSharesSecondaryFinal',
  type: allowedFieldTypes.finalOfferingSizeNrSharesSecondary,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: 89, ABO: 89, EQL: null, RI: 59, FMO: 79, OTH: 474, SPAC: 119 }
}

export const offeringCurrencyType = {
  label: 'Offering currency',
  name: 'offeringCurrencyType',
  type: allowedFieldTypes.dropdown,
  optionsArray: CURRENCIES_ARRAY.slice(10, CURRENCIES_ARRAY.length).map((item) => ({
    label: item,
    value: item
  })),
  optionsArray2: CURRENCIES_ARRAY.slice(0, 10).map((item) => ({
    label: item,
    value: item
  })),
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: 80, ABO: 80, EQL: 30, RI: 50, FMO: 70, OTH: 468, SPAC: 120 }
}

export const offeringSizeValue = {
  // offeringSizeValue is added on initial termsheed creations.
  // Replaced by finalOfferingSizeValue after the deal was priced.
  label: 'Offering size',
  name: 'offeringSizeValue',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: 100, ABO: 100, EQL: 40, RI: 50, FMO: 70, OTH: 476, SPAC: 60 }
}

export const finalOfferingSizeValue = {
  name: 'finalOfferingSize',
  label: 'Final offering size',
  type: allowedFieldTypes.finalOfferingSizeValue,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: 100.1, ABO: 100.1, EQL: 40.1, RI: 60, FMO: 70, OTH: 476.1, SPAC: 60.1 }
}

export const issuedShareCapital = {
  label: 'Issued Share Capital',
  name: 'issuedShareCapital',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: {
    IPO: 137,
    ABO: 137,
    EQL: null,
    RI: 145,
    FMO: null,
    OTH: 1440
  }
}

export const lockUpExpiryDays = {
  label: 'Lockup',
  name: 'lockUpExpiryDays',
  type: allowedFieldTypes.text,
  formTooltipText: "Provide the lockup duration as amount of days. If the information is not yet available, write down 'TBA'.",
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: 130, ABO: 130, EQL: 125, RI: 140, FMO: 110, OTH: 1100, SPAC: 270 }
}

export const greenshoe = {
  label: 'Greenshoe',
  name: 'greenshoe',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: 110, ABO: 119, EQL: 40, RI: 50, FMO: 98, OTH: 980 }
}

export const greenshoePrimaryShares = {
  label: 'GS Primary Shares',
  name: 'greenshoePrimaryShares',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: 111, ABO: 119.1, EQL: 41, RI: 51, FMO: 98.1, OTH: 111 }
}

export const greenshoeSecondaryShares = {
  label: 'GS Secondary Shares',
  name: 'greenshoeSecondaryShares',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: 112, ABO: 119.2, EQL: 41, RI: 51, FMO: 98.2, OTH: 112 }
}

export const greenshoeSharesTotal = {
  label: 'greenshoeSharesTotal',
  name: 'Greenshoe Shares Total',
  type: allowedFieldTypes.greenshoeSharesTotal,
  termsheetRank: { IPO: 113, ABO: 119.3, EQL: 42, RI: 52, FMO: 98.3, OTH: 113 }
}

export const stabAgent = {
  label: 'Stab Agent',
  name: 'stabAgent',
  type: allowedFieldTypes.autoComplete,
  optionsArray: [],
  defaultValue: '',
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: 114, ABO: 119.4, EQL: 43, RI: 53, FMO: 98.4, OTH: 114 }
}

export const otherSellingRestrictions = {
  label: 'Other selling restrictions',
  name: 'otherSellingRestrictions',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: 123, ABO: 123, EQL: 123, RI: 125, FMO: 102, OTH: 1020, SPAC: 160 }
}

export const state = {
  label: 'Deal State',
  name: 'state',
  type: allowedFieldTypes.state,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: 131, ABO: null, EQL: null, RI: 141, FMO: null, OTH: null, SPAC: null }
}

export const distribution = {
  label: 'USA distribution',
  name: 'distribution',
  'data-qa': 'Deal-UsaDistributionField',
  type: allowedFieldTypes.dropdown,
  optionsArray: Object.entries(DISTRIBUTION_TYPES).map((item) => ({
    value: item[0],
    label: item[1]
  })),
  formTooltipText: {
    [REG_S_ONLY]: 'The Company’s shares will be offered and sold only in offshore transactions in reliance on Regulation S under the Securities Act',
    [QIBS]: 'As defined under the United States Securities Act of 1933, as amended ("the Securities Act")',
    // The lines below are to accomodate old versions (earlier than 9/17/2020) and will be phased out overtime.
    'Rule 144a to QIBs': 'The Company’s shares will be offered and sold only in offshore transactions in reliance on Regulation S under the Securities Act',

    'Reg S only': 'The Company’s shares will be offered and sold only in offshore transactions in reliance on Regulation S under the Securities Act'
  },
  termsheetTooltipText: {
    [REG_S_ONLY]: 'The Company’s shares will be offered and sold only in offshore transactions in reliance on Regulation S under the Securities Act',
    [QIBS]: 'As defined under the United States Securities Act of 1933, as amended ("the Securities Act")',
    // The lines below are to accomodate old versions (earlier than 9/17/2020) and will be phased out overtime.

    'Reg S only': 'The Company’s shares will be offered and sold only in offshore transactions in reliance on Regulation S under the Securities Act',
    'Rule 144a to QIBs': 'As defined under the United States Securities Act of 1933, as amended ("the Securities Act")'
  },
  termsheetRowType: termsheetRowTypes.distribution,
  termsheetRank: { IPO: 120, ABO: 120, EQL: 120, RI: 120, FMO: 100, OTH: 1000, SPAC: 150 }
}

export const distributionIntoSaudiArabia = {
  label: 'Distribution into Saudi Arabia',
  name: 'distributionIntoSaudiArabia',
  type: allowedFieldTypes.distributionIntoSaudiArabia,
  optionsArray: [{ value: true, label: 'Yes' },
    { value: false, label: 'No' }]
}

export const listingExchange = {
  label: 'Listing exchange(s)',
  name: 'listingExchange',
  type: allowedFieldTypes.listingExchange,
  defaultValue: '',
  termsheetRowType: termsheetRowTypes.listingExchange,
  termsheetRank: { IPO: 140, ABO: 140, EQL: 81, RI: 150, FMO: 120, OTH: 1200, SPAC: 80 }
}

export const stockTickerCode = {
  label: 'Ticker',
  name: 'stockTickerCode',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: 158, ABO: 158, EQL: 11, RI: 168, FMO: 138, OTH: 1300, SPAC: 90 }
}

export const isinNumber = {
  label: 'ISIN',
  name: 'isinNumber',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: 157, ABO: 157, EQL: 137, RI: 167, FMO: 137, OTH: 1400, SPAC: 310 }
}
export const cusipNumber = {
  label: 'CUSIP',
  name: 'cusipNumber',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: null, ABO: null, EQL: null, RI: null, FMO: null, OTH: null, SPAC: 320 }
}

export const offeringImpliedMarketValue = {
  label: 'Implied market value',
  name: 'offeringImpliedMarketValue',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: {
    IPO: 70,
    ABO: null,
    EQL: null,
    RI: null,
    FMO: null,
    OTH: 70
  }
}

export const headerDisclaimerText = {
  label: 'Header Disclaimer',
  name: 'headerDisclaimerText',
  type: allowedFieldTypes.text,
  rows: 4,
  termsheetRowType: termsheetRowTypes.fullWidthRow,
  termsheetRank: { IPO: 175, ABO: 170, EQL: 150, RI: 180, FMO: 150, OTH: 10 }
}

export const disclaimerText = {
  label: 'Footer Disclaimer',
  name: 'disclaimerText',
  type: allowedFieldTypes.text,
  rows: 4,
  termsheetRowType: termsheetRowTypes.fullWidthRow,
  termsheetRank: { IPO: 176, ABO: 170, EQL: 150, RI: 180, FMO: 150, OTH: 1500, SPAC: 380 }
}

export const additionalInfo = {
  label: 'Additional info',
  name: 'additionalInfo',
  type: allowedFieldTypes.text,
  rows: 4,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: 174, ABO: 168, EQL: 145, RI: 175, FMO: 145, OTH: 1450, SPAC: 370 }
}

export const securityType = {
  label: 'Security type',
  name: 'securityType',
  type: allowedFieldTypes.dropdown,
  optionsArray: SECURITY_TYPES_LABELS('ABO').map((item) => ({
    label: item,
    value: item
  })),
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: 45, ABO: 45, EQL: null, RI: 45, FMO: 45, OTH: 460 }
}

export const otherSecurityType = {
  label: 'Other security type',
  name: 'otherSecurityType',
  type: allowedFieldTypes.otherSecurityType,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: 46, ABO: 46, EQL: null, RI: 46, FMO: 46, OTH: 461 }
}

export const securityTypeEQL = {
  label: 'Security type',
  name: 'securityTypeEQL',
  type: allowedFieldTypes.dropdown,
  optionsArray: SECURITY_TYPES_LABELS_EQL().map((item) => ({
    label: item,
    value: item
  })),
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: {
    IPO: null,
    ABO: null,
    EQL: 26,
    RI: null,
    FMO: null,
    OTH: null
  }
}

export const freeFloat = {
  label: 'Free float',
  name: 'freeFloat',
  type: allowedFieldTypes.text,
  formTooltipText: "You can write down any free float information that you deem fit, or you simply write down 'TBA'",
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: 75, ABO: 75, EQL: null, RI: null, FMO: 96, OTH: 960 }
}

export const upsizeOption = {
  label: 'Upsize option',
  name: 'upsizeOption',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: 105, ABO: 105, EQL: null, RI: null, FMO: 95, OTH: 950 }
}

export const useOfProceeds = {
  label: 'Use of proceeds',
  name: 'useOfProceeds',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: 55, ABO: 55, EQL: 100, RI: 91, FMO: 55, OTH: 550 }
}

export const bondRating = {
  label: 'Bond rating',
  name: 'bondRating',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: {
    IPO: null,
    ABO: null,
    EQL: 18,
    RI: null,
    FMO: null,
    OTH: null
  }
}

export const underlyingSecurity = {
  label: 'Underlying Security',
  name: 'underlyingSecurity',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: {
    IPO: null,
    ABO: null,
    EQL: 26,
    RI: null,
    FMO: null,
    OTH: null
  }
}

export const commitments = {
  label: 'Commitments',
  name: 'commitments',
  type: allowedFieldTypes.text,
  rows: 3,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: 107, ABO: 112, EQL: null, RI: 117, FMO: 97, OTH: 970, SPAC: 280 }
}

export const issuerDomicile = {
  label: 'Issuer domicile',
  name: 'issuerDomicile',
  type: allowedFieldTypes.autoComplete,
  optionsArray: ARRAY_OF_DOMICILE,
  defaultValue: '',
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: 16, ABO: 17, EQL: 17, RI: 16, FMO: 16, OTH: 840 }
}

export const issuerRating = {
  label: 'Issuer rating',
  name: 'issuerRating',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: {
    IPO: null,
    ABO: null,
    EQL: 17,
    RI: null,
    FMO: null,
    OTH: null
  }
}

export const mifidTargetMarket = {
  label: 'MIFID II target market',
  name: 'mifidTargetMarket',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: 125, ABO: 125, EQL: 124.9, RI: 130, FMO: 105, OTH: 1050 }
}

export const offeringMaturity = {
  label: 'Maturity',
  name: 'offeringMaturity',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: {
    IPO: null,
    ABO: null,
    EQL: 50,
    RI: null,
    FMO: null,
    OTH: null
  }
}

export const issuePrice = {
  label: 'Issue Price',
  name: 'issuePrice',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: {
    IPO: null,
    ABO: null,
    EQL: 55,
    RI: null,
    FMO: null,
    OTH: null
  }
}

export const finalIssuePrice = {
  label: 'Issue Price - Final',
  name: 'finalIssuePrice',
  type: allowedFieldTypes.finalIssuePrice,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: {
    IPO: null,
    ABO: null,
    EQL: 55,
    RI: null,
    FMO: null,
    OTH: null
  }
}

export const redemptionPrice = {
  label: 'Redemption Price',
  name: 'redemptionPrice',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: {
    IPO: null,
    ABO: null,
    EQL: 60,
    RI: null,
    FMO: null,
    OTH: null
  }
}

export const percentCapitalIncrease = {
  label: '% Capital increase',
  name: 'percentCapitalIncrease',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: 95, ABO: 95, EQL: null, RI: 65, FMO: 85, OTH: 850 }
}

export const couponRange = {
  label: 'Coupon Range',
  name: 'couponRange',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: {
    IPO: null,
    ABO: null,
    EQL: 65,
    RI: null,
    FMO: null,
    OTH: null
  }
}
export const finalCoupon = {
  label: 'Coupon - Final',
  name: 'finalCoupon',
  type: allowedFieldTypes.finalCoupon,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: null, ABO: null, EQL: 110, RI: null, FMO: null, OTH: null, SPAC: null }
}

export const yieldToMaturity = {
  label: 'Yield to maturity',
  name: 'yieldToMaturity',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: {
    IPO: null,
    ABO: null,
    EQL: 67,
    RI: null,
    FMO: null,
    OTH: null
  }
}

export const finalYieldToMaturity = {
  label: 'Yield to Maturity - Final',
  name: 'finalYieldToMaturity',
  type: allowedFieldTypes.finalYieldToMaturity,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: {
    IPO: null,
    ABO: null,
    EQL: 67,
    RI: null,
    FMO: null,
    OTH: null
  }
}

export const conversionPremium = {
  label: 'Conversion Premium',
  name: 'conversionPremium',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: {
    IPO: null,
    ABO: null,
    EQL: 70,
    RI: null,
    FMO: null,
    OTH: null
  }
}

export const finalConversionPremium = {
  label: 'Conversion Premium - Final',
  name: 'finalConversionPremium',
  type: allowedFieldTypes.finalConversionPremium,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: {
    IPO: null,
    ABO: null,
    EQL: 70,
    RI: null,
    FMO: null,
    OTH: null
  }
}

export const referenceSharePrice = {
  label: 'Reference Share Price',
  name: 'referenceSharePrice',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: {
    IPO: null,
    ABO: null,
    EQL: 72,
    RI: null,
    FMO: null,
    OTH: null
  }
}

export const subscriptionPrice = {
  label: 'Subscription Price',
  name: 'subscriptionPrice',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: {
    IPO: null,
    ABO: null,
    EQL: null,
    RI: 90,
    FMO: null,
    OTH: null
  }
}

export const subscriptionRatio = {
  label: 'Subscription Ratio',
  name: 'subscriptionRatio',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: {
    IPO: null,
    ABO: null,
    EQL: null,
    RI: 100,
    FMO: null,
    OTH: null
  }
}

export const discountToClose = {
  label: 'Discount to Close',
  name: 'discountToClose',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: {
    IPO: null,
    ABO: null,
    EQL: null,
    RI: 115,
    FMO: null,
    OTH: null
  }
}

export const discountToTERP = {
  label: 'Discount to TERP',
  name: 'discountToTERP',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: {
    IPO: null,
    ABO: null,
    EQL: null,
    RI: 110,
    FMO: null,
    OTH: null
  }
}

export const investorPut = {
  label: 'Investor Put',
  name: 'investorPut',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: {
    IPO: null,
    ABO: null,
    EQL: 74,
    RI: null,
    FMO: null,
    OTH: null
  }
}

export const issuerCall = {
  label: 'Issuer Call',
  name: 'issuerCall',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: {
    IPO: null,
    ABO: null,
    EQL: 73,
    RI: null,
    FMO: null,
    OTH: null
  }
}

export const antiDilutionProtection = {
  label: 'Anti- Dilution Protection',
  name: 'antiDilutionProtection',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: {
    IPO: null,
    ABO: null,
    EQL: 75,
    RI: null,
    FMO: null,
    OTH: null
  }
}

export const settlementUponConversion = {
  label: 'Settlement upon conversion',
  name: 'settlementUponConversion',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: {
    IPO: null,
    ABO: null,
    EQL: 76,
    RI: null,
    FMO: null,
    OTH: null
  }
}

export const changeOfControl = {
  label: 'Change of Control',
  name: 'changeOfControl',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: {
    IPO: null,
    ABO: null,
    EQL: 77,
    RI: null,
    FMO: null,
    OTH: null
  }
}

export const denomination = {
  label: 'Denomination',
  name: 'denomination',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: {
    IPO: null,
    ABO: null,
    EQL: 78,
    RI: null,
    FMO: null,
    OTH: null
  }
}

export const dividendProtection = {
  label: 'Dividend Protection',
  name: 'dividendProtection',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: {
    IPO: null,
    ABO: null,
    EQL: 79,
    RI: null,
    FMO: null,
    OTH: null
  }
}

export const TERP = {
  label: 'TERP',
  name: 'TERP',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: {
    IPO: null,
    ABO: null,
    EQL: null,
    RI: 105,
    FMO: null,
    OTH: null
  }
}

export const rumpOffering = {
  label: 'Rump offering',
  name: 'rumpOffering',
  type: allowedFieldTypes.dropdown,
  optionsArray: ['Yes', 'No'].map((item) => ({
    label: item,
    value: item
  })),
  termsheetRowType: termsheetRowTypes.rumpOffering,
  termsheetRank: {
    IPO: null,
    ABO: 16,
    EQL: null,
    RI: null,
    FMO: null,
    OTH: null
  }
}

export const marketCap = {
  label: 'Market Cap',
  name: 'marketCap',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: 158, ABO: 158, EQL: 138, RI: 168, FMO: 138, OTH: 1440 }
}

export const minimumOrder = {
  label: 'Minimum order',
  name: 'minimumOrder',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: 159, ABO: 159, EQL: 139, RI: 169, FMO: 139, OTH: 1150 }
}

export const conditionsForCompletion = {
  label: 'Conditions for completion',
  name: 'conditionsForCompletion',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: 160, ABO: 160, EQL: 140, RI: 170, FMO: 140, OTH: 1430 }
}

export const documentation = {
  label: 'Documentation',
  name: 'documentation',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: 161, ABO: 161, EQL: 141, RI: 171, FMO: 141, OTH: 1450 }
}

export const allocationCriteria = {
  label: 'Allocation criteria',
  name: 'allocationCriteria',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: 162, ABO: 162, EQL: 142, RI: 172, FMO: 142, OTH: 1350 }
}

export const investorAcknowledgement = {
  label: 'Investor acknowledgement',
  name: 'investorAcknowledgement',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: 163, ABO: 163, EQL: 143, RI: 173, FMO: 143 }
}

export const commissions = {
  label: 'Commissions',
  name: 'commissions',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: 164, ABO: 164, EQL: 144, RI: 174, FMO: 144, OTH: 1430 }
}

export const foundersPromoteSponsorPromote = {
  label: "Founders' Promote/ Sponsor promote",
  name: 'foundersPromoteSponsorPromote',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: null, ABO: null, EQL: null, RI: null, FMO: null, SPAC: 180 }
}

export const riskCapital = {
  label: 'Risk Capital',
  name: 'riskCapital',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: null, ABO: null, EQL: null, RI: null, FMO: null, SPAC: 190 }
}

export const warrantTerms = {
  label: 'Warrant terms',
  name: 'warrantTerms',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: null, ABO: null, EQL: null, RI: null, FMO: null, SPAC: 200 }
}

export const amountHeldInTrust = {
  label: 'Amount held in trust',
  name: 'amountHeldInTrust',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: null, ABO: null, EQL: null, RI: null, FMO: null, SPAC: 210 }
}

export const interestEarnedOnCash = {
  label: 'Interest earned on cash in trust',
  name: 'interestEarnedOnCash',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: null, ABO: null, EQL: null, RI: null, FMO: null, SPAC: 220 }
}

export const shareLiquidationValue = {
  label: 'Share Liquidation Value',
  name: 'shareLiquidationValue',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: null, ABO: null, EQL: null, RI: null, FMO: null, SPAC: 230 }
}

export const focusTargetAcquisitionMarket = {
  label: 'Focus/Target Acquisition Market',
  name: 'focusTargetAcquisitionMarket',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: null, ABO: null, EQL: null, RI: null, FMO: null, SPAC: 240 }
}

export const acquisitionApproval = {
  label: 'Acquisition Approval',
  name: 'acquisitionApproval',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: null, ABO: null, EQL: null, RI: null, FMO: null, SPAC: 250 }
}

export const overallotment = {
  label: 'Overallotment',
  name: 'overallotment',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: null, ABO: null, EQL: null, RI: null, FMO: null, SPAC: 260 }
}

export const incorporation = {
  label: 'Incorporation',
  name: 'incorporation',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: null, ABO: null, EQL: null, RI: null, FMO: null, SPAC: 290 }
}

export const industry = {
  label: 'Industry',
  name: 'industry',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: null, ABO: null, EQL: null, RI: null, FMO: null, SPAC: 300 }
}

export const groupCallDateTime = {
  label: 'Group Call date/time',
  name: 'groupCallDateTime',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: null, ABO: null, EQL: null, RI: null, FMO: null, SPAC: 330 }
}

export const groupCallNr = {
  label: 'Group call nr',
  name: 'groupCallNr',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: null, ABO: null, EQL: null, RI: null, FMO: null, SPAC: 340 }
}

export const groupCallId = {
  label: 'Group call ID',
  name: 'groupCallId',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: null, ABO: null, EQL: null, RI: null, FMO: null, SPAC: 340 }
}

export const groupCallEntryCode = {
  label: 'Group call entry code',
  name: 'groupCallEntryCode',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: null, ABO: null, EQL: null, RI: null, FMO: null, SPAC: 350 }
}

export const groupCallPresentation = {
  label: 'Group call presentation',
  name: 'groupCallPresentation',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: null, ABO: null, EQL: null, RI: null, FMO: null, SPAC: 360 }
}

export const pricingAssumptionsSection = {
  label: 'Pricing assumptions',
  name: 'pricingAssumptionsSection', // Required
  type: allowedFieldTypes.section,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: null, ABO: null, EQL: null, RI: null, FMO: null, SPAC: null }
}

export const emptySpaceSection = {
  label: ' ', // Required
  name: 'emptySpaceSection', // Required
  type: allowedFieldTypes.space,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: null, ABO: null, EQL: null, RI: null, FMO: null, SPAC: null }
}

export const creditSpread = {
  label: 'Pricing assumptions - credit spread',
  name: 'creditSpread',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: null, ABO: null, EQL: 126, RI: null, FMO: null, SPAC: null }
}

export const costOfBorrow = {
  label: 'Pricing assumptions - cost of borrow',
  name: 'costOfBorrow',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: null, ABO: null, EQL: 127, RI: null, FMO: null, SPAC: null }
}

export const impliedVolatility = {
  label: 'Pricing assumptions - implied volatility',
  name: 'impliedVolatility',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: null, ABO: null, EQL: 128, RI: null, FMO: null, SPAC: null }
}

export const delta = {
  label: 'Pricing assumptions - delta',
  name: 'delta',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: null, ABO: null, EQL: 129, RI: null, FMO: null, SPAC: null }
}

export const isinOfTheBond = {
  label: 'ISIN of the Bond',
  name: 'isinOfTheBond',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: null, ABO: null, EQL: 130, RI: null, FMO: null, SPAC: null }
}

export const statusOfTheBonds = {
  label: 'Status of the Bonds',
  name: 'statusOfTheBonds',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: null, ABO: null, EQL: 131, RI: null, FMO: null, SPAC: null }
}

export const formOfTheBonds = {
  label: 'Form of the Bonds',
  name: 'formOfTheBonds',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: null, ABO: null, EQL: 132, RI: null, FMO: null, SPAC: null }
}

export const initialConversionPrice = {
  label: 'Initial Conversion Price',
  name: 'initialConversionPrice',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: null, ABO: null, EQL: 133, RI: null, FMO: null, SPAC: null }
}

export const conversionRights = {
  label: 'Conversion Rights',
  name: 'conversionRights',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: null, ABO: null, EQL: 134, RI: null, FMO: null, SPAC: null }
}

export const conversionPeriod = {
  label: 'Conversion Period',
  name: 'conversionPeriod',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: null, ABO: null, EQL: 135, RI: null, FMO: null, SPAC: null }
}

export const cleanUpCall = {
  label: 'Clean-up call',
  name: 'cleanUpCall',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: null, ABO: null, EQL: 136, RI: null, FMO: null, SPAC: null }
}

export const taxCallGrossUp = {
  label: 'Tax call / Gross-up',
  name: 'taxCallGrossUp',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: null, ABO: null, EQL: 137, RI: null, FMO: null, SPAC: null }
}

export const crossDefaultAcceleration = {
  label: 'Cross Default/Acceleration',
  name: 'crossDefaultAcceleration',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: null, ABO: null, EQL: 138, RI: null, FMO: null, SPAC: null }
}

export const negativePledge = {
  label: 'Negative Pledge',
  name: 'negativePledge',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: null, ABO: null, EQL: 139, RI: null, FMO: null, SPAC: null }
}

export const eventOfDefault = {
  label: 'Event of Default',
  name: 'eventOfDefault',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: null, ABO: null, EQL: 140, RI: null, FMO: null, SPAC: null }
}

export const settlementUponRedemption = {
  label: 'Settlement upon Redemption',
  name: 'settlementUponRedemption',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: null, ABO: null, EQL: 141, RI: null, FMO: null, SPAC: null }
}

export const guarantor = {
  label: 'Guarantor',
  name: 'guarantor',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: null, ABO: null, EQL: 142, RI: null, FMO: null, SPAC: null }
}

export const fairMarketCallPut = {
  label: 'Fair Market Call & Put',
  name: 'fairMarketCallPut',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: null, ABO: null, EQL: 143, RI: null, FMO: null, SPAC: null }
}

export const governingLaw = {
  label: 'Governing Law',
  name: 'governingLaw',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: null, ABO: null, EQL: 144, RI: null, FMO: null, SPAC: null }
}

export const securitiesOffered = {
  label: 'Securities Offered',
  name: 'securitiesOffered',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: null, ABO: null, EQL: 124.97, RI: null, FMO: null, SPAC: null }
}

export const exchangeProperty = {
  label: 'Exchange Property',
  name: 'exchangeProperty',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: null, ABO: null, EQL: 124.98, RI: null, FMO: null, SPAC: null }
}

export const exchangePremium = {
  label: 'Exchange Premium',
  name: 'exchangePremium',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: null, ABO: null, EQL: 124.99, RI: null, FMO: null, SPAC: null }
}

export const trancheStructure = {
  label: 'Tranche structure',
  name: 'trancheStructure',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: 159, ABO: null, EQL: null, RI: null, FMO: null, OTH: null }
}

export const employeeResPortion = {
  label: 'Employee reservation portion',
  name: 'employeeResPortion',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: 160, ABO: null, EQL: null, RI: null, FMO: null, OTH: null }
}

export const fxRate = {
  label: 'FX rate',
  name: 'fxRate',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: 161, ABO: 166, EQL: null, RI: null, FMO: null, OTH: 1449.05 }
}

export const bidLotSize = {
  label: 'Bid lot size',
  name: 'bidLotSize',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: 162, ABO: null, EQL: null, RI: null, FMO: null, OTH: null }
}

export const totalOfferQibs = {
  label: 'Total offer for QIBs',
  name: 'totalOfferQibs',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: 163, ABO: null, EQL: null, RI: null, FMO: null, OTH: null }
}

export const anchorInvPortion = {
  label: 'Anchor investor portion',
  name: 'anchorInvPortion',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: 164, ABO: null, EQL: null, RI: null, FMO: null, OTH: null }
}

export const netOfferQIBsExclAnch = {
  label: 'Net offer for QIBs excluding Anchor investors (assuming Anchor portion is fully subscribed)',
  name: 'netOfferQIBsExclAnch',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: 165, ABO: null, EQL: null, RI: null, FMO: null, OTH: null }
}

export const nonInstPortion = {
  label: 'Non-institutional portion',
  name: 'nonInstPortion',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: 166, ABO: null, EQL: null, RI: null, FMO: null, OTH: null }
}

export const retailPortion = {
  label: 'Retail portion',
  name: 'retailPortion',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: 167, ABO: null, EQL: null, RI: null, FMO: null, OTH: null }
}

export const minAnchorBid = {
  label: 'Minimum Anchor bid (adjusted for bid lot)',
  name: 'minAnchorBid',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: 168, ABO: null, EQL: null, RI: null, FMO: null, OTH: null }
}

export const maxAnchorBid = {
  label: 'Maximum Anchor bid (adjusted for bid lot)',
  name: 'maxAnchorBid',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: 169, ABO: null, EQL: null, RI: null, FMO: null, OTH: null }
}

export const minBidSizeFPI = {
  label: 'Minimum bid size for FPI / Domestic MF / Ins / Other QIB',
  name: 'minBidSizeFPI',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: 170, ABO: null, EQL: null, RI: null, FMO: null, OTH: null }
}

export const maxBidSizeFPI = {
  label: 'Maximum bid size for FPI / Domestic MF / Ins / Other QIB',
  name: 'maxBidSizeFPI',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: 171, ABO: null, EQL: null, RI: null, FMO: null, OTH: null }
}

export const anchorInvPortionRegs = {
  label: 'Anchor Investor Portion Regs',
  name: 'anchorInvPortionRegs',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: 172, ABO: null, EQL: null, RI: null, FMO: null, OTH: null }
}

export const catQibsApply = {
  label: 'Categories of QIBs who can apply (subject to applicable law & restrictions)',
  name: 'catQibsApply',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: 173, ABO: null, EQL: null, RI: null, FMO: null, OTH: null }
}

export const discountRange = {
  label: 'Discount Range',
  name: 'discountRange',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: null, ABO: 165, EQL: null, RI: null, FMO: null, OTH: 1449 }
}

export const boardLot = {
  label: 'Board Lot',
  name: 'boardLot',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: null, ABO: 167, EQL: null, RI: null, FMO: null, OTH: null }
}

export const domesticSplit = {
  label: 'Domestic Inst/Intl Split',
  name: 'domesticSplit',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: null, ABO: null, EQL: null, RI: null, FMO: null, OTH: 1449.02 }
}

export const demandAllocationPolicy = {
  label: 'Demand/Allocation Policy',
  name: 'demandAllocationPolicy',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: null, ABO: null, EQL: null, RI: null, FMO: null, OTH: 1449.03 }
}

export const underwritingShare = {
  label: 'Underwriting share',
  name: 'underwritingShare',
  type: allowedFieldTypes.text,
  termsheetRowType: termsheetRowTypes.singleColumnRow,
  termsheetRank: { IPO: null, ABO: null, EQL: null, RI: null, FMO: null, OTH: 1449.04 }
}
