import React from 'react'

import { useContextValueHandler } from './useContextValueHandler'

import { TermsheetContext } from './context'
import { observer } from 'mobx-react-lite'

export const TermsheetProvider = observer((props): JSX.Element => {
  const { value } = useContextValueHandler()

  return <TermsheetContext.Provider {...props} value={value} />
})
