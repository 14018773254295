import { makeAutoObservable } from 'mobx'

import { RootStore } from 'store/rootStore'
import { DashboardDealViewDTO, DealOrTermsheetDTO } from 'services/deals'
import { equals, mapObjIndexed, pipe, reduce, uniq, values } from 'ramda'
import {
  CountriesWithNumberOfActiveDeals,
  DealListByRegionV1,
  DealListByRegionV2,
  DealListByType,
  DealListByTypeItem,
  DealListByTypeItemType,
  DealListByTypeItemV2,
  ITermsheetV2Entity
} from 'store/types'
import { DashboardRegion, TermsheetRegion } from 'constants/index'
import {
  getAmountOfActiveDealsByCountries,
  getDealsByRegion,
  getFilteredDealsV2,
  getPartitionedDeals,
  getPartitionedDealsV2
} from 'store/dealsOrTermsheetsStore/utils'
import { ITSv2ViewRes } from 'lib/queries/types'

export const initDealsListValue: DealListByType<DealListByTypeItemType> = pipe(
  values as (obj: typeof DashboardRegion) => string[],
  reduce<string, DealListByType<DealListByTypeItemType>>(
    (acc, item) => ({
      ...acc,
      [item]: {}
    }),
    {}
  ) as unknown as () => DealListByType<DealListByTypeItemType>
)(DashboardRegion)

export class DealsOrTermsheetsStore {
  private readonly rootStore: RootStore
  private _drafts: ITermsheetV2Entity[] = []
  private _viewedDeal: ITSv2ViewRes | null = null
  private _dealsList: DealOrTermsheetDTO[] = []
  private _dealsListV2: DashboardDealViewDTO[] = []
  private _dealListByRegion: DealListByRegionV1 = {}
  private _dealListByRegionV2: DealListByRegionV2 = {}
  private _isGeoFilterActive: boolean = false
  private _cacheKey: string = Date.now().toString()

  constructor (rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }

  set drafts (value: ITermsheetV2Entity[]) {
    if (!equals(this._drafts, value)) {
      this._drafts = value
    }
  }

  get drafts (): ITermsheetV2Entity[] {
    return this._drafts
  }

  set viewedDeal (value: ITSv2ViewRes | null) {
    if (!equals(this._viewedDeal, value)) {
      this._viewedDeal = value
    }
  }

  get viewedDeal (): ITSv2ViewRes | null {
    return this._viewedDeal
  }

  set isGeoFilterEnabled (value: boolean) {
    if (!equals(this._isGeoFilterActive, value)) {
      this._isGeoFilterActive = value
    }
  }

  get isGeoFilterEnabled (): boolean {
    return this._isGeoFilterActive
  }

  get cacheKey (): string {
    return this._cacheKey
  }

  refreshCacheKey (): void {
    this._cacheKey = Date.now().toString()
  }

  set deals (data: DealOrTermsheetDTO[] | undefined) {
    if (equals(data, this._dealsList)) return
    this._dealsList = data === undefined ? [] : data
    this._dealListByRegion = getDealsByRegion(this._dealsList)
  }

  get deals (): DealOrTermsheetDTO[] {
    return this._dealsList
  }

  set dealsV2 (data: DashboardDealViewDTO[] | undefined) {
    if (equals(data, this._dealsListV2)) return
    this._dealsListV2 = data === undefined ? [] : data
    this._dealListByRegionV2 = getDealsByRegion(this._dealsListV2)
  }

  get dealsV2 (): DashboardDealViewDTO[] {
    return this._dealsListV2
  }

  get dealListByRegionV2 (): DealListByRegionV2 {
    return this._dealListByRegionV2
  }

  private getDealsListByType<T extends DealListByTypeItem | DealListByTypeItemV2> (
    type: DashboardRegion,
    regionList: TermsheetRegion[],
    version: 'v1' | 'v2'
  ): DealListByType<T> {
    const allCountryCodesWithStockExchanges = this.rootStore.countriesStore.allCountryCodesWithStockExchanges
    const countries = this.getCountriesForFiltering(type, regionList)

    return mapObjIndexed((val) =>
      // @ts-expect-error
      (version === 'v2'
        ? getPartitionedDealsV2
        : getPartitionedDeals
      )(
        {
          isGeoFilterEnabled: this.isGeoFilterEnabled,
          includeWithNoStockExchanges: this.rootStore.filterStore.includeWithNoStockExchanges
        },
        val as any,
        countries,
        allCountryCodesWithStockExchanges
      ), version === 'v2' ? this._dealListByRegionV2 : this._dealListByRegion)
  }

  getDealsListByTypeV2 (type: DashboardRegion, regionList: TermsheetRegion[]): DealListByType<DealListByTypeItemV2> {
    if (this._dealsListV2.length === 0) return initDealsListValue
    return this.getDealsListByType(type, regionList, 'v2')
  }

  getDealsForListViewV2 (type: DashboardRegion, regionList: TermsheetRegion[]): Record<string, DashboardDealViewDTO[]> {
    if (this._dealsListV2.length === 0) return {}
    const allCountryCodesWithStockExchanges = this.rootStore.countriesStore.allCountryCodesWithStockExchanges
    const countries = this.getCountriesForFiltering(type, regionList)
    return mapObjIndexed((val) =>
      getFilteredDealsV2(
        {
          isGeoFilterEnabled: this.isGeoFilterEnabled,
          includeWithNoStockExchanges: this.rootStore.filterStore.includeWithNoStockExchanges
        },
        val as any,
        countries,
        allCountryCodesWithStockExchanges
      ), this.dealListByRegionV2)
  }

  getDealsListByTypeV1 (type: DashboardRegion, regionList: TermsheetRegion[]): DealListByType<DealListByTypeItem> {
    if (this._dealsList.length === 0) return initDealsListValue
    return this.getDealsListByType(type, regionList, 'v1')
  }

  getAmountOfActiveDealsByCountriesAndRegion (
    region: DashboardRegion | TermsheetRegion,
    version: 'v1' | 'v2'
  ): CountriesWithNumberOfActiveDeals {
    return getAmountOfActiveDealsByCountries((
      version === 'v2'
        ? this._dealListByRegionV2
        : this._dealListByRegion
    )[region])
  }

  getCountriesForFiltering (type: DashboardRegion, regionList: TermsheetRegion[]): string[] {
    return type === DashboardRegion.ALL
      ? uniq(regionList.reduce<string[]>((acc, region) =>
        [...acc, ...this.rootStore.filterStore.filter[region].countries],
      []))
      : this.rootStore.filterStore.filter[type].countries
  }
}
