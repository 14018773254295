import React from 'react'
import { ProfileProvider as AuthProvider } from './user-auth-context/index'
import { DealsProvider } from './deals-context'
import { ModalProvider } from './modal-context'
import { ThemeProvider } from '@emotion/react'
import { lightThemeColors } from '../theme'
import { ModuleProvider } from './modules-context'
import { NavbarBackButtonProvider } from '../components/Buttons/NavbarBackButton/context'

import { DealFormProvider } from '../containers/DealForm/DealFormProvider'
import { TermsheetProvider } from '../containers/TermsheetInformation/TermsheetProvider'
import { TermsheetModalProvider } from './modal-context/TermsheetModals/context'
import { store } from '../store/store'
import { StoreProvider } from 'easy-peasy'

interface AppProvidersProps {
  children: any
  isMfe?: boolean
}

export function AppProviders ({ children, isMfe = false }: AppProvidersProps): JSX.Element {
  return (
    <StoreProvider store={store}>
      <AuthProvider isMfe={isMfe}>
        <ModuleProvider>
          <ThemeProvider theme={lightThemeColors}>
            <DealsProvider>
              <DealFormProvider>
                <TermsheetProvider>
                  <ModalProvider>
                    <NavbarBackButtonProvider>
                      <TermsheetModalProvider>{children}</TermsheetModalProvider>
                    </NavbarBackButtonProvider>
                  </ModalProvider>
                </TermsheetProvider>
              </DealFormProvider>
            </DealsProvider>
          </ThemeProvider>
        </ModuleProvider>
      </AuthProvider>
    </StoreProvider>
  )
}
