import { ChevronDownIcon, ChevronUpIcon, SelectorIcon } from '@heroicons/react/solid'
import clsx from 'clsx'
import React, { useCallback, useEffect, useImperativeHandle } from 'react'
import { useBlockLayout, useFilters, useGlobalFilter, useRowState, useSortBy, useTable } from 'react-table'
import AutoSizer from 'react-virtualized-auto-sizer'
import { FixedSizeList } from 'react-window'
import { v4 as uuidv4 } from 'uuid'
import { TableContainer, TableHeader, TableHeaderItem, TableNoDataMessage } from 'ui/Table/Table'
import { useRowSelect } from 'ui/DataGrid/plugins/customRowSelect/useRowSelect'
import { DataGridProps, ReactTableInstance } from 'ui/DataGrid/types'
import RenderRow from 'ui/DataGrid/RenderRow'

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, disabled, ...rest }: any, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = (ref != null) || defaultRef

    React.useEffect(() => {
      (resolvedRef as any).current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <>
        <input
          type='checkbox'
          className={
            clsx(
              disabled !== true ? 'border-gray-900' : 'border-gray-200'
            )
          }
          disabled={disabled}
          ref={resolvedRef} {...rest}
          role='select'
        />

      </>
    )
  }
)
const rowSelectHook = ({
  handleSelectChange,
  isDisabledRow,
  isChecked,
  isAllChecked,
  handleAllUnselected
}) => (hooks): any => {
  hooks.visibleColumns.push(columns => [
    // Let's make a column for selection
    {
      id: 'selection',
      Header: () => {
        const isChecked: boolean = isAllChecked()
        return isChecked
          ? (
            <input
              type='checkbox'
              checked={isChecked}
              onClick={() => handleAllUnselected()}
              onChange={handleSelectChange(null)}
              className='cursor-pointer'
            />

            )
          : null
      },
      width: 'w-12',
      hideHeader: true,
      // ),
      // The cell can use the individual row's getToggleRowSelectedProps method
      // to the render a checkbox
      Cell: (values: any) => {
        return (
          <div>
            <IndeterminateCheckbox
              {...values.row.getToggleRowSelectedProps()}
              checked={isChecked(values.row.original)}
              onChange={handleSelectChange(values.row)}
              disabled={isDisabledRow !== undefined ? isDisabledRow(values.row.values) : false}
            />
          </div>
        )
      }
    },
    ...columns
  ])
}

const DataGridComponent = <T extends unknown> ({
  columns, data, noDataMsg, bulkSelectProps, reactTableOptions, additionalTableClasses,
  globalFilterValue, columnFilterValues, itemSize, dataQa, disableSortBy
}: DataGridProps<T>, ref: React.ForwardedRef<ReactTableInstance>): JSX.Element => {
  const useBulkSelect = (): any => {
    if (bulkSelectProps !== undefined) {
      return [useRowSelect, rowSelectHook({
        handleSelectChange: bulkSelectProps.handleSelected,
        isDisabledRow: bulkSelectProps.isDisabledRow,
        isChecked: bulkSelectProps.isChecked,
        isAllChecked: bulkSelectProps.isAllChecked,
        handleAllUnselected: bulkSelectProps.handleAllUnselected
      }
      )]
    }
    return []
  }
  const defaultColumn = React.useMemo(
    () => ({
      width: 'w-100'
    }),
    []
  )

  const tableInstance = useTable({
    columns,
    data,
    autoResetGlobalFilter: false,
    defaultColumn,
    disableSortBy,
    stateReducer: useCallback(
      (newState, action) => {
        if (action.type === 'resetSelectedRows' ||
              (action.type === 'toggleAllRowsSelected' && action.value === false)) {
          if (bulkSelectProps?.onResetSelectedRows !== undefined) {
            bulkSelectProps?.onResetSelectedRows()
          }
        }
        return newState
      }, [bulkSelectProps]),
    ...reactTableOptions,
    useBlockLayout

  },
  useGlobalFilter,
  useFilters,
  useSortBy,
  useRowSelect,
  useRowState,
  ...useBulkSelect()
  ) as ReactTableInstance
  useImperativeHandle(ref, () => tableInstance)

  const {
    getTableBodyProps,
    headerGroups,
    headers,
    rows,
    prepareRow,
    setGlobalFilter,
    setFilter
  } = tableInstance

  useEffect(
    () => {
      setGlobalFilter(globalFilterValue)
    }
    , [setGlobalFilter, globalFilterValue])

  useEffect(() => {
    if (columnFilterValues != null) {
      columnFilterValues.forEach(([filter, value]) => setFilter(filter, value))
    }
  }, [setFilter, columnFilterValues])
  const renderRow = RenderRow(prepareRow, rows, dataQa)
  return (
    <div className='w-full'>
      <TableContainer {...getTableBodyProps()} additionalTableClasses={additionalTableClasses}>
        <TableHeader {...headerGroups[0].getHeaderGroupProps()}>
          {headers.map((column, idx) => (
            <TableHeaderItem
              {...column.getHeaderProps({ ...column.getSortByToggleProps() })}
              key={`headerItem-${idx}`}
              additionalClasses={column.width}
              title={column.title}
            >
              {column.render('Header', { setGlobalFilter: setGlobalFilter, key: uuidv4('column-header') })}
              {(column.hideHeader !== true && (disableSortBy === false) && column.disableSortBy !== true)
                ? (column.isSorted)
                    ? column.isSortedDesc
                      ? <ChevronDownIcon className='object-right h-3 w-3 inline mb-1' key={uuidv4('column-icon-down')} />
                      : <ChevronUpIcon className='object-right h-3 w-3 inline mb-1' key={uuidv4('column-icon-up')} />
                    : <SelectorIcon className='object-right h-3 w-3 inline mb-1' key={uuidv4('column-icon-selector')} />
                : <React.Fragment key={uuidv4('no-icon')} />}
            </TableHeaderItem>
          ))}
        </TableHeader>
        <>{rows.length <= 0 && <TableNoDataMessage>{noDataMsg ?? 'No Data'}</TableNoDataMessage>}</>
        <AutoSizer>
          {({ height, width }) => (
            <FixedSizeList
              height={height}
              itemCount={rows.length}
              itemSize={itemSize}
              width={width}
            >
              {renderRow}
            </FixedSizeList>
          )}

        </AutoSizer>
      </TableContainer>
    </div>
  )
}
export const DataGrid = React.forwardRef(DataGridComponent) as <T> (
  props: DataGridProps<T> & { ref?: React.ForwardedRef<ReactTableInstance> }
) => ReturnType<typeof DataGridComponent>
