import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import Autocomplete from '@material-ui/lab/Autocomplete'
import styled from '@emotion/styled'
import { TextField } from '@material-ui/core'
import { FormLabel } from '../FormLabel'
import { getErrorLine } from '../ErrorLine/getErrorLine'

export function AutocompleteComponent (props): JSX.Element {
  const { label, name, onChange, value, array } = props
  return (
    <AutocompleteStyled>
      <Autocomplete
        options={array}
        value={value}
        onChange={(event, newValue) => {
          onChange(name, newValue)
        }}
        getOptionSelected={(option, value) => option === value || value === '' || value === '-'}
        getOptionLabel={(option) => option}
        renderInput={(params) => (
          <>
            <FormLabel>{label}</FormLabel>
            <TextField {...params} variant='outlined' />
          </>
        )}
      />
    </AutocompleteStyled>
  )
}

AutocompleteComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
  array: PropTypes.instanceOf(Array).isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
}

const AutocompleteStyled = styled.div`
  .MuiAutocomplete-inputRoot {
    padding: 1px 7px;
  }
`

interface AutocompleteHookedProps {
  name?: string
  defaultValue: string | number
  label?: string
  optionsArray: any[]
  errors: any
  setValue: any
  register: any
  watch: any
}

export const AutocompleteHooked = ({ name, label, optionsArray, errors, setValue, register, defaultValue }: AutocompleteHookedProps): JSX.Element => {
  useEffect(() => {
    register({ name })
  }, [register, name])

  return (
    <AutocompleteStyled>
      <Autocomplete
        defaultValue={defaultValue}
        options={optionsArray}
        onChange={(event, newValue) => {
          setValue(name, newValue, { shouldValidate: true })
        }}
        // Form native components below here.
        getOptionSelected={(option, value) => option === value || value === '' || value === '-'}
        getOptionLabel={(option) => option}
        renderInput={(params) => (
          <>
            <FormLabel>{label}</FormLabel>
            <TextField error={false} {...params} variant='outlined' />
          </>
        )}
      />
      {getErrorLine(errors, name)}
    </AutocompleteStyled>
  )
}
