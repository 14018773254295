/**
 * IF YOU CHANGE THIS FILE,
 * YOU MUST ALSO UPDATE THE MATCHING FILE IN THE API REPO
 */
import difference from 'lodash.difference'

import {
  finalCoupon,
  finalOfferingSharePrice,
  finalOfferingSizeNrShares,
  finalOfferingSizeNrSharesPrimary,
  finalOfferingSizeNrSharesSecondary,
  finalOfferingSizeValue,
  greenshoePrimaryShares,
  greenshoeSecondaryShares,
  greenshoeSharesTotal,
  offeringCurrencyType,
  primarySecondaryTotal,
  stabAgent,
  finalYieldToMaturity,
  finalConversionPremium,
  finalIssuePrice
} from '../../formTypes/inputFields'

import { finalSettlementDate, lastDayOfStabTrading, pricingDate, startOfTradingDate, closeOfBookbuildingDate } from '../../formTypes/datePickers'
import { fieldsToExcludePrimarySecondaryField } from '..'

const priceDealConfigShares = [finalOfferingSizeNrSharesPrimary, finalOfferingSizeNrSharesSecondary, finalOfferingSizeNrShares, primarySecondaryTotal]

const priceDealConfigValue = [offeringCurrencyType, finalOfferingSizeValue]

const timetableConfig = [pricingDate, startOfTradingDate, finalSettlementDate]

const withDrawConfig = [pricingDate, closeOfBookbuildingDate]

const greenshoeConfig = [greenshoePrimaryShares, greenshoeSecondaryShares, greenshoeSharesTotal, stabAgent]

const finalPricesDataConfig = [finalCoupon, finalYieldToMaturity, finalConversionPremium, finalIssuePrice]

export const getPriceDealConfigShares = (type) => {
  return difference(priceDealConfigShares, fieldsToExcludePrimarySecondaryField(type))
}

export const getPriceDealPriceRange = () => [finalOfferingSharePrice]

export const getPriceDealConfigValue = () => priceDealConfigValue

export const getPriceDealTimetableConfig = () => timetableConfig

export const getGreenshoeConfig = () => greenshoeConfig

export const getFinalPricedDataConfig = () => finalPricesDataConfig

export const getGreenshoeTimetableConfig = () => [lastDayOfStabTrading]

export const getWithDrawConfig = () => withDrawConfig
