import styled from '@emotion/styled'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { useUserRole } from 'lib'
import React, { useCallback } from 'react'
import { PublishButtonAddNewDeal } from '../../../../components/Buttons/AddNewDeal/PublishButtonAddNewDeal'

interface PreviewAndPublishProps {
  onSaveDraft?: any
  onDeleteDraft?: any
  shouldShowSaveAsDraft?: boolean
  isDelete?: any
  title?: string
  type?: any
  onClick?: any
  disabled?: boolean
  'data-qa'?: string
}

export const PreviewAndPublish = ({ onSaveDraft, onDeleteDraft, shouldShowSaveAsDraft, isDelete, 'data-qa': dataQA = '', ...props }: PreviewAndPublishProps): JSX.Element => {
  const { isTaaS } = useUserRole()
  const [open, setOpen] = React.useState<boolean>(false)
  const anchorRef = React.useRef<HTMLDivElement>(null)

  const handleToggle = (): void => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = useCallback(
    (event) => {
      if ((anchorRef?.current?.contains(event.target)) ?? false) {
        return
      }

      setOpen(false)
    },
    [anchorRef]
  )

  const handleSaveAsDraft = useCallback(
    (event) => {
      handleClose(event)
      if (onSaveDraft !== undefined) {
        onSaveDraft()
      }
    },
    [onSaveDraft, handleClose]
  )

  const handleDeleteDraft = useCallback(
    (event) => {
      handleClose(event)
      if (onDeleteDraft !== undefined) {
        onDeleteDraft()
      }
    },
    [onDeleteDraft, handleClose]
  )
  if (props.title) {
    return (
      <div>
        <PublishButtonAddNewDeal label={props.title} {...props} data-qa={`${dataQA}-PublishButton`} />
      </div>
    )
  }
  // Is it button in delete draft modal ?
  if (isDelete === true) {
    return (
      <div>
        <PublishButtonAddNewDeal label='Delete' {...props} data-qa={`${dataQA}-PublishButton`} />
      </div>
    )
  } else if (shouldShowSaveAsDraft === undefined || shouldShowSaveAsDraft === null || !shouldShowSaveAsDraft) {
    return (
      <div>
        <PublishButtonAddNewDeal label='Publish' {...props} data-qa={`${dataQA}-PublishButton`} />
      </div>
    )
  }

  if (isTaaS) {
    return (
      <div>
        <ButtonGroup variant='contained' color='primary' ref={anchorRef}>
          <PublishButtonAddNewDeal label='Publish' {...props} data-qa={`${dataQA}PublishButton`} />
        </ButtonGroup>
      </div>
    )
  }

  return (
    <div>
      <ButtonGroup variant='contained' color='primary' ref={anchorRef} aria-label='split button'>
        <PublishButtonAddNewDeal label='Publish' {...props} data-qa={`${dataQA}PublishButton`} />
        <Button
          color='primary'
          size='small'
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label='select merge strategy'
          aria-haspopup='menu'
          onClick={handleToggle}
          data-qa={`${dataQA}PublishDropdown`}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <ButtonGroupStyled>
                  <PublishButtonAddNewDeal
                    label='Save as draft'
                    onClick={handleSaveAsDraft}
                    className='MuiButtonBase-root MuiButton-root MuiButton-contained MuiButtonGroup-grouped MuiButtonGroup-groupedHorizontal MuiButtonGroup-groupedContained MuiButtonGroup-groupedContainedHorizontal MuiButtonGroup-groupedContainedPrimary MuiButton-containedPrimary MuiButton-fullWidth'
                    data-qa='AddDeal-SaveAsDraftButton'
                  />
                  <PublishButtonAddNewDeal label='Delete draft' onClick={handleDeleteDraft} />
                </ButtonGroupStyled>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  )
}

const ButtonGroupStyled = styled.div`
  margin: 2px 2px 2px -24px;
  background: none;
  border: none;
  & > div {
    display: flex;
    justify-content: space-between;
  }
`
