import { createContext, useContext } from 'react'

export const DealFormContext = createContext<any>(undefined)

export const useDealFormContext = (): any => {
  const context = useContext<any>(DealFormContext)

  if (!(context as boolean)) {
    throw new Error('useDealFormContext must be inside DealFormProvider')
  }

  return context
}
