/**
 * IF YOU CHANGE THIS FILE,
 * YOU MUST ALSO UPDATE THE MATCHING FILE IN THE API REPO
 */
import difference from 'lodash.difference'

import {
  additionalInfo,
  couponRange,
  finalCoupon,
  finalYieldToMaturity,
  finalConversionPremium,
  finalIssuePrice,
  yieldToMaturity,
  conversionPremium,
  issuePrice,
  finalOfferingSharePrice,
  finalOfferingSizeNrShares,
  finalOfferingSizeNrSharesPrimary,
  finalOfferingSizeNrSharesSecondary,
  finalOfferingSizeValue,
  greenshoePrimaryShares,
  greenshoeSecondaryShares,
  headerDisclaimerText,
  issuerLegalName,
  issuerName,
  lockUpExpiryDays,
  mifidTargetMarket,
  offeringSharePriceRange,
  offeringSizeNrShares,
  offeringSizeNrSharesPrimary,
  offeringSizeNrSharesSecondary,
  offeringSizeValue,
  offeringType,
  percentCapitalIncrease,
  primarySecondaryString,
  primarySecondaryTotal,
  publishingBankRole,
  region,
  sellingShareholders,
  stabAgent,
  useOfProceeds
} from '../formTypes/inputFields'

import { expectedPricingDate, finalSettlementDate, pricingDate, settlementDate } from '../formTypes/datePickers'

import { additionalQuestionsIpo, additionalQuestionsIpoApac, mainQuestionsIpo, timetableIpo } from './configs/ipoConfig'
import { additionalQuestionsAbo, additionalQuestionsAboApac, mainQuestionsAbo, timetableAbo } from './configs/aboConfig'
import { additionalQuestionsFmo, mainQuestionsFmo, timetableFmo } from './configs/fmoConfig'
import { additionalQuestionsRi, mainQuestionsRi, timetableRi } from './configs/riConfig'
import { additionalQuestionsEql, mainQuestionsEql, timetableEql } from './configs/eqlConfig'
import { additionalQuestionsOth, additionalQuestionsOthApac, mainQuestionsOth, timetableOth } from './configs/othConfig'
import { additionalQuestionsSpac, mainQuestionsSpac, timetableSpac } from './configs/spacConfig'

import { formConfigStep1 } from './configs/step1'
import { TBA } from '../../constants'

export const isPricingDate = (date) => date && date !== TBA && date !== ''

// For all deals the form config step 1 will be the same.
export const getFormConfigStep1 = (offeringType) => {
  return difference(formConfigStep1, fieldsToExcludeOfferingTypeField(offeringType))
}

export const getMainQuestionsConfig = (offeringTypeParam, isTermsheetView, primarySecondary, isPricingDate, isEdit) => {
  // use second parameter>false when preview termsheet in add new deal
  // supply offering type, issuer name, issuer name legal manually bacause they come from first form

  const fieldsToExcludeInRender = [
    ...fieldsToExcludePricingDate(isPricingDate),
    ...fieldsToExcludePrimarySecondaryField(primarySecondary),
    headerDisclaimerText
  ]

  const fieldsToExclude = [...fieldsToExcludePricingDate(isPricingDate), ...fieldsToExcludePrimarySecondaryField(primarySecondary)]

  const mainQuestions = difference(mainQuestionsConfig[offeringTypeParam], isEdit ? fieldsToExclude : fieldsToExcludeInRender)

  const injectValuesFormOne = [publishingBankRole, offeringType, region, issuerName, issuerLegalName, primarySecondaryString, ...mainQuestions]

  return isTermsheetView ? difference(injectValuesFormOne, fieldsToExcludeOfferingTypeField(offeringTypeParam)) : mainQuestions
}

export const getAdditionalQuestionsConfig = (offeringType, primarySecondary, isPricingDate, region) => {
  const fieldsToExclude = [...fieldsToExcludePricingDate(isPricingDate), ...fieldsToExcludePrimarySecondaryField(primarySecondary)]
  const result = difference(additionalQuestionsConfig[offeringType], fieldsToExclude)
  if (region === 'APAC') {
    const resultWithoutAdditionalInfo = result.filter(field => field.name !== 'additionalInfo')
    if (offeringType === 'IPO') {
      return [...resultWithoutAdditionalInfo, ...additionalQuestionsIpoApac, additionalInfo]
    }
    if (offeringType === 'ABO') {
      return [...resultWithoutAdditionalInfo, ...additionalQuestionsAboApac, additionalInfo]
    }
    if (offeringType === 'OTH') {
      return [...resultWithoutAdditionalInfo, ...additionalQuestionsOthApac, additionalInfo]
    }
  }
  return result
}

export const getTimetableFieldsConfig = (offeringType, isPricingDate) => {
  return difference(timetableConfig[offeringType], fieldsToExcludePricingDate(isPricingDate))
}

const mainQuestionsConfig = {
  IPO: mainQuestionsIpo,
  ABO: mainQuestionsAbo,
  FMO: mainQuestionsFmo,
  RI: mainQuestionsRi,
  OTH: mainQuestionsOth,
  EQL: mainQuestionsEql,
  SPAC: mainQuestionsSpac
}

const additionalQuestionsConfig = {
  IPO: additionalQuestionsIpo,
  ABO: additionalQuestionsAbo,
  FMO: additionalQuestionsFmo,
  RI: additionalQuestionsRi,
  OTH: additionalQuestionsOth,
  EQL: additionalQuestionsEql,
  SPAC: additionalQuestionsSpac
}

const timetableConfig = {
  IPO: timetableIpo,
  ABO: timetableAbo,
  FMO: timetableFmo,
  RI: timetableRi,
  OTH: timetableOth,
  EQL: timetableEql,
  SPAC: timetableSpac
}

const fieldsToExcludePricingDate = (isPricingDate) =>
  isPricingDate
    ? [
        expectedPricingDate,
        settlementDate,
        offeringSizeValue,
        couponRange,
        yieldToMaturity,
        conversionPremium,
        issuePrice,
        offeringSharePriceRange,
        offeringSizeNrShares,
        offeringSizeNrSharesPrimary,
        offeringSizeNrSharesSecondary
      ]
    : [
        greenshoePrimaryShares,
        greenshoeSecondaryShares,
        stabAgent,
        finalCoupon,
        finalYieldToMaturity,
        finalConversionPremium,
        finalIssuePrice,
        pricingDate,
        finalOfferingSizeValue,
        finalOfferingSharePrice,
        finalOfferingSizeNrShares,
        finalOfferingSizeNrSharesPrimary,
        finalOfferingSizeNrSharesSecondary,
        finalSettlementDate
      ]

const fieldsToExcludeOfferingTypeField = (type) => {
  switch (type) {
    case 'IPO':
      return []
    case 'ABO':
      return []
    case 'FMO':
      return []
    case 'OTH':
      return []

    default:
      return [primarySecondaryString]
  }
}

export const fieldsToExcludePrimarySecondaryField = (type) => {
  switch (type) {
    case 'Primary':
      return [
        sellingShareholders,
        offeringSizeNrSharesPrimary,
        offeringSizeNrSharesSecondary,
        primarySecondaryTotal,
        finalOfferingSizeNrSharesPrimary,
        finalOfferingSizeNrSharesSecondary
      ]
    case 'Secondary':
      return [
        percentCapitalIncrease,
        lockUpExpiryDays,
        useOfProceeds,
        mifidTargetMarket,
        offeringSizeNrSharesPrimary,
        offeringSizeNrSharesSecondary,
        primarySecondaryTotal,
        finalOfferingSizeNrSharesPrimary,
        finalOfferingSizeNrSharesSecondary
      ]
    case 'Primary and Secondary':
      return [offeringSizeNrShares, finalOfferingSizeNrShares]
    default:
      return []
  }
}
