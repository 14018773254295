import { cond, isNil, omit, pipe, propEq } from 'ramda'
import { BookbuildMessage } from 'services/bookbuild-messages'
import { CondArg, ReturnTypeForGetBookBuildDataByType } from 'components/Termsheet/View/BookbuildingMessages/types'
import { ProfileState } from 'contexts/user-auth-context'
import { isReallyEmpty } from 'utils'
import find from 'ramda/src/find'
import pathEq from 'ramda/src/pathEq'
import { SortedTermsheet } from 'utils/generateTermsheetOptionsArray'

export const createBookBuildingMessages = (message, email, publishingBankName, date): any => {
  return {
    timeStamp: !isNil(date) ? date : new Date(),
    messageText: message,
    publishingBankerName: publishingBankName,
    userEmail: email
  }
}

export const apiReadybookBuildingMessagesValuesForAdd = (
  newMessage: string,
  selectedTermsheet: {
    bookBuildingMessages?: BookbuildMessage[]
    dealId: string
    termsheetId: string
    publishingBankName?: string
  },
  profile: ProfileState
): BookbuildMessage[] => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { email, given_name, family_name } = profile
  const { bookBuildingMessages } = selectedTermsheet
  const existingMessages = !isReallyEmpty(bookBuildingMessages) ? [...bookBuildingMessages as BookbuildMessage[]] : []
  const apiReadyNewMessage = createBookBuildingMessages(
    newMessage,
    email,
    !isReallyEmpty(selectedTermsheet.publishingBankName)
      ? selectedTermsheet.publishingBankName
      : `${given_name} ${family_name}`, undefined)
  return [apiReadyNewMessage, ...existingMessages]
}

const getTermsheetObjectById = (
  termsheets: SortedTermsheet[],
  termsheetId: string
): {bookBuildingMessages: BookbuildMessage[]} => {
  const termsheetToUpdate = find(pathEq(['termsheetObject', 'termsheetId'], termsheetId))(termsheets)
  return termsheetToUpdate.termsheetObject
}

const apiReadybookBuildingMessagesValuesForEdit = (
  newMessage: string,
  index: number,
  termsheetToUpdateId: string,
  termsheets: SortedTermsheet[]
): BookbuildMessage[] => {
  const termsheetToUpdate = getTermsheetObjectById(termsheets, termsheetToUpdateId)
  const existingMessages = [...termsheetToUpdate.bookBuildingMessages]
  existingMessages[index].messageText = newMessage
  return existingMessages
}

const apiReadybookBuildingMessagesValuesForRemove = (
  indexToRemove: number,
  termsheetToUpdateId: string,
  termsheets: SortedTermsheet[]
): BookbuildMessage[] => {
  const termsheetToUpdate = getTermsheetObjectById(termsheets, termsheetToUpdateId)
  return termsheetToUpdate.bookBuildingMessages.filter((msg, index) => index !== indexToRemove)
}

export const getBookBuildDataByType = cond([
  [propEq('type', 'add'), pipe(({ data, selectedTermsheet, profile }: CondArg) => ({
    successMsg: 'Message added successfully',
    errorMsg: 'Could not add message, please try again',
    bookBuildingMessages: apiReadybookBuildingMessagesValuesForAdd(
      data.bookBuildingMessage as string,
      selectedTermsheet,
      profile
    )
  }), (res) => ({ ...res, newMsg: res.bookBuildingMessages[0] }))],
  [propEq('type', 'edit'), pipe(({ data, termsheets }: CondArg) => ({
    successMsg: 'Message saved',
    errorMsg: 'Could not save message, please try again',
    index: data.index as number,
    bookBuildingMessages: apiReadybookBuildingMessagesValuesForEdit(
      data.bookBuildingMessage as string,
      data.index as number,
      data.termsheetId,
      termsheets
    )
  }), (res) => ({ ...omit(['index'], res), newMsg: res.bookBuildingMessages[res.index] }))],
  [propEq('type', 'remove'), ({ data, termsheets }: CondArg) => ({
    successMsg: 'Message removed',
    errorMsg: 'Could not save changes, please try again',
    bookBuildingMessages: apiReadybookBuildingMessagesValuesForRemove(
      data.index as number,
      data.termsheetId,
      termsheets
    ),
    newMsg: null
  }) as unknown as ReturnTypeForGetBookBuildDataByType]
]) as (arg: CondArg) => ReturnTypeForGetBookBuildDataByType
