/**
 * IF YOU CHANGE THIS FILE,
 * YOU MUST ALSO UPDATE THE MATCHING FILE IN THE API REPO
 */

export const dateTimeDealRecived = {
  name: 'dateTimeDealRecived',
  label: 'Select date and time you received the deal'
}

export const dealAnnouncementDate = {
  name: 'dealAnnouncementDate',
  label: 'Deal Announcement'
}

export const researchPublicationDate = {
  name: 'researchPublicationDate',
  label: 'Research Publication'
}

export const prospectusPublicationDate = {
  name: 'prospectusPublicationDate',
  label: 'Publication of the Prospectus'
}

export const startOfBookbuildingDate = {
  name: 'startOfBookbuildingDate',
  label: 'Start of bookbuilding'
}

export const closeOfBookbuildingDate = {
  name: 'closeOfBookbuildingDate',
  label: 'Expected books close date'
}

export const closeOfRetailOfferingDate = {
  name: 'closeOfRetailOfferingDate',
  label: 'Close of Retail offering'
}

export const allocationsReleasedDate = {
  name: 'allocationsReleasedDate',
  label: 'Allocations released'
}

export const startOfTradingDate = {
  name: 'startOfTradingDate',
  label: 'Start of trading'
}

export const settlementDate = {
  // settlementDate is added on initial termsheed creations. Is replaced by finalSettlementDate after the deal was priced.
  name: 'settlementDate',
  label: 'Settlement'
}
export const finalSettlementDate = {
  ...settlementDate,
  label: 'Final Settlement Date'
}

export const expectedPricingDate = {
  //
  // Expected pricing is in use from termsheet's initial creation up until the termsheet is priced using price deal functionality.
  // After the deal is priced expectedPricingDate is replaced by the pricingDate.
  name: 'expectedPricingDate',
  label: 'Expected pricing date'
}

export const pricingDate = {
  // Pricing date is added to the termsheet in price deal flow. After pricing date is added to the termsheet,
  // the termsheet is regarded to be as priced and is added to recently priced deals on the dashboard.
  name: 'pricingDate',
  label: 'Pricing date'
}

export const egmDate = {
  name: 'egmDate',
  label: 'EGM'
}

export const lastDayOfCRTradingDate = {
  name: 'lastDayOfCRTradingDate',
  label: 'Last day of cum-rights trading'
}

export const exDate = {
  name: 'exDate',
  label: 'Ex-rights Date'
}

export const recordDate = {
  name: 'recordDate',
  label: 'Record Date'
}

export const startOfRightsTradingDate = {
  name: 'startOfRightsTradingDate',
  label: 'Start of rights trading'
}

export const startOfSubscriptionPeriodDate = {
  name: 'startOfSubscriptionPeriodDate',
  label: 'Start of subscription period'
}

export const startOfTradingNewSharesDate = {
  name: 'startOfTradingNewSharesDate',
  label: 'Start of trading new shares'
}

export const announcementOfResultsDate = {
  name: 'announcementOfResultsDate',
  label: 'Announcement of results'
}

export const settlementOfNewSharesDate = {
  name: 'settlementOfNewSharesDate',
  label: 'Settlement of new shares'
}

export const endOfRightsTradingDate = {
  name: 'endOfRightsTradingDate',
  label: 'End of rights trading'
}

export const endOfSubscriptionPeriodDate = {
  name: 'endOfSubscriptionPeriodDate',
  label: 'End of subscription period'
}

export const rumpPlacementDate = {
  name: 'rumpPlacementDate',
  label: 'Rump placement'
}

export const maturityDate = {
  name: 'maturityDate',
  label: 'Maturity date'
}

export const lastDayOfStabTrading = {
  name: 'lastDayOfStabTrading',
  label: 'Last Day of Stab Trading'
}

export const roadshowScheduleTiming = {
  name: 'roadshowScheduleTiming',
  label: 'Roadshow Schedule/Timing'
}
