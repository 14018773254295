import React from 'react'
import { CommonStore } from 'store/commonStore/commonStore'
import { FilterStore } from 'store/filterStore/filterStore'
import { CountriesStore } from 'store/countriesStore/countriesStore'
import { DealsOrTermsheetsStore } from 'store/dealsOrTermsheetsStore/dealsOrTermsheetsStore'
import { ConnectionsStore } from 'routes/ConnectionsPage/connectionsStore/connectionsStore'
import { BookbuildingMessagesStore } from 'store/bookbuildingMessagesStore/bookbuildingMessagesStore'
import { UserStore } from 'store/userStore/userStore'
import { AlertStore } from 'store/alertStore/alertStore'
import { RedirectionStore } from 'store/redirectionStore/redirectionStore'
import { ApplicationGeneratorStore } from 'store/applicationGeneratorStore/applicationGeneratorStore'
import { RfnJourneyStore } from 'store/rfnJourneyStore/rfnJourneyStore'
import { TempTermsheetsStore } from 'store/tempTermsheetsStore/tempTermsheetsStore'

export class RootStore {
  commonStore: CommonStore
  alertStore: AlertStore
  filterStore: FilterStore
  connectionsStore: ConnectionsStore
  connectedConnectionsStore: ConnectionsStore
  countriesStore: CountriesStore
  dealsOrTermsheetsStore: DealsOrTermsheetsStore
  bookbuildingMessagesStore: BookbuildingMessagesStore
  applicationGeneratorStore: ApplicationGeneratorStore
  userStore: UserStore
  redirectionStore: RedirectionStore
  rfnJourneyStore: RfnJourneyStore
  tempTermsheetsStore: TempTermsheetsStore
  constructor () {
    this.commonStore = new CommonStore(this)
    this.alertStore = new AlertStore(this)
    this.filterStore = new FilterStore(this)
    this.connectionsStore = new ConnectionsStore(this)
    this.countriesStore = new CountriesStore(this)
    this.dealsOrTermsheetsStore = new DealsOrTermsheetsStore(this)
    this.bookbuildingMessagesStore = new BookbuildingMessagesStore(this)
    this.connectedConnectionsStore = new ConnectionsStore(this)
    this.applicationGeneratorStore = new ApplicationGeneratorStore(this)
    this.userStore = new UserStore(this)
    this.redirectionStore = new RedirectionStore(this)
    this.rfnJourneyStore = new RfnJourneyStore(this)
    this.tempTermsheetsStore = new TempTermsheetsStore(this)
  }
}

export const rootStore = new RootStore()
const RootStoreContext = React.createContext(rootStore)
export const useStores = (): RootStore => React.useContext(RootStoreContext)
