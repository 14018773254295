import { useGetBankerOrganizations } from 'lib/queries/useGetBankerOrganizations'
import Linkify from 'linkify-react'
import PropTypes from 'prop-types'
import punycode from 'punycode/'
import React, { useContext } from 'react'
import { linkifyDefaults } from 'config/linkifyDefaults'
import { OFFERING_TYPES } from 'constants/index'
import { ORGANIZATION_TYPES } from 'constants/organizations'
import { ModalContext } from 'contexts/modal-context'
import { UnderwritingSyndicateModal } from 'contexts/modal-context/UnderwitingSyndicateModal'
import { useProfileHook } from 'contexts/user-auth-context'
import { convertSyndicateBanks } from 'utils'
import { EditModeButton } from 'components/Buttons/TopOfPageButtons/EditModeButton'
import { GenerateTermsheetTableRows } from 'components/Termsheet/View/TermsheetTable/GenerateTermsheetTableRows'
import { usePublishingBankLogo } from 'components/Termsheet/View/TermsheetTable/hooks'
import { TermsheetTableHeaderStyled, TermsheetTableStyled, TermsheetTableTitleStyled } from 'components/Termsheet/View/TermsheetTable/styles'

function createSyndicateList (otherSyndicateBanks, publishingBank): string {
  // @Todo!! --> Bank roles will be changed into a seperate package.
  const syndicateObject = convertSyndicateBanks(otherSyndicateBanks)
  const syndicateObjectWithPublishingBank = {
    ...syndicateObject,
    allBanks: [publishingBank, ...syndicateObject.allBanks]
  }

  return syndicateObjectWithPublishingBank.allBanks.slice(0, 5).join(', ')
}

const componentDecorator = (href, text, key): JSX.Element => (
  <a href={href} key={key}>
    {punycode.toASCII(text)}
  </a>
)

export function TermsheetTable ({ termsheetObject, readOnly, resetPosition }:
{ termsheetObject: any, readOnly?: boolean, resetPosition?: boolean }): JSX.Element {
  // Profile policy
  const [profile] = useProfileHook()
  const { accountType } = profile

  const isInvestor = ORGANIZATION_TYPES.INVESTOR === accountType

  const { getModalContent, setIsModal, setMaxWidth } = useContext(ModalContext)

  const bankerOrgsQuery = useGetBankerOrganizations()

  const organizationLogoQuery = bankerOrgsQuery.data?.find(org => org.id === termsheetObject.publishingAccountOrganizationId)

  const publishingBank = {
    name: termsheetObject.publishingBankName,
    role: termsheetObject.publishingBankRole,
    contact: termsheetObject.designatedBankerName?.name ?? termsheetObject.fullBankerName,
    email: termsheetObject.designatedBankerEmail,
    telephone: termsheetObject.designatedBankerPhone,
    underwritingSplit: termsheetObject.underwritingSplit
  }

  const jgcList = createSyndicateList(termsheetObject.otherSyndicateBanks, publishingBank.name)

  const handleUnderwritingSyndicateModal = (): void => {
    setMaxWidth('md')
    setIsModal(true)
    getModalContent(<UnderwritingSyndicateModal
      array={termsheetObject.otherSyndicateBanks}
      publishingBank={publishingBank} setIsModal={setIsModal} region={termsheetObject.region}
                    />)
  }

  const termsheetTableObject = {
    ...termsheetObject,
    offeringType: OFFERING_TYPES[termsheetObject.offeringType]
  }

  const publishBankLogo = usePublishingBankLogo(organizationLogoQuery, termsheetObject.publishingAccountOrganizationId)

  return (
    <TermsheetTableStyled logoUrl={publishBankLogo.logo ?? ''} resetPosition={resetPosition}>
      <TermsheetTableTitleStyled>
        <h2>Offering details</h2>
        <div>{(!isInvestor && readOnly !== true) && <EditModeButton data-qa='ViewTermsheet-EditButton' />}</div>
      </TermsheetTableTitleStyled>
      {typeof termsheetObject.headerDisclaimerText === 'string' &&
       termsheetObject.headerDisclaimerText.length > 0 &&
       (
         <Linkify
           {...componentDecorator}
           options={linkifyDefaults}
         >
           <TermsheetTableHeaderStyled>{termsheetObject.headerDisclaimerText}</TermsheetTableHeaderStyled>
         </Linkify>
       )}
      <GenerateTermsheetTableRows
        jointGlobalCoordinatorArray={jgcList}
        termsheetObject={termsheetTableObject}
        offeringTypeAbbreviation={termsheetObject.offeringType}
        handleUnderwritingSyndicateModal={handleUnderwritingSyndicateModal}
      />
    </TermsheetTableStyled>
  )
}

TermsheetTable.propTypes = {
  termsheetObject: PropTypes.object
}
