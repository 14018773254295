import React, { ReactNode } from 'react'

import styled from '@emotion/styled'
import { formatDateString, isDateInThePast } from '../../../../utils'

import { TBA } from '../../../../constants'
import { removeDoubleStringify } from '../../../../utils/removeDoubleStringify'

import { EditModeButton } from '../../../Buttons/TopOfPageButtons/EditModeButton'
import { useProfileHook } from '../../../../contexts/user-auth-context'
import { ORGANIZATION_TYPES } from '../../../../constants/organizations'

export function TimetableRender ({ termsheetTimetableObject, termsheetObject, readOnly }: {
  termsheetTimetableObject: any[]
  termsheetObject: {}
  readOnly?: boolean
}): JSX.Element {
  const [profile] = useProfileHook()
  const { accountType } = profile

  const isInvestor = ORGANIZATION_TYPES.INVESTOR === accountType

  const isAddNewDeal = window.location.pathname.includes('add-new')

  const isDate = (data: unknown): boolean => typeof data === 'string' && data !== TBA && data !== ''

  const filteredTimetable = termsheetTimetableObject.filter((event) => isDate(termsheetObject[event.name]))

  const icon = (date): ReactNode => (date === TBA || date === '' || !isDateInThePast(date) ? <CircleFutureStyled /> : <CircleLastPastEventStyled />)

  return (
    <TermsheetTimetableStyled isAddNewDeal={isAddNewDeal}>
      <div className='timetable-heading'>
        <h2>Timetable</h2>
        {(!isInvestor && readOnly !== true) && <EditModeButton />}
      </div>
      {filteredTimetable.length > 0
        ? filteredTimetable.map((event, index) => (
          <TimetableRowStyled key={index} isChanged={event.isChanged}>
            <>
              <div className='timetable-row-label'>
                <span>{Boolean((termsheetObject)[event.name]) && icon(removeDoubleStringify(termsheetObject[event.name]))}</span>
                <p>{event.label}</p>
              </div>
              <p>{formatDateString(removeDoubleStringify(termsheetObject[event.name]))}</p>
            </>
          </TimetableRowStyled>
        ))
        : (
            'No dates available'
          )}
    </TermsheetTimetableStyled>
  )
}

const CircleLastPastEventStyled = styled.div`
  height: 10px;
  width: 10px;
  background: #6cb7a6;
  border-radius: 20px;
`

const CircleFutureStyled = styled.div`
  height: 10px;
  width: 10px;
  background: grey;
  border-radius: 20px;
`

const TermsheetTimetableStyled = styled.div<{ isAddNewDeal: boolean }>`
  box-shadow: 3px 3px 3px 0 #ececec;
  background: ${({ theme }) => theme.white};
  max-width: ${({ isAddNewDeal }) => isAddNewDeal && '100%'};
  min-width: 280px;
  grid-column: 3;
  grid-row: 2/3;
  padding: 0 10px;
  border: ${({ theme }) => `1px solid ${theme.gray_bg as string}`};
  border-radius: 3px;
  margin-bottom: 20px;
  padding-bottom: 10px;
  .timetable-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: ${({ theme }) => theme.borderBottom};

    h2 {
      margin: 10px 0;
      font-size: 16px;
    }
    margin-bottom: 9px;
  }
`

const TimetableRowStyled = styled.div<{ isChanged: boolean }>`
  display: flex;
  justify-content: space-between;
  background: ${({ isChanged, theme }) => isChanged && theme.yellow_100};
  margin: 5px 0;

  p {
    font-size: 12px;
    margin: 1px 0;
  }
  span {
    color: #6cb7a6;
    margin: 0;
    svg {
      font-size: 18px;
    }
  }
  .timetable-row-label {
    display: flex;
    align-items: center;

    p {
      margin-left: 30px;
    }
  }
`
