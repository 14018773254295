import React, { useEffect } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import styled from '@emotion/styled'
import { TextField } from '@material-ui/core'
import { FormLabel } from '../../../../components/FormLabel'
import { getErrorLine } from '../../../../components/ErrorLine/getErrorLine'

export interface AutocompleteMultiSelectHookedProps {
  name?: string
  label?: string
  optionsArray: any[]
  errors: any
  setValue: any
  register: any
  watch: any
  defaultValue: string[] | string | null
}

export const AutocompleteMultiSelectHooked = ({
  name, label, optionsArray, errors, setValue, register, defaultValue
}: AutocompleteMultiSelectHookedProps): JSX.Element => {
  useEffect(() => {
    register({ name })
  }, [register, name])
  const newDefaultValue: string[] = typeof defaultValue === 'string' ? [defaultValue] : (defaultValue ?? [])

  useEffect(() => {
    setValue(name, newDefaultValue, { shouldValidate: true })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AutocompleteStyled>
      <Autocomplete
        multiple
        options={optionsArray}
        defaultValue={newDefaultValue}
        onChange={(event, newValue) => {
          setValue(name, newValue, { shouldValidate: true })
        }}
        renderInput={(params) => (
          <>
            <FormLabel>{label}</FormLabel>
            <TextField {...params} variant='outlined' />
          </>
        )}
      />
      {getErrorLine(errors, name)}
    </AutocompleteStyled>
  )
}

const AutocompleteStyled = styled.div`
  .MuiAutocomplete-inputRoot {
    padding: 1px 7px;
  }
  `
