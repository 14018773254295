import React, { ReactElement } from 'react'

const ModuleStateContext = React.createContext<any>(undefined)
const ModuleDispatchContext = React.createContext<any>(undefined)

export const SET_TERMSHEET = 'REFRESH_TERMSHEET'

function userReducer (state, action): any {
  const { type, payload } = action

  switch (type) {
    case SET_TERMSHEET: {
      return { ...state, termsheet: { ...payload.termsheet } }
    }
    default: {
      throw new Error(`Unhandled action type: ${type as string}`)
    }
  }
}

function ModuleProvider ({ children }: { children: ReactElement }): JSX.Element {
  const [state, dispatch] = React.useReducer(userReducer, {
    termsheet: {
      otherSyndicateBanks: []
    }
  })

  return (
    <ModuleStateContext.Provider value={state}>
      <ModuleDispatchContext.Provider value={dispatch}>{children}</ModuleDispatchContext.Provider>
    </ModuleStateContext.Provider>
  )
}

function useModuleDispatch (): any {
  const context = React.useContext<any>(ModuleDispatchContext)
  if (context === undefined) {
    throw new Error('useModuleDispatch must be used within a CountProvider')
  }
  return context
}

export { ModuleProvider, useModuleDispatch }
