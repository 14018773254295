/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React from 'react'
import PropTypes from 'prop-types'

import { TooltipReusable } from '../../../../TooltipReusable'
import styled from '@emotion/styled'

export const SellingShareholdersRow = ({ termsheetObject, isChanged }): JSX.Element => {
  const { sellingShareholdersLegal, sellingShareholders } = termsheetObject
  return (
    <div>
      {typeof sellingShareholders === 'string'
        ? (
          <SellingShareholdersStyled isChanged={isChanged} className='row'>
            <div className='row-header row-header-shareholders'>
              <p>Selling shareholder(s)</p>
              <TooltipReusable
                helperText={sellingShareholdersLegal && sellingShareholdersLegal.length > 1 ? sellingShareholdersLegal : 'No information available'}
              />
            </div>
            <p>{sellingShareholders}</p>
          </SellingShareholdersStyled>
          )
        : (
            sellingShareholders.map((item, index: number) =>
              item.shareholder && item.shareholder.length > 0
                ? (
                  <SellingShareholdersObjectStyled key={index}>
                    <SellingShareholdersStyled isChanged={isChanged} className='row'>
                      <div className='row-header row-header-shareholders'>
                        Selling shareholder {index + 1}
                        <TooltipReusable
                          helperText={item.shareholderLegal && item.shareholderLegal.length > 1 ? item.shareholderLegal : 'No information available'}
                        />
                      </div>
                      <p>{item.shareholder}</p>
                    </SellingShareholdersStyled>
                    <div className='row'>
                      <p className='row-header row-header-shareholders'>Lock up (days)</p>
                      <p>{item.lockup}</p>
                    </div>
                  </SellingShareholdersObjectStyled>
                  )
                : null
            )
          )}
    </div>
  )
}

SellingShareholdersRow.propTypes = {
  termsheetObject: PropTypes.object.isRequired,
  isChanged: PropTypes.bool
}

interface SellingShareholdersStyledProps {
  isChanged: boolean
}

const SellingShareholdersStyled = styled.div<SellingShareholdersStyledProps>`
  background: ${({ theme, isChanged }) => isChanged && theme.yellow_100};

  .row-header-shareholders {
    min-width: 150px;
  }

  p:last-of-type {
    text-align: end;

    p {
      font-weight: 400;
    }
  }

  border-bottom: 1px solid #d8d8d8;
`

const SellingShareholdersObjectStyled = styled.div`
  border-bottom: 1px solid #d8d8d8;

  & > div {
    display: flex;
    justify-content: space-between;
  }

  margin: 5px 0;
  padding: 5px 0;
  border-radius: 3px;

  .row {
    border-bottom: none;
  }

  .row-header-shareholders {
    min-width: 150px;
  }
`
