/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React from 'react'
import { Button } from '@material-ui/core'
import styled from '@emotion/styled'

interface TransparentDashboardFilterButtonProps {
  label: string
  handleClick?: any
  isBlue?: boolean
}

export function TransparentDashboardFilterButton ({ label, handleClick, isBlue }: TransparentDashboardFilterButtonProps): JSX.Element {
  return (
    <ButtonStyled isBlue={isBlue}>
      <Button onClick={handleClick}>{label}</Button>
    </ButtonStyled>
  )
}

interface ButtonStyledProps {
  isBlue?: boolean
}

const ButtonStyled = styled.div<ButtonStyledProps>`
  .MuiButton-label {
    color: ${({ isBlue, theme }) => (isBlue ? theme.blue_900 : theme.orange)};
    text-transform: none;
    font-size: 12px;
    font-weight: 600;
  }
`
