import React from 'react'
import PropTypes from 'prop-types'

import styled from '@emotion/styled'
import startCase from 'lodash.startcase'
import toLower from 'lodash.tolower'
import upperFirst from 'lodash.upperfirst'
import { pipe } from 'ramda'

const f: (message: string) => string = pipe(startCase, toLower, upperFirst)

export const getErrorLine = (errors?: Record<string, Record<string, string>>, name?: string, label?: string): JSX.Element | null => {
  if (errors === undefined || name === undefined || errors[name] === undefined) return null
  // This is a terrible code and we need to rebuild this form asap

  return (
    <ErrorP>{(label !== undefined && label.length > 0)
      ? `${label} is not allowed to be empty`
      : (f(errors[name].message) === 'Distribution into saudi arabia must be a boolean'
          ? 'Distribution is not allowed to be empty'
          : f(errors[name].message))}
    </ErrorP>
  )
}

getErrorLine.propTypes = {
  errors: PropTypes.object,
  name: PropTypes.string.isRequired
}

const ErrorP = styled.p`
  margin-top: 3px;
  color: red;
`
