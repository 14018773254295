import styled from '@emotion/styled'
import { createTheme, makeStyles } from '@material-ui/core'
import { lightThemeColors } from '../../../theme'

interface DropdownContainerProps {
  width: string
}

export const DropdownContainer = styled.div<DropdownContainerProps>`
  width: ${({ width }) => width};
`

export const dropdownTheme = createTheme({
  overrides: {
    MuiInputBase: {
      root: {
        height: '100%'
      }
    },
    MuiSelect: {
      select: {
        padding: '9px 0px 10px 12px',
        '&:focus': {
          backgroundColor: 'none'
        }
      }
    }
  }
})

export const FormHeaderStyled = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-top: 2px;
    font-size: 1rem;
  }
`

export const useDropdownStyles = makeStyles(() => ({
  formControl: {
    width: '100%',
    borderRadius: '4px'
  },
  select: {
    border: `1px solid ${lightThemeColors.grey_350}`,
    borderRadius: '4px'
  },
  selectError: {
    border: '1px solid red'
  }
}))

export const AsteriskStyled = styled.span`
  color: red;
  margin-left: 3px;
  font-size: 15px;
`
