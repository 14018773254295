/**
 * IF YOU CHANGE THIS FILE,
 * YOU MUST ALSO UPDATE THE MATCHING FILE IN THE API REPO
 */
import {
  sellingShareholders,
  otherSyndicateBanks,
  offeringSharePriceRange,
  offeringSizeNrSharesPrimary,
  offeringSizeNrSharesSecondary,
  offeringCurrencyType,
  distribution,
  listingExchange,
  stockTickerCode,
  isinNumber,
  securityType,
  freeFloat,
  upsizeOption,
  greenshoe,
  commitments,
  issuerDomicile,
  headerDisclaimerText,
  disclaimerText,
  mifidTargetMarket,
  useOfProceeds,
  primarySecondaryTotal,
  offeringSizeValue,
  additionalInfo,
  otherSellingRestrictions,
  percentCapitalIncrease,
  offeringSizeNrShares,
  lockUpExpiryDays,
  finalOfferingSharePrice,
  finalOfferingSizeNrShares,
  finalOfferingSizeNrSharesPrimary,
  finalOfferingSizeNrSharesSecondary,
  finalOfferingSizeValue,
  otherSecurityType,
  greenshoePrimaryShares,
  greenshoeSecondaryShares,
  stabAgent,
  distributionIntoSaudiArabia
} from '../../formTypes/inputFields'
import {
  dealAnnouncementDate,
  researchPublicationDate,
  startOfBookbuildingDate,
  closeOfBookbuildingDate,
  allocationsReleasedDate,
  pricingDate,
  startOfTradingDate,
  settlementDate,
  expectedPricingDate,
  lastDayOfStabTrading
} from '../../formTypes/datePickers'

export const mainQuestionsFmo = [
  otherSyndicateBanks,
  sellingShareholders,
  lockUpExpiryDays,
  offeringSharePriceRange,
  finalOfferingSharePrice,
  offeringCurrencyType,
  offeringSizeNrShares,
  offeringSizeNrSharesPrimary,
  offeringSizeNrSharesSecondary,
  primarySecondaryTotal,
  finalOfferingSizeNrShares,
  finalOfferingSizeNrSharesPrimary,
  finalOfferingSizeNrSharesSecondary,
  offeringSizeValue,
  finalOfferingSizeValue,
  distribution,
  otherSellingRestrictions,
  listingExchange,
  distributionIntoSaudiArabia,
  stockTickerCode,
  isinNumber,
  headerDisclaimerText,
  disclaimerText
]

export const additionalQuestionsFmo = [
  greenshoePrimaryShares,
  greenshoeSecondaryShares,
  stabAgent,
  percentCapitalIncrease,
  securityType,
  otherSecurityType,
  useOfProceeds,
  upsizeOption,
  freeFloat,
  commitments,
  greenshoe,
  mifidTargetMarket,
  issuerDomicile,
  additionalInfo
]

export const timetableFmo = [
  dealAnnouncementDate,
  researchPublicationDate,
  startOfBookbuildingDate,
  closeOfBookbuildingDate,
  pricingDate,
  expectedPricingDate,
  allocationsReleasedDate,
  startOfTradingDate,
  settlementDate,
  lastDayOfStabTrading
]
