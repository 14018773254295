/**
 * IF YOU CHANGE THIS FILE,
 * YOU MUST ALSO UPDATE THE MATCHING FILE IN THE API REPO
 */
import {
  allocationsReleasedDate,
  closeOfBookbuildingDate,
  dealAnnouncementDate,
  expectedPricingDate,
  finalSettlementDate,
  pricingDate,
  roadshowScheduleTiming,
  settlementDate,
  startOfTradingDate
} from '../../formTypes/datePickers'
import {
  acquisitionApproval,
  additionalInfo,
  amountHeldInTrust,
  sector,
  businessDescription,
  commitments,
  cusipNumber,
  disclaimerText,
  distribution,
  finalOfferingSharePrice,
  finalOfferingSizeNrShares,
  finalOfferingSizeNrSharesPrimary,
  finalOfferingSizeNrSharesSecondary,
  finalOfferingSizeValue,
  focusTargetAcquisitionMarket,
  foundersPromoteSponsorPromote,
  groupCallDateTime,
  groupCallEntryCode,
  groupCallId,
  groupCallNr,
  groupCallPresentation,
  headerDisclaimerText,
  incorporation,
  industry,
  interestEarnedOnCash,
  isinNumber,
  keyIndividualsFounders,
  listingExchange,
  lockUpExpiryDays,
  offeringCurrencyType,
  offeringSharePriceRange,
  offeringSizeNrShares,
  offeringSizeNrSharesPrimary,
  offeringSizeNrSharesSecondary,
  offeringSizeValue,
  otherSellingRestrictions,
  otherSyndicateBanks,
  overallotment,
  riskCapital,
  sellingShareholders,
  shareLiquidationValue,
  spacLifetime,
  stockTickerCode,
  unitComposition,
  warrantTerms,
  distributionIntoSaudiArabia
} from '../../formTypes/inputFields'

export const mainQuestionsSpac = [
  sellingShareholders,
  lockUpExpiryDays,
  offeringSharePriceRange,
  finalOfferingSharePrice,
  offeringCurrencyType,
  offeringSizeNrShares,
  offeringSizeNrSharesPrimary,
  offeringSizeNrSharesSecondary,
  finalOfferingSizeNrShares,
  finalOfferingSizeNrSharesPrimary,
  finalOfferingSizeNrSharesSecondary,
  offeringSizeValue,
  finalOfferingSizeValue,
  otherSyndicateBanks,
  listingExchange,
  distributionIntoSaudiArabia,
  stockTickerCode,
  sector,
  businessDescription,
  unitComposition,
  keyIndividualsFounders,
  spacLifetime,
  distribution,
  otherSellingRestrictions,
  headerDisclaimerText,
  disclaimerText
]

export const additionalQuestionsSpac = [
  foundersPromoteSponsorPromote,
  riskCapital,
  warrantTerms,
  amountHeldInTrust,
  interestEarnedOnCash,
  shareLiquidationValue,
  focusTargetAcquisitionMarket,
  acquisitionApproval,
  overallotment,
  commitments,
  incorporation,
  industry,
  isinNumber,
  cusipNumber,
  groupCallDateTime,
  groupCallNr,
  groupCallId,
  groupCallEntryCode,
  groupCallPresentation,
  additionalInfo
]

export const timetableSpac = [
  dealAnnouncementDate,
  roadshowScheduleTiming,
  closeOfBookbuildingDate,
  pricingDate,
  allocationsReleasedDate,
  settlementDate,
  finalSettlementDate,
  startOfTradingDate,
  expectedPricingDate
]
