import React, { lazy, Suspense } from 'react'
import { Redirect, Route, Switch, withRouter } from 'react-router-dom'
import { FullPageSpinner } from 'components/LoadingSpinner'
import { AuthenticatedRoute } from 'routes/AuthenticatedRoute'
import { useDashboardPage } from 'routes/DashboardPage/components/useDashboardPage'
import { Retry } from 'routes/Retry'
import { UnauthenticatedRoute } from 'routes/UnauthenticatedRoute'
import { getFeatureFlagHideECMRelatedFeature } from 'routes/utils'
import { useStores } from 'store/rootStore'
import { observer } from 'mobx-react-lite'
import ConditionalRender from 'components/ConditionalRender/ConditionalRender'

/* eslint-disable @typescript-eslint/promise-function-async */
const DashboardPage = lazy(() => Retry(() => import('./DashboardPage')))
const DashboardPageV2 = lazy(() => Retry(() => import('./DashboardPageV2')))
const TermsheetPage = lazy(() => Retry(() => import('./TermsheetPage')))
const AdminPage = lazy(() => Retry(() => import('./AdminPage')))
const OrganisationAdminPage = lazy(() => Retry(() => import('./OrganisationAdminPage')))
const AddNewDealPage = lazy(() => Retry(() => import('./AddNewDealPage')))
const NotFoundPage = lazy(() => Retry(() => import('./NotFoundPage')))
const PartnerTermsheetPage = lazy(() => Retry(() => import('./PartnerTermsheetPage')))
const SignInPage = lazy(() => Retry(() => import('./Authentication/Pages/SignInPage')))
const ForgotPasswordPage = lazy(() => Retry(() => import('./Authentication/Pages/ForgotPasswordPage')))
const ForgotPasswordSubmitPage = lazy(() => Retry(() => import('./Authentication/Pages/ForgotPasswordSubmitPage')))
const ChangePasswordPage = lazy(() => Retry(() => import('./Authentication/Pages/ChangePasswordPage')))
const SignUpPage = lazy(() => Retry(() => import('./Authentication/Pages/SignUpPage')))
const ConfirmAccountPage = lazy(() => Retry(() => import('./Authentication/Pages/ConfirmAccountPage')))
const ConnectionsPage = lazy(() => Retry(() => import('routes/ConnectionsPage')))
const SellSideSalesSignUpPage = lazy(() => Retry(() => import('./Authentication/Pages/SellSideSalesSignUpPage')))
const LockUpExpiryPage = lazy(() => Retry(() => import('./LockUpExpiryPage')))
const PreferencesPage = lazy(() => Retry(() => import('./PreferencesPage')))
const SyndicateContactsPage = lazy(() => Retry(() => import('./SyndicateContactsPage')))
const TermsheetV2Page = lazy(() => Retry(() => import('./TermsheetV2Page')))

const RoutedApp: React.FC = observer(() => {
  const { filteredDashboardRegions } = useDashboardPage()
  const { commonStore, userStore } = useStores()
  const { isTaaS, isBanker, isInvestor } = userStore.getUserRoles()
  const FeatureFlagHideECMLoginScreen = getFeatureFlagHideECMRelatedFeature(
    commonStore.config?.envName,
    commonStore.config?.wsEndpoint
  )
  const isHideECMLoginScreenEnabled: boolean = commonStore.config?.envName
    ? FeatureFlagHideECMLoginScreen[commonStore.config?.envName]
    : false

  return (
    <Suspense fallback={<FullPageSpinner />}>
      <Switch>
        <AuthenticatedRoute exact path='/termsheetV2'>
          <TermsheetV2Page />
        </AuthenticatedRoute>

        <AuthenticatedRoute exact path='/dashboard'>
          <ConditionalRender
            isAccountTypePermitted
            component={DashboardPage}
            isFFPermitted
          />
        </AuthenticatedRoute>

        <AuthenticatedRoute exact path='/dashboardV2'>
          <DashboardPageV2 />
        </AuthenticatedRoute>

        <AuthenticatedRoute exact path='/'>
          <Redirect to='/dashboard' />
        </AuthenticatedRoute>

        {filteredDashboardRegions.map((region, i) => (
          <Route
            key={i} exact path={[`/${region}`, `/${region}/`]} render={() =>
              <Switch>
                <AuthenticatedRoute>
                  <DashboardPage />
                </AuthenticatedRoute>
                <UnauthenticatedRoute>
                  <SignInPage />
                </UnauthenticatedRoute>
              </Switch>}
          />
        ))}

        <AuthenticatedRoute exact path='/deals/add-new'>
          <ConditionalRender isAccountTypePermitted={isTaaS || isBanker} component={AddNewDealPage} isFFPermitted />
        </AuthenticatedRoute>
        <AuthenticatedRoute path='/termsheet/:dealId/:termsheetId?'>
          <TermsheetPage />
        </AuthenticatedRoute>
        <AuthenticatedRoute path='/deals/:dealId/termsheets/:termsheetId'>
          <TermsheetPage />
        </AuthenticatedRoute>
        <AuthenticatedRoute path='/termsheets/:termsheetId'>
          <TermsheetPage />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path='/admin'>
          <ConditionalRender isAccountTypePermitted={isTaaS} isFFPermitted component={AdminPage} />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path='/admin/organisation-settings'>
          <ConditionalRender isAccountTypePermitted={isTaaS} isFFPermitted component={OrganisationAdminPage} />
        </AuthenticatedRoute>

        <AuthenticatedRoute exact path='/admin/investor-connections'>
          <ConditionalRender isAccountTypePermitted={isBanker} component={ConnectionsPage} isFFPermitted />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path='/investor/lock-up-expiry'>
          <ConditionalRender isAccountTypePermitted={isInvestor} component={LockUpExpiryPage} isFFPermitted />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path='/investor/syndicate-contacts'>
          <ConditionalRender isAccountTypePermitted={isInvestor} component={SyndicateContactsPage} isFFPermitted />
        </AuthenticatedRoute>

        <AuthenticatedRoute exact path='/investor/banker-connections'>
          <ConditionalRender isAccountTypePermitted={isInvestor} component={ConnectionsPage} isFFPermitted />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path='/investor/preferences'>
          <ConditionalRender isAccountTypePermitted={isInvestor} component={PreferencesPage} isFFPermitted />
        </AuthenticatedRoute>

        <UnauthenticatedRoute exact path='/login'>
          {isHideECMLoginScreenEnabled
            ? <NotFoundPage />
            : <SignInPage />}
        </UnauthenticatedRoute>

        <UnauthenticatedRoute exact path='/sss-sign-up/:nameOfSalesTeam'>
          <SellSideSalesSignUpPage />
        </UnauthenticatedRoute>
        <UnauthenticatedRoute exact path='/forgot-password'>
          <ForgotPasswordPage />
        </UnauthenticatedRoute>
        <UnauthenticatedRoute exact path='/forgot-password-submit'>
          <ForgotPasswordSubmitPage />
        </UnauthenticatedRoute>
        <UnauthenticatedRoute exact path='/buy-side-sign-up'>
          <SignUpPage />
        </UnauthenticatedRoute>
        <UnauthenticatedRoute exact path='/confirm-account'>
          <ConfirmAccountPage />
        </UnauthenticatedRoute>

        <Route exact path='/partner-referral/view-termsheet'>
          <PartnerTermsheetPage />
        </Route>

        <AuthenticatedRoute exact path='/change-password'>
          <ChangePasswordPage />
        </AuthenticatedRoute>

        <Route exact path='/not-found'>
          <NotFoundPage />
        </Route>

        <UnauthenticatedRoute path='*'>
          <Redirect to='/login' />
        </UnauthenticatedRoute>

      </Switch>
    </Suspense>
  )
})

export default withRouter(RoutedApp)
