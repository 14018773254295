import { Actions, createStore, RecursiveActions } from 'easy-peasy'
import { enableMapSet } from 'immer'
import { deals, DealsStoreModel } from './deals'

enableMapSet()

export interface StoreModel {
  deals: DealsStoreModel
}

export type StoreActions = Actions<StoreModel>

export const store = createStore<StoreModel>({
  deals
})

export const getDealsActions = (): RecursiveActions<DealsStoreModel> => store.getActions().deals
export const getDealsState = (): any => store.getState().deals
