/**
 * IF YOU CHANGE THIS FILE,
 * YOU MUST ALSO UPDATE THE MATCHING FILE IN THE API REPO
 */
import {
  sellingShareholders,
  otherSyndicateBanks,
  offeringSharePriceRange,
  offeringSizeNrSharesPrimary,
  offeringSizeNrSharesSecondary,
  offeringCurrencyType,
  distribution,
  listingExchange,
  stockTickerCode,
  isinNumber,
  securityType,
  freeFloat,
  upsizeOption,
  greenshoe,
  commitments,
  issuerDomicile,
  offeringImpliedMarketValue,
  useOfProceeds,
  headerDisclaimerText,
  disclaimerText,
  sector,
  businessDescription,
  mifidTargetMarket,
  issuedShareCapital,
  primarySecondaryTotal,
  offeringSizeValue,
  additionalInfo,
  otherSellingRestrictions,
  percentCapitalIncrease,
  offeringSizeNrShares,
  lockUpExpiryDays,
  finalOfferingSizeNrShares,
  finalOfferingSizeNrSharesPrimary,
  finalOfferingSizeNrSharesSecondary,
  finalOfferingSizeValue,
  finalOfferingSharePrice,
  otherSecurityType,
  greenshoePrimaryShares,
  greenshoeSecondaryShares,
  stabAgent,
  minimumOrder,
  conditionsForCompletion,
  documentation,
  allocationCriteria,
  investorAcknowledgement,
  commissions,
  trancheStructure,
  anchorInvPortionRegs,
  employeeResPortion,
  fxRate,
  bidLotSize,
  totalOfferQibs,
  anchorInvPortion,
  netOfferQIBsExclAnch,
  nonInstPortion,
  retailPortion,
  minAnchorBid,
  maxAnchorBid,
  minBidSizeFPI,
  maxBidSizeFPI,
  catQibsApply,
  state,
  distributionIntoSaudiArabia
} from '../../formTypes/inputFields'

import {
  dealAnnouncementDate,
  researchPublicationDate,
  startOfBookbuildingDate,
  closeOfRetailOfferingDate,
  closeOfBookbuildingDate,
  allocationsReleasedDate,
  pricingDate,
  startOfTradingDate,
  settlementDate,
  expectedPricingDate,
  finalSettlementDate,
  lastDayOfStabTrading
} from '../../formTypes/datePickers'

export const mainQuestionsIpo = [
  sellingShareholders,
  lockUpExpiryDays,
  otherSyndicateBanks,
  state,
  offeringSharePriceRange,
  finalOfferingSharePrice,
  offeringImpliedMarketValue,
  offeringCurrencyType,
  offeringSizeNrShares,
  offeringSizeNrSharesPrimary,
  offeringSizeNrSharesSecondary,
  finalOfferingSizeNrShares,
  finalOfferingSizeNrSharesPrimary,
  finalOfferingSizeNrSharesSecondary,
  primarySecondaryTotal,
  offeringSizeValue,
  finalOfferingSizeValue,
  greenshoe,
  distribution,
  otherSellingRestrictions,
  listingExchange,
  distributionIntoSaudiArabia,
  stockTickerCode,
  isinNumber,
  headerDisclaimerText,
  disclaimerText
]

export const additionalQuestionsIpo = [
  greenshoePrimaryShares,
  greenshoeSecondaryShares,
  stabAgent,
  percentCapitalIncrease,
  issuerDomicile,
  sector,
  businessDescription,
  securityType,
  otherSecurityType,
  useOfProceeds,
  freeFloat,
  upsizeOption,
  commitments,
  mifidTargetMarket,
  issuedShareCapital,
  minimumOrder,
  conditionsForCompletion,
  documentation,
  allocationCriteria,
  investorAcknowledgement,
  commissions,
  additionalInfo
]

export const timetableIpo = [
  dealAnnouncementDate,
  researchPublicationDate,
  startOfBookbuildingDate,
  closeOfRetailOfferingDate,
  closeOfBookbuildingDate,
  pricingDate,
  expectedPricingDate,
  allocationsReleasedDate,
  startOfTradingDate,
  settlementDate,
  finalSettlementDate,
  lastDayOfStabTrading
]

export const additionalQuestionsIpoApac = [
  trancheStructure,
  employeeResPortion,
  fxRate,
  bidLotSize,
  totalOfferQibs,
  anchorInvPortion,
  netOfferQIBsExclAnch,
  nonInstPortion,
  retailPortion,
  minAnchorBid,
  maxAnchorBid,
  minBidSizeFPI,
  maxBidSizeFPI,
  anchorInvPortionRegs,
  catQibsApply
]
