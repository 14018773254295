import Joi from 'joi'

export const get2FormSchemaValidation = (offeringType): any => {
  return (
    offeringType as boolean &&
    mainQuestionsValidation[offeringType]
      .concat(timetableValdiation[offeringType].concat(additionalQuestionsValdiation[offeringType]).concat(additionTimetableValidation[offeringType]))
      .unknown()
  )
}

const stringValidationRequired = Joi.string()
const booleanValidationRequired = Joi.boolean()

const dateValidationRequired = Joi.string().invalid('TBA')

const stringValidation = Joi.string().allow('')

const stringArray = Joi.array().items()

const sellingShareholdersValidation = Joi.array().items(
  Joi.object({
    shareholder: stringValidation,
    shareholderLegal: stringValidation,
    lockup: stringValidation
  })
)
const otherSyndicateBanksValidation = Joi.array().items(
  Joi.object({
    name: stringValidation,
    role: stringValidation,
    id: stringValidation
  })
)

export const Step1FormSchema = Joi.object({
  issuerName: stringValidationRequired,
  offeringType: stringValidationRequired,
  region: stringValidationRequired,
  issuerLegalName: stringValidation,
  taasPublishAsBankerName: stringValidationRequired,
  publishingBankName: stringValidationRequired,
  publishingBankRole: stringValidation,
  researchfnLink: stringValidation,
  netroadshowLink: stringValidation,
  prospectusRegistrationLink: stringValidation,
  primarySecondaryString: stringValidation
}).unknown()

export const PriceDealSchema = Joi.object({
  offeringSizeNrSharesPrimary: stringValidationRequired,
  offeringSizeNrSharesSecondary: stringValidationRequired,
  offeringSizeNrShares: stringValidationRequired,
  offeringCurrencyType: stringValidationRequired,
  offeringSizeValue: stringValidationRequired,
  offeringSharePriceRange: stringValidationRequired,
  pricingDate: dateValidationRequired,
  settlementDate: dateValidationRequired
}).unknown()

const mainQuestionsValidationIpo = Joi.object({
  otherSyndicateBanks: otherSyndicateBanksValidation,
  sellingShareholders: sellingShareholdersValidation,
  offeringSharePriceRange: stringValidation,
  offeringImpliedMarketValue: stringValidation,
  offeringSizeNrSharesPrimary: stringValidation,
  offeringSizeNrSharesSecondary: stringValidation,
  offeringCurrencyType: stringValidation,
  greenshoe: stringValidation,
  distribution: stringValidationRequired,
  listingExchange: stringArray,
  stockTickerCode: stringValidation,
  isinNumber: stringValidation,
  headerDisclaimerText: stringValidation,
  disclaimerText: stringValidation,
  distributionIntoSaudiArabia: booleanValidationRequired
})

const additionalQuestionsValidationIpo = Joi.object({
  issuerDomicile: stringValidation,
  sector: stringValidation,
  businessDescription: stringValidation,
  securityType: stringValidation,
  useOfProceeds: stringValidation,
  freeFloat: stringValidation,
  upsizeOption: stringValidation,
  commitments: stringValidation,
  mifidTargetMarket: stringValidation,
  issuedShareCapital: stringValidation
})

const timetableValidationIpo = Joi.object({
  dealAnnouncementDate: stringValidation,
  researchPublicationDate: stringValidation,
  startOfBookbuildingDate: stringValidation,
  closeOfBookbuildingDate: stringValidation,
  allocationsReleasedDate: stringValidation,
  startOfTradingDate: stringValidation,
  settlementDate: stringValidation
})

const additionalTimetableValidoationIpo = Joi.object({
  pricingDate: stringValidation
})

const mainQuestionsValidationAbo = Joi.object({
  otherSyndicateBanks: otherSyndicateBanksValidation,
  offeringSizeNrSharesPrimary: stringValidation,
  offeringSizeNrSharesSecondary: stringValidation,
  offeringCurrencyType: stringValidation,
  distribution: stringValidationRequired,
  lockUpExpiryDays: stringValidation,
  listingExchange: stringArray,
  stockTickerCode: stringValidation,
  isinNumber: stringValidation,
  headerDisclaimerText: stringValidation,
  disclaimerText: stringValidation,
  distributionIntoSaudiArabia: booleanValidationRequired
})

const additionalQuestionsValidationAbo = Joi.object({
  issuerDomicile: stringValidation,
  sector: stringValidation,
  businessDescription: stringValidation,
  securityType: stringValidation,
  useOfProceeds: stringValidation,
  sellingShareholders: sellingShareholdersValidation,
  freeFloat: stringValidation,
  upsizeOption: stringValidation,
  offeringSharePriceRange: stringValidation,
  commitments: stringValidation,
  greenshoe: stringValidation,
  mifidTargetMarket: stringValidation,
  issuedShareCapital: stringValidation
})

const timetableValidationAbo = Joi.object({
  dealAnnouncementDate: stringValidation,
  startOfBookbuildingDate: stringValidation,
  closeOfBookbuildingDate: stringValidation,
  allocationsReleasedDate: stringValidation,
  startOfTradingDate: stringValidation,
  settlementDate: stringValidation
})

const additionalTimetableValidoationAbo = Joi.object({
  pricingDate: stringValidation
})

const mainQuestionsValidationFmo = Joi.object({
  otherSyndicateBanks: otherSyndicateBanksValidation,
  offeringSharePriceRange: stringValidation,
  offeringSizeNrShares: stringValidation,
  offeringCurrencyType: stringValidation,
  distribution: stringValidationRequired,
  lockUpExpiryDays: stringValidation,
  listingExchange: stringArray,
  stockTickerCode: stringValidation,
  isinNumber: stringValidation,
  headerDisclaimerText: stringValidation,
  disclaimerText: stringValidation,
  distributionIntoSaudiArabia: booleanValidationRequired
})

const additionalQuestionsValidationFmo = Joi.object({
  securityType: stringValidation,
  useOfProceeds: stringValidation,
  sellingShareholders: sellingShareholdersValidation,
  upsizeOption: stringValidation,
  freeFloat: stringValidation,
  commitments: stringValidation,
  greenshoe: stringValidation,
  mifidTargetMarket: stringValidation,
  issuerDomicile: stringValidation
})

const timetableValidationFmo = Joi.object({
  dealAnnouncementDate: stringValidation,
  startOfBookbuildingDate: stringValidation,
  closeOfBookbuildingDate: stringValidation,
  startOfTradingDate: stringValidation,
  settlementDate: stringValidation
})

const additionalTimetableValidoationFmo = Joi.object({
  researchPublicationDate: stringValidation,
  pricingDate: stringValidation,
  allocationsReleasedDate: stringValidation
})

const mainQuestionsValidationRi = Joi.object({
  otherSyndicateBanks: otherSyndicateBanksValidation,
  offeringSizeNrSharesPrimary: stringValidation,
  offeringSizeNrSharesSecondary: stringValidation,
  offeringCurrencyType: stringValidation,
  useOfProceeds: stringValidation,
  subscriptionPrice: stringValidation,
  subscriptionRatio: stringValidation,
  discountToTERP: stringValidation,
  distribution: stringValidationRequired,
  mifidTargetMarket: stringValidation,
  lockUpExpiryDays: stringValidation,
  listingExchange: stringArray,
  stockTickerCode: stringValidation,
  isinNumber: stringValidation,
  headerDisclaimerText: stringValidation,
  disclaimerText: stringValidation,
  distributionIntoSaudiArabia: booleanValidationRequired
})

const additionalQuestionsValidationRi = Joi.object({
  issuerDomicile: stringValidation,
  securityType: stringValidation,
  TERP: stringValidation,
  discountToClose: stringValidation,
  commitments: stringValidation,
  issuedShareCapital: stringValidation
})

const timetableValidationRi = Joi.object({
  dealAnnouncementDate: stringValidation,
  recordDate: stringValidation,
  lastDayOfCRTradingDate: stringValidation,
  exDate: stringValidation,
  startOfRightsTradingDate: stringValidation,
  startOfSubscriptionPeriodDate: stringValidation,
  endOfRightsTradingDate: stringValidation,
  endOfSubscriptionPeriodDate: stringValidation,
  startOfTradingNewSharesDate: stringValidation,
  announcementOfResultsDate: stringValidation,
  settlementOfNewSharesDate: stringValidation
})

const additionalTimetableValidoationRi = Joi.object({
  egmDate: stringValidation,
  prospectusPublicationDate: stringValidation,
  rumpPlacementDate: stringValidation
})

const mainQuestionsValidationEql = Joi.object({
  otherSyndicateBanks: otherSyndicateBanksValidation,
  securityTypeEQL: stringValidation,
  offeringCurrencyType: stringValidation,
  offeringMaturity: stringValidation,
  issuePrice: stringValidation,
  redemptionPrice: stringValidation,
  couponRange: stringValidation,
  yieldToMaturity: stringValidation,
  conversionPremium: stringValidation,
  useOfProceeds: stringValidation,
  mifidTargetMarket: stringValidation,
  distribution: stringValidationRequired,
  lockUpExpiryDays: stringValidation,
  listingExchange: stringArray,
  headerDisclaimerText: stringValidation,
  disclaimerText: stringValidation,
  distributionIntoSaudiArabia: booleanValidationRequired
})

const additionalQuestionsValidationEql = Joi.object({
  issuerRating: stringValidation,
  issuerDomicile: stringValidation,
  underlyingSecurity: stringValidation,
  upsizeOption: stringValidation,
  referenceSharePrice: stringValidation,
  issuerCall: stringValidation,
  investorPut: stringValidation,
  antiDilutionProtection: stringValidation,
  settlementUponConversion: stringValidation,
  changeOfControl: stringValidation,
  commitments: stringValidation
})

const timetableValidationEql = Joi.object({
  dealAnnouncementDate: stringValidation,
  startOfBookbuildingDate: stringValidation,
  closeOfBookbuildingDate: stringValidation,
  settlementDate: stringValidation,
  pricingDate: stringValidation,
  maturityDate: stringValidation
})

const additionalTimetableValidoationEql = Joi.object({
  pricingDate: stringValidation,
  maturityDate: stringValidation
})

const additionalTimetableValidoationOth = Joi.object({
  pricingDate: stringValidation
})

const mainQuestionsValidationOth = Joi.object({
  otherSyndicateBanks: otherSyndicateBanksValidation,
  sellingShareholders: sellingShareholdersValidation,
  offeringSharePriceRange: stringValidation,
  offeringImpliedMarketValue: stringValidation,
  offeringSizeNrSharesPrimary: stringValidation,
  offeringSizeNrSharesSecondary: stringValidation,
  offeringCurrencyType: stringValidation,
  greenshoe: stringValidation,
  distribution: stringValidationRequired,
  listingExchange: stringArray,
  stockTickerCode: stringValidation,
  isinNumber: stringValidation,
  headerDisclaimerText: stringValidation,
  disclaimerText: stringValidation,
  distributionIntoSaudiArabia: booleanValidationRequired
})

const mainQuestionsValidationSpac = Joi.object({
  sellingShareholders: sellingShareholdersValidation,
  otherSyndicateBanks: otherSyndicateBanksValidation,
  offeringSizeNrSharesPrimary: stringValidation,
  finalOfferingSizeNrSharesPrimary: stringValidation,
  offeringSizeNrSharesSecondary: stringValidation,
  finalOfferingSizeNrSharesSecondary: stringValidation,
  offeringSizeNrShares: stringValidation,
  finalOfferingSizeNrShares: stringValidation,
  listingExchange: stringArray,
  stockTickerCode: stringValidation,
  sector: stringValidation,
  businessDescription: stringValidation,
  offeringPrice: stringValidation,
  finalOfferingSharePrice: stringValidation,
  unitComposition: stringValidation,
  keyIndividualsFounders: stringValidation,
  spacLifetime: stringValidation,
  distribution: stringValidationRequired,
  otherSellingRestrictions: stringValidation,
  headerDisclaimerText: stringValidation,
  disclaimerText: stringValidation,
  distributionIntoSaudiArabia: booleanValidationRequired
})

const additionalQuestionsValidationOth = Joi.object({
  issuerDomicile: stringValidation,
  sector: stringValidation,
  businessDescription: stringValidation,
  securityType: stringValidation,
  useOfProceeds: stringValidation,
  freeFloat: stringValidation,
  upsizeOption: stringValidation,
  commitments: stringValidation,
  mifidTargetMarket: stringValidation,
  issuedShareCapital: stringValidation
})

const timetableValidationOth = Joi.object({
  dealAnnouncementDate: stringValidation,
  researchPublicationDate: stringValidation,
  startOfBookbuildingDate: stringValidation,
  closeOfBookbuildingDate: stringValidation,
  allocationsReleasedDate: stringValidation,
  startOfTradingDate: stringValidation,
  settlementDate: stringValidation
})

const additionalQuestionsValidationSpac = Joi.object({
  foundersPromoteSponsorPromote: stringValidation,
  riskCapital: stringValidation,
  warrantTerms: stringValidation,
  amountHeldInTrust: stringValidation,
  interestEarnedOnCash: stringValidation,
  shareLiquidationValue: stringValidation,
  focusTargetAcquisitionMarket: stringValidation,
  acquisitionApproval: stringValidation,
  overallotment: stringValidation,
  lockUpExpiryDays: stringValidation,
  commitments: stringValidation,
  incorporation: stringValidation,
  industry: stringValidation,
  isinNumber: stringValidation,
  cusipNumber: stringValidation,
  groupCallDateTime: stringValidation,
  groupCallNr: stringValidation,
  groupCallId: stringValidation,
  groupCallEntryCode: stringValidation,
  groupCallPresentation: stringValidation
})

const timetableValidationSpac = Joi.object({
  dealAnnouncementDate: stringValidation,
  roadshowScheduleTiming: stringValidation,
  closeOfBookbuildingDate: stringValidation,
  allocationsReleasedDate: stringValidation,
  settlementDate: stringValidation,
  startOfTradingDate: stringValidation,
  expectedPricingDate: stringValidation
})

const additionalTimetableValidoationSpac = Joi.object({})

const mainQuestionsValidation = {
  IPO: mainQuestionsValidationIpo,
  ABO: mainQuestionsValidationAbo,
  FMO: mainQuestionsValidationFmo,
  RI: mainQuestionsValidationRi,
  EQL: mainQuestionsValidationEql,
  OTH: mainQuestionsValidationOth,
  SPAC: mainQuestionsValidationSpac
}

const additionalQuestionsValdiation = {
  IPO: additionalQuestionsValidationIpo,
  ABO: additionalQuestionsValidationAbo,
  FMO: additionalQuestionsValidationFmo,
  RI: additionalQuestionsValidationRi,
  EQL: additionalQuestionsValidationEql,
  OTH: additionalQuestionsValidationOth,
  SPAC: additionalQuestionsValidationSpac
}

const timetableValdiation = {
  IPO: timetableValidationIpo,
  ABO: timetableValidationAbo,
  FMO: timetableValidationFmo,
  RI: timetableValidationRi,
  EQL: timetableValidationEql,
  OTH: timetableValidationOth,
  SPAC: timetableValidationSpac
}

const additionTimetableValidation = {
  IPO: additionalTimetableValidoationIpo,
  ABO: additionalTimetableValidoationAbo,
  FMO: additionalTimetableValidoationFmo,
  RI: additionalTimetableValidoationRi,
  EQL: additionalTimetableValidoationEql,
  OTH: additionalTimetableValidoationOth,
  SPAC: additionalTimetableValidoationSpac
}
