import { CACHED_QUERY_PROPS } from 'constants/queries'
import { useQuery, UseQueryResult } from 'react-query'
import { getStockExchanges, StockExchange } from 'services/stock-exchanges'
import { useStores } from 'store/rootStore'

export function useGetStockExchanges (): UseQueryResult<StockExchange[]> {
  const { commonStore } = useStores()
  return useQuery<StockExchange[]>('stock-exchanges', getStockExchanges, {
    ...CACHED_QUERY_PROPS,
    staleTime: 30 * 60 * 1000,
    onSuccess: (data: StockExchange[]) => {
      commonStore.stockExchanges = data
    }
  })
}
