import { joiResolver } from '@hookform/resolvers'
import { PriceDealSchema } from 'containers/DealForm/formValidation'
import { useDealFormContext } from 'containers/DealForm/context'
import { useForm } from 'react-hook-form'
import { useSubmitHandler } from 'containers/DealForm/Step2Form/useSubmitHandler'
import { mergeDeepRight } from 'ramda'
import { useTermsheetModalContext } from 'contexts/modal-context/TermsheetModals/context'
import { DealContext } from 'contexts/deals-context'
import { useContext } from 'react'

export function useWithdrawModalForm (): { formMethods: any, onSubmit: any } {
  const { formValues, updateFormValues } = useDealFormContext()
  const { handleWithdrawDeal } = useSubmitHandler()
  const { setIsOpen, setModalSelector, setSubmitHandler } = useTermsheetModalContext()
  const { setIsPriceDealDisabled }: { setIsPriceDealDisabled: (a: any) => boolean } = useContext(DealContext)
  const formMethods = useForm({
    defaultValues: formValues,
    resolver: joiResolver(PriceDealSchema)
  })

  const onSubmit = (data: any): void => {
    setIsOpen(true)
    setModalSelector('confirmWithdraw')
    const newVals = mergeDeepRight(formValues, {
      ...data,
      finalPriceLocalCcy: '0',
      finalOfferingSize: '0'
    })
    updateFormValues(newVals)
    setSubmitHandler(() => (data: any, shouldSendFeedNotifications: boolean, shouldSendEmailNotifications: boolean) => {
      handleWithdrawDeal(data, shouldSendFeedNotifications, shouldSendEmailNotifications)
      setIsPriceDealDisabled(true)
    })
  }

  return { formMethods, onSubmit }
}
