import './LoadingIndicator.css'
import React from 'react'
import clsx from 'clsx'

interface LoadingIconProps {
  className?: string
}

export function LoadingIcon ({ className }: LoadingIconProps): JSX.Element {
  if (className === undefined) className = 'h-12 w-12'
  const classes = clsx('LoadingIcon ease-linear rounded-full border-4 border-t-4 border-gray-200', className)
  return (
    <>
      <div className={classes} />
      <div hidden>loading</div>
    </>
  )
}

interface LoadingScreenProps {
  subtitle?: string
}

export function LoadingScreen ({ subtitle }: LoadingScreenProps): JSX.Element {
  return (
    <div className='absolute top-0 left-0 right-0 bottom-0 z-50 overflow-hidden opacity-75 flex flex-col items-center justify-center'>
      <LoadingIcon />
      <h2 className='mt-2 text-center text-xl font-semibold'>Loading</h2>
      {subtitle !== undefined
        ? (
          <p className='w-1/3 text-center text-white'>{subtitle}</p>
          )
        : null}
    </div>
  )
}
