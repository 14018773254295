import { Auth } from '@aws-amplify/auth'
import { useProfileDispatch, USER_REDUCER_TYPES } from 'contexts/user-auth-context'
import qs from 'query-string'
import { cond, T } from 'ramda'
import { useCallback, useEffect } from 'react'
import { useLocation } from 'react-router'
import { useAlert } from 'ui'
import { AlertData } from 'ui/Alert/types'
import { sendValidationToken } from 'routes/Authentication/Pages/SignInPage/api'

interface Return {
  onLogin: ({ email, password }) => Promise<void>
  alert: AlertData
}

export default function useSignInPage (): Return {
  const [alert, clearAlert, , setErrorAlert, setWarningAlert, setSuccessAlert] = useAlert()
  const dispatch = useProfileDispatch()

  const afterLogin = useCallback(() => {
    dispatch({
      type: USER_REDUCER_TYPES.SET_HAS_AUTHENTICATED,
      payload: {
        hasAuthenticated: true
      }
    })
  }, [dispatch])

  const onLogin = async ({ email, password }): Promise<void> => {
    const resendSignUp = async (): Promise<void> => {
      await Auth.resendSignUp(email.toLowerCase())
      setWarningAlert({
        title: 'Email address not verified',
        description: 'We have sent you a new email, click the link in it to verify.'
      })
    }
    const getMessageForError = cond([
      [(error) => error.code === 'UserNotConfirmedException',
        resendSignUp],
      [(error) => error.message === 'User is disabled.',
        () =>
          setWarningAlert({
            title: 'Your account requires validation',
            description:
            `We have sent you an email with a link to validate your account. 
             Please click this link to resume access to your dashboard.`
          }
          )],
      [T,
        (error) => {
          setErrorAlert({
            title: 'Could not sign in',
            description: error?.message
          })
          console.error(error)
        }
      ]
    ])
    clearAlert()
    try {
      await Auth.signIn(email.toLowerCase(), password)
      afterLogin()
    } catch (error: any) {
      await getMessageForError(error)
    }
  }

  const { token: queryStringToken } = qs.parse(useLocation().search ?? '')

  useEffect(() => {
    const postToken = async (): Promise<void> => {
      try {
        await sendValidationToken(queryStringToken as string)
        setSuccessAlert({
          title: 'Validation successful',
          description: 'Thank you for validating your account. Log in to access your dashboard.'
        })
      } catch (e) {
        console.error(e)
        setErrorAlert({
          title: 'Error',
          description: 'We could not validate your account'
        })
      }
    }
    if (queryStringToken != null) {
      void postToken()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryStringToken])

  return (
    {
      onLogin,
      alert
    }
  )
}
