/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

export function TextBox ({ text, placeholder }): JSX.Element {
  return <TextBoxStyled>{text || placeholder}</TextBoxStyled>
}

TextBox.propTypes = {
  text: PropTypes.string,
  placeholder: PropTypes.string
}

TextBox.defaultProps = {
  text: '',
  placeholder: ''
}

const TextBoxStyled = styled.div`
  display: flex;
  align-items: center;
  min-height: 41px;
  padding: 13px 17px;
`
