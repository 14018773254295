/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { createContext, useContext } from 'react'

export const TermsheetContext = createContext(undefined)

export const useTermsheetContext = (): any => {
  const context = useContext<any>(TermsheetContext)

  if (!context) {
    throw new Error('useTermsheetContext must be inside TermsheetProvider')
  }

  return context
}
