import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

export const BankSyndicatesRow = ({ jointGlobalCoordinatorArray, handleUnderwritingSyndicateModal, isChanged }): JSX.Element => {
  return (
    <UnderwritingSyndicateStyled isChanged={isChanged} className='row'>
      <div>
        <p className='row-header'>Underwriting syndicate</p>
      </div>
      <div className='jgcs'>
        <div>
          <ul className='jgc-list'>
            {jointGlobalCoordinatorArray.split(',').map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
          <span className='jgc-expand-all' onClick={handleUnderwritingSyndicateModal}>
            Show all
          </span>
        </div>
      </div>
    </UnderwritingSyndicateStyled>
  )
}

BankSyndicatesRow.propTypes = {
  jointGlobalCoordinatorArray: PropTypes.string.isRequired,
  handleUnderwritingSyndicateModal: PropTypes.func.isRequired,
  isChanged: PropTypes.bool
}

interface UnderwritingSyndicateStyledProps {
  isChanged: boolean
}

const UnderwritingSyndicateStyled = styled.div<UnderwritingSyndicateStyledProps>`
  background: ${({ theme, isChanged }) => isChanged && theme.yellow_100};

  display: block;
  p:nth-of-type(1) {
    text-align: start !important;
  }
  .jgcs {
    div:last-of-type {
      text-align: end;
    }
  }
  .jgc-expand-all {
    cursor: pointer;
    display: inline-block;
    border-radius: 3px;
    padding: 6px 15px;
    margin: 8px 0;
    border: none;
    background: ${({ theme }) => theme.grey_100};
  }
  .jgc-list {
    width: 100%;
    border: none;
  }
  ul {
    font-size: 12px;
    margin: 0;
    width: 50%;

    list-style-type: none;
  }
`
