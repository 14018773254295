/**
 * IF YOU CHANGE THIS FILE,
 * YOU MUST ALSO UPDATE THE MATCHING FILE IN THE API REPO
 */
import {
  otherSyndicateBanks,
  offeringSizeNrSharesPrimary,
  offeringSizeNrSharesSecondary,
  finalOfferingSizeNrShares,
  finalOfferingSizeValue,
  offeringCurrencyType,
  distribution,
  lockUpExpiryDays,
  listingExchange,
  stockTickerCode,
  isinNumber,
  securityType,
  commitments,
  issuerDomicile,
  subscriptionPrice,
  subscriptionRatio,
  discountToClose,
  discountToTERP,
  TERP,
  headerDisclaimerText,
  disclaimerText,
  mifidTargetMarket,
  issuedShareCapital,
  useOfProceeds,
  primarySecondaryTotal,
  offeringSizeValue,
  additionalInfo,
  otherSellingRestrictions,
  percentCapitalIncrease,
  offeringSizeNrShares,
  otherSecurityType,
  greenshoePrimaryShares,
  greenshoeSecondaryShares,
  stabAgent,
  marketCap,
  minimumOrder,
  conditionsForCompletion,
  documentation,
  allocationCriteria,
  investorAcknowledgement,
  commissions,
  businessDescription,
  sector,
  state,
  distributionIntoSaudiArabia
} from '../../formTypes/inputFields'

import {
  startOfTradingNewSharesDate,
  settlementOfNewSharesDate,
  prospectusPublicationDate,
  dealAnnouncementDate,
  egmDate,
  lastDayOfCRTradingDate,
  exDate,
  recordDate,
  startOfRightsTradingDate,
  startOfSubscriptionPeriodDate,
  endOfRightsTradingDate,
  announcementOfResultsDate,
  endOfSubscriptionPeriodDate,
  rumpPlacementDate,
  lastDayOfStabTrading,
  finalSettlementDate
} from '../../formTypes/datePickers'

export const mainQuestionsRi = [
  otherSyndicateBanks,
  offeringCurrencyType,
  state,
  offeringSizeNrShares,
  offeringSizeNrSharesPrimary,
  offeringSizeNrSharesSecondary,
  primarySecondaryTotal,
  offeringSizeValue,
  useOfProceeds,
  finalOfferingSizeNrShares,
  finalOfferingSizeValue,
  subscriptionPrice,
  subscriptionRatio,
  discountToTERP,
  distribution,
  otherSellingRestrictions,
  mifidTargetMarket,
  lockUpExpiryDays,
  listingExchange,
  distributionIntoSaudiArabia,
  stockTickerCode,
  isinNumber,
  headerDisclaimerText,
  disclaimerText
]

export const additionalQuestionsRi = [
  greenshoePrimaryShares,
  greenshoeSecondaryShares,
  stabAgent,
  percentCapitalIncrease,
  issuerDomicile,
  securityType,
  otherSecurityType,
  TERP,
  discountToClose,
  commitments,
  issuedShareCapital,
  marketCap,
  minimumOrder,
  conditionsForCompletion,
  documentation,
  allocationCriteria,
  investorAcknowledgement,
  commissions,
  sector,
  businessDescription,
  additionalInfo
]

export const timetableRi = [
  dealAnnouncementDate,
  recordDate,
  lastDayOfCRTradingDate,
  exDate,
  startOfRightsTradingDate,
  startOfSubscriptionPeriodDate,
  endOfRightsTradingDate,
  endOfSubscriptionPeriodDate,
  startOfTradingNewSharesDate,
  announcementOfResultsDate,
  settlementOfNewSharesDate,
  finalSettlementDate,
  egmDate,
  prospectusPublicationDate,
  rumpPlacementDate,
  lastDayOfStabTrading
]
