import clsx from 'clsx'
import React from 'react'
import { twMerge } from 'tailwind-merge'
import InfoIcon from '@material-ui/icons/Info'
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
interface Column{
  Header: string
  tailwindWidth?: string
}

interface Record {
  [index: string]: React.ReactNode
}

interface TableProps {
  rows: Record[]
  columns: Column[]
  tableProps?
  headerProps?
  bodyProps?
  cellProps?
  rowProps?
  noDataMsg?: string
  defaultTailwindWidth?
}

interface TableContainerProps {
  children: React.ReactNode
  additionalTableClasses?: string
}

interface TableHeaderProps {
  children: React.ReactNode
  additionalClasses?: string
}
interface TableHeaderItemProps {
  children: React.ReactNode
  additionalClasses: string
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
  title?: string
}

interface TableBodyProps {
  children: React.ReactNode
}

interface TableRowProps {
  dataQa?: string
  children: React.ReactNode
  rowIdx: number
  style?: {[index: string]: string}
}
interface TableCellProps {
  children: React.ReactNode
  additionalClasses?: string
}

export const TableContainer = ({ children, additionalTableClasses, ...props }:
TableContainerProps): JSX.Element => {
  const tableClasses = clsx('table relative w-full border divide-y divide-gray-200 h-full',
    additionalTableClasses)

  return (
    <div className='py-2 w-full align-middle inline-block h-full pb-10'>
      <div className='shadow border-b border-gray-200 sm:rounded-lg ' />
      <div className={tableClasses} {...props}>
        {children}
      </div>
    </div>
  )
}

export const TableHeader = ({ children, additionalClasses, ...props }: TableHeaderProps): JSX.Element => (
  <div className={twMerge('theadshadow sticky top-0 bg-gray-50 border-solid z-10', additionalClasses ?? '')} {...props}>
    <div className='tr shadow flex'>
      {children}
    </div>
  </div>
)

export const TableBody = ({ children, ...props }: TableBodyProps): JSX.Element => (
  <div {...props}>
    <div className='h-full'>{children}</div>
  </div>
)

export const TableHeaderItem = ({ children, additionalClasses, ...props }:
TableHeaderItemProps): JSX.Element => {
  const classes =
    twMerge(clsx('th px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider',
      additionalClasses))

  return (
    <div className={classes} {...props}>
      {children}{props.title ? <span title={props.title}> <InfoIcon /></span> : null}
    </div>
  )
}
export const TableRow = ({ dataQa, rowIdx, children, style, ...props }: TableRowProps): JSX.Element => {
  const className = rowIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'
  const classes = clsx('tr', 'flex', className)

  return (
    <div className={classes} style={style} {...props} data-qa={dataQa ?? ''}>
      {children}
    </div>
  )
}

export const TableCell = ({ children, additionalClasses, ...props }: TableCellProps): JSX.Element => {
  const classes = twMerge(clsx('td px-2 py-1 text-xs text-gray-500 break-words', additionalClasses))
  return (
    <div className={classes} {...props}>{children}</div>
  )
}

export const TableNoDataMessage = ({ children, additionalClasses }: TableCellProps): JSX.Element => (
  <div className={twMerge('no-data my-6 font-medium w-full text-center ', additionalClasses ?? '')}><div className='m-5'>{children}</div></div>
)

export const Table = ({
  rows, columns, defaultTailwindWidth: defaultWidth, tableProps,
  headerProps, bodyProps, cellProps, rowProps, noDataMsg
}: TableProps): JSX.Element => {
  return (
    <TableContainer>
      <TableHeader>
        {columns.map((column) => (
          <TableHeaderItem
            key={column.Header} additionalClasses={column?.tailwindWidth ??
            defaultWidth}
          >{column.Header}
          </TableHeaderItem>
        ))}
      </TableHeader>
      <TableBody>
        {rows.map((row, rowIdx) => {
          return (
            <TableRow rowIdx={rowIdx} key={`row-${rowIdx.toString()}`}>
              {Object.values(row).map((cell, cellIdx: number) => {
                return (
                  <TableCell
                    key={`cell-${rowIdx}-${cellIdx}`}
                    additionalClasses={columns[cellIdx]?.tailwindWidth ??
                     defaultWidth}
                  >{cell}
                  </TableCell>
                )
              })}
            </TableRow>
          )
        })}
        {rows.length <= 0 && <TableNoDataMessage>{noDataMsg ?? 'No Data'}</TableNoDataMessage>}
      </TableBody>
    </TableContainer>
  )
}
