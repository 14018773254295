import React from 'react'

import styled from '@emotion/styled'
import { TermsheetTable } from 'components/Termsheet/View/TermsheetTable'
import { TermsheetTimetable } from 'components/Termsheet/View/TermsheetTimetable'
import { Button } from '@material-ui/core'
import { useDealFormContext } from '../../../containers/DealForm/context'
import { useTermsheetModalContext } from './context'

export const TermsheetPreview = (): JSX.Element => {
  const { formValues } = useDealFormContext()
  const { setIsOpen } = useTermsheetModalContext()

  return (
    <>
      <TermsheetTableStyled>
        <div>
          <TermsheetTable termsheetObject={formValues} />
        </div>
        <div>
          <TermsheetTimetable termsheetObject={formValues} />
        </div>
      </TermsheetTableStyled>
      <ModalPreviewButtons>
        <Button onClick={() => setIsOpen(false)}>Close</Button>
      </ModalPreviewButtons>
    </>
  )
}

const TermsheetTableStyled = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-gap: 15px;
  word-break: break-word;
  & > div:last-of-type {
    max-width: 400px;
  }
  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
  }
`

const ModalPreviewButtons = styled.div`
  display: flex;
  justify-content: space-between;
  width: 30%;
  margin-top: 20px;
  white-space: pre-wrap;
  & > div {
    width: 50%;
  }
  & > div:first-of-type {
    margin-right: 15px;
  }
  .MuiButton-root {
    padding-left: 30px;
    padding-right: 30px;
  }
`
