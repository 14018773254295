import { Action, action, Computed, computed, Thunk, thunk } from 'easy-peasy'
import { onError } from 'lib'
import { getAllTermsheetsById, getTermsheetByDealIdAndTermsheetId, getTermsheetsByDeal } from 'services/termsheets-v1'
import { generateTermsheetOptionsArray } from 'utils/generateTermsheetOptionsArray'
import { TermsheetState } from 'constants/index'
import { isReallyNotEmpty } from 'utils'

export interface Termsheet {
  id: string
  dealId: string
  issuerName: string
  publishingBankName: string
  draft: boolean

  [key: string]: any
}

export interface Deal {
  id: string
  termsheets: Termsheet[]
  state: TermsheetState
}

export interface LoadingInfo {
  isLoading?: boolean
  isLoaded?: boolean
}

// eslint-disable-next-line no-unused-vars
export type GetDealFn = (dealId: string) => Deal | undefined
// eslint-disable-next-line no-unused-vars
export type GetIsLoadedFn = (dealId: string) => boolean
export type GetIsLoadingFn = (dealId: string) => boolean

export interface DealsStoreModel {
  currentDealId: string | null
  deals: Map<string, Deal>
  loadingInfoMap: Map<string, LoadingInfo>
  setIsLoading: Action<DealsStoreModel, { id: string, isLoading: boolean }>
  setIsLoaded: Action<DealsStoreModel, { id: string, isLoaded: boolean }>
  setDeal: Action<DealsStoreModel, Deal>
  fetchFromBackend: Thunk<DealsStoreModel, { dealId?: string, termsheetId?: string, termsheet?: Termsheet }>
  refreshFromBackend: Thunk<DealsStoreModel>
  getDeal: Computed<DealsStoreModel, GetDealFn>
  getIsLoaded: Computed<DealsStoreModel, GetIsLoadedFn>
  getIsLoading: Computed<DealsStoreModel, GetIsLoadedFn>
}

export const deals: DealsStoreModel = {
  currentDealId: null,
  deals: new Map<string, Deal>(),
  loadingInfoMap: new Map<string, LoadingInfo>(),

  setIsLoading: action<DealsStoreModel, { id: string, isLoading: boolean }>((state, { id, isLoading }) => {
    const loadingInfo = state.loadingInfoMap.get(id)
    if (loadingInfo != null) {
      loadingInfo.isLoading = isLoading
    } else {
      state.loadingInfoMap.set(id, { isLoading })
    }
  }),

  setIsLoaded: action<DealsStoreModel, { id: string, isLoaded: boolean }>((state, { id, isLoaded }) => {
    const loadingInfo = state.loadingInfoMap.get(id)
    if (loadingInfo != null) {
      loadingInfo.isLoaded = isLoaded
    } else {
      state.loadingInfoMap.set(id, { isLoaded })
    }
  }),

  setDeal: action<DealsStoreModel, Deal>((state, deal) => {
    state.deals.set(deal.id, deal)
    state.currentDealId = deal.id
  }),

  refreshFromBackend: thunk<DealsStoreModel>(async (actions, payload, helpers) => {
    const dealId = helpers.getState().currentDealId
    if (dealId == null) return
    await actions.fetchFromBackend({ dealId: dealId })
  }),

  fetchFromBackend: thunk<DealsStoreModel, { dealId?: string, termsheetId?: string, termsheet?: Termsheet }>(async (actions, { dealId, termsheetId, termsheet }) => {
    const id = dealId ?? termsheetId ?? ''
    actions.setIsLoading({ id, isLoading: true })
    try {
      let dealTermsheets: any
      let state: TermsheetState = TermsheetState.ANNOUNCED
      if (termsheetId && dealId) {
        const deal = (await getTermsheetByDealIdAndTermsheetId(dealId, termsheetId))
        dealTermsheets = deal.termsheet ? [deal.termsheet] : []
        state = deal.state
      } else if (termsheetId) {
        if (isReallyNotEmpty(termsheet)) {
          dealTermsheets = [termsheet]
          state = termsheet.state
        } else {
          const deal = (await getAllTermsheetsById(termsheetId))
          dealTermsheets = deal.termsheets
          state = deal.state
        }
      } else if (dealId) {
        const deal = (await getTermsheetsByDeal(dealId))
        dealTermsheets = deal.termsheets
        state = deal.state
      }
      let termsheets = dealTermsheets.map(termsheet => ({ ...termsheet, dealState: state }))
      if (termsheets.length > 0) {
        termsheets = generateTermsheetOptionsArray(termsheets)
      }
      const deal: Deal = { id: dealId ?? termsheetId ?? '', termsheets, state }
      actions.setDeal(deal)
      actions.setIsLoading({ id, isLoading: false })
      actions.setIsLoaded({ id, isLoaded: true })
      return deal
    } catch (error: any) {
      actions.setIsLoading({ id, isLoading: false })
      actions.setIsLoaded({ id, isLoaded: true })
      onError({ error })
    }
  }),

  getDeal: computed<DealsStoreModel, GetDealFn>((state) => {
    return (dealId: string): Deal | undefined => {
      return state.deals.get(dealId)
    }
  }),

  getIsLoaded: computed<DealsStoreModel, GetIsLoadedFn>((state) => {
    return (dealId: string): boolean => {
      const loadingInfo = state.loadingInfoMap.get(dealId)
      if (loadingInfo != null) {
        return loadingInfo.isLoaded === true
      }
      return false
    }
  }),

  getIsLoading: computed<DealsStoreModel, GetIsLoadingFn>((state) => {
    return (dealId: string): boolean => {
      const loadingInfo = state.loadingInfoMap.get(dealId)
      if (loadingInfo != null) {
        return loadingInfo.isLoading === true
      }
      return false
    }
  })
}
