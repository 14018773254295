import { BANK_ROLE, DASHBOARD_TYPE, TermsheetRegion, TermsheetState } from 'constants/index'
import { PartitionedDeals } from 'routes/DashboardPage/types'
import { IConfigurationForm } from 'routes/TermsheetV2Page/components/Configuration/types'
import { ProfileDTO, UserPreferencesDTO } from 'services/users'
import { MODES, OfferingType, STEPS_ORDER } from 'store/applicationGeneratorStore/const'
import { AlertData } from 'ui/Alert/types'
import { PartitionedDealsV2 } from 'routes/DashboardPageV2/types'
import { DashboardDealViewDTO, DealOrTermsheetDTO, PublishingBank } from 'services/deals'
import { ICompleteForm } from 'routes/TermsheetV2Page/components/Complete/types'
import { AccountType } from 'lib/types'

export enum AttachmentVirusScanStatus {
  CLEAN = 'CLEAN',
  INFECTED = 'INFECTED',
  CANNOT_BE_SCANNED = 'CANNOT_BE_SCANNED',
  PENDING = 'PENDING'
}

export interface AttachmentVirusScanData {
  title: string
  icon: AttachmentIcons
  color: string
}

export enum AttachmentIcons {
  CHECK_CIRCLE = 'check-circle',
  NO_SYMBOL = 'no-symbol',
  EXCLAMATION = 'exclamation'
}

export interface AttachmentFormObject {
  attachments?: Attachment[]
  deletedAttachments?: Attachment[]
}

export interface Attachment {
  name: string
  id: string
  timestamp: number
  access?: AttachmentAccess
  virusScanStatus: AttachmentVirusScanStatus
  contentDisposition: string
}

export enum AttachmentAccess {
  INTERNAL = 'internal',
  EXTERNAL = 'external'
}
export interface CountryItem {
  name: string
  code: string
  displayType: FilterDisplay
}
export interface SubRegionSoringMappingType {
  [subReg: string]: number
}
export interface SubRegionType {
  [subReg: string]: CountryItem[]
}
export interface CountriesType {
  [key: string]: SubRegionType
}

export type SetUserDataType = (ProfileDTO, UserPreferencesDTO) => void
export type GetUserDataType = () => Promise<Partial<ProfileDTO>>

export interface DealListByRegionV1 {
  [region: string]: DealOrTermsheetDTO[]
}
export interface DealListByRegionV2 {
  [region: string]: DashboardDealViewDTO[]
}
export interface DealListByTypeItem {
  [key: string]: PartitionedDeals | null
}
export interface DealListByTypeItemV2 {
  [key: string]: PartitionedDealsV2 | null
}
export type DealListByTypeItemType = DealListByTypeItem & DealListByTypeItemV2
export interface DealListByType<T> {
  [type: string]: T
}
export interface CountriesWithNumberOfActiveDeals {
  [countryCode: string]: number
}

export enum FilterDisplay {
  SHOW = 'Always Show',
  EXPANDED = 'Expanded List',
  HIDDEN = 'Hidden Unless Active Deal',
}

export interface CountryDTO {
  name: string
  subRegion: string
  region: string
  alpha3ISOCode: string
  professionalInvestorBlurb: string
  filterDisplay?: FilterDisplay
  shortName?: string
  subRegionSortingRank?: number
}
export type FilterFn = (item: CountryItem) => boolean

export interface UserModel {
  profile: ProfileDTO | null
  config: {
    dashboardType: DASHBOARD_TYPE | null
    canAccessLockUpExpiry: boolean
    canSeeDashboardForAllSwitch: boolean
  }
  preferences: UserPreferencesDTO
  organizationLogo?: string
  userCountryCode?: string
}
export interface IUserRoles {
  isTaaS: boolean
  isBanker: boolean
  isInvestor: boolean
  isSSS: boolean
}
export interface ToastArgs {
  alert: AlertData
  ms?: number
}

export interface IFilterSettings {
  isGeoFilterEnabled: boolean
  includeWithNoStockExchanges: boolean
}

export interface RedirectionData {
  type: RedirectionType
  payload: RedirectionPayload
}

interface ViewTermsheetRedirectionPayload {
  bankId: string
}
export type RedirectionPayload = ViewTermsheetRedirectionPayload
export enum RedirectionTypeEnum {
  VIEW_TERMSHEETS = 'VIEW_TERMSHEETS'
}
export type RedirectionType = RedirectionTypeEnum | null

export type IStep = IConfigurationForm | ICompleteForm | {}

export interface IApplicationForm {
  [key: string]: IStep
}

export interface IApplicationFormMeta {
  tsId?: string
  mode: typeof MODES[keyof typeof MODES] | null
  isDraft?: boolean
  offeringType: OfferingType
  currentStep: typeof STEPS_ORDER[keyof typeof STEPS_ORDER] | null
  dealId?: string
  publishingBankIds?: string[]
}

export type ONE_OF_STEPS = typeof STEPS_ORDER[number]

export enum BUNDLE_MODE {
  ECM = 'ECM',
  MFE = 'MFE',
}

type NameModifier = (offeringType: OfferingType) => string
export type FieldLocation = 'root' | 'offeringTypeSubObject' | 'timetableSubObject'
export interface MappingRecords {
  [key: string]: {
    name: string | NameModifier
    valueMappingHandler: (value: any, stepValues: Record<string, any>, offeringType: OfferingType) => any
    location?: FieldLocation
    // belongsToOfferingTypes - doesn't make a lot of sense now,
    // but it allows the mapper to be used as a single source of truth for building the form
    belongsToOfferingTypes?: OfferingType[]
  }
}
export interface FieldsMapping {
  [key: string]: MappingRecords
}

export interface OtherSyndicateBank {
  name: string
  role: BANK_ROLE
  id: string
}

export interface SyndicateBanker {
  // this looks very bad, but that's how it comes from the backend.
  designatedBankerName?: {
    email: string
    name: string
    organizationId: string
    phone: string
  }
  fullBankerName?: string
  designatedBankerEmail?: string
  underwritingSplit?: string
  designatedBankerPhone?: string
}

export interface ExternalLinks {
  researchfnLink?: string
  netroadshowLink?: string
  prospectusRegistrationLink?: string
}

export interface CreatedBy {
  name: string
  email: string
  type: AccountType
}

export interface ITermsheetV2Entity {
  syndicateBanker: SyndicateBanker
  organizationId: string
  termsheetId: string
  pricingDate?: string
  dealId: string
  issuerName: string
  state: TermsheetState
  draft: boolean
  offeringType: OfferingType
  region: TermsheetRegion
  publishingBankName: string
  publishingBankRole?: BANK_ROLE
  headerDisclaimer?: string
  footerDisclaimer?: string
  createdAt?: string
  updatedAt?: string
  otherSyndicateBanks?: OtherSyndicateBank[]
  attachments: Attachment[]
  primaryOrSecondary: string
  offeringCurrencyType: string
  isOrderExists: boolean
  externalLinks: ExternalLinks
  createdBy?: CreatedBy
}

export interface PreviousDealDTO {
  dealId: string
  issuerName: string
  state: string
  ticker?: string
  isTermsheetViewableOnThisDeal: boolean
  offeringType: OfferingType
  endOfSubscriptionPeriodDate?: string
  closeOfBookbuildingDate?: string
  pricingDate?: number
  publishingBankNames: PublishingBank[]
}

export interface EmailToSelfResponse {
  destinationEmailAddress: string
  termsheet: ITermsheetV2Entity
}

export interface ITsV2WithCommands extends ITermsheetV2Entity {
  command: {
    sendFeedNotifications: boolean
    sendEmailNotifications: boolean
  }
}

export interface ITsDraft extends ITermsheetV2Entity {
  sendEmail?: boolean
}
