import React, { FormEvent, ReactNode } from 'react'

interface Props {
  title: JSX.Element | string
  onSubmit?: (e: FormEvent) => void
  children?: ReactNode
  dataQa?: string
}

export function Form ({ title, children, onSubmit, dataQa }: Props): JSX.Element {
  return (
    <div className='flex flex-col justify-center py-12 sm:px-6 lg:px-8'>
      <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-lg'>
        <div className='bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10'>
          <h2 className='mb-6 text-xl text-gray-600'>{title}</h2>
          <form className='space-y-6' onSubmit={onSubmit} action='' data-qa={dataQa}>
            {children}
          </form>
        </div>
      </div>
    </div>
  )
}
