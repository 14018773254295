import React, { useCallback } from 'react'
import { SimpleSelect } from '@ppui/ui-components'
import { DashboardRegion, DashboardRegionLabels } from 'constants/index'
import { useUserPreferences } from 'lib'
import { useDashboardPage } from 'routes/DashboardPage/components/useDashboardPage'
import { observer } from 'mobx-react-lite'

const RegionalFilterDropdown: React.FC = observer(() => {
  const preferencePath = ['dashboardRegion']
  const [dashboardRegion, setDashboardRegion] = useUserPreferences<DashboardRegion>(preferencePath)
  const handleDashboardRegion = useCallback(async (e: React.ChangeEvent<HTMLSelectElement>): Promise<void> => {
    return await setDashboardRegion(DashboardRegion[e.target.value] || dashboardRegion)
  }, [dashboardRegion, setDashboardRegion])

  const { filteredDashboardRegions } = useDashboardPage()

  return (
    <SimpleSelect
      name='location'
      label='Dashboard region'
      value={dashboardRegion}
      options={filteredDashboardRegions.map(region => ({
        value: region,
        text: DashboardRegionLabels[region]
      }))}
      onChange={handleDashboardRegion}
    />
  )
})
export default RegionalFilterDropdown
