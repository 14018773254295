import React, { useRef, ReactElement } from 'react'

import FormControl from '@material-ui/core/FormControl'
import { ThemeProvider } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { FormLabel } from '../../FormLabel'
import { Controller, Control } from 'react-hook-form'
import { getErrorLine } from '../../ErrorLine/getErrorLine'
import { TooltipReusable } from '../../TooltipReusable'
import { ListSubheader } from '@material-ui/core'
import { DropdownContainer, FormHeaderStyled, AsteriskStyled, useDropdownStyles, dropdownTheme } from './styled'

interface DropdownHookedProps {
  name?: string
  label?: string
  control: Control<Record<string, any>>
  watch: (val) => string
  errors: Record<string, Record<string, string>>
  optionsArray: any[]
  optionsArray2?: any[]
  formTooltipText?: string | object
  isEmptyPlaceholder?: boolean
  'data-qa'?: string
  defaultValue?: string | boolean
  isDisabled?: boolean
  show?: boolean
}

export const DropdownHooked = ({ name = '', label, control, watch, optionsArray, optionsArray2, errors, formTooltipText, isEmptyPlaceholder = true, 'data-qa': dataQA, defaultValue, isDisabled, show }: DropdownHookedProps): ReactElement => {
  const classes = useDropdownStyles()

  const watchResult = watch(name)

  const tooltipContent: string | Object = formTooltipText !== undefined && formTooltipText instanceof Object ? formTooltipText[watchResult] : formTooltipText
  const inputRef = useRef<HTMLDivElement>(null)

  const value = watchResult === undefined ? '' : watchResult

  // hide component if tadawul not selected

  if (show === false) {
    return <></>
  }

  return (
    <DropdownContainer width='100%'>
      <ThemeProvider theme={dropdownTheme}>
        <FormHeaderStyled>
          <FormLabel>{label}</FormLabel>
          {tooltipContent as boolean && (
            <>
              <AsteriskStyled>*</AsteriskStyled>
              <TooltipReusable helperText={tooltipContent as string} />
            </>
          )}
        </FormHeaderStyled>
        <FormControl className={classes.formControl}>
          <Controller
            as={
              <Select disableUnderline className={`${classes.select}`} value={value} inputRef={inputRef}>
                {isEmptyPlaceholder && (
                  <MenuItem value=''>
                    <em>None</em>
                  </MenuItem>
                )}

                {optionsArray2?.map((item, index) => (
                  <MenuItem value={item.value} key={index}>
                    {item.label}
                  </MenuItem>
                ))}

                {(optionsArray2 != null) && <ListSubheader>-</ListSubheader>}
                {optionsArray.map((item, index) => (
                  <MenuItem value={item.value} key={index}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
}
            onFocus={() => inputRef.current?.focus()}
            control={control}
            name={name}
            defaultValue={defaultValue ?? ''}
            data-qa={dataQA}
            disabled={isDisabled}
          />
          {getErrorLine(errors, name)}
        </FormControl>
      </ThemeProvider>
    </DropdownContainer>
  )
}
