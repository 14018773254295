import React from 'react'
import { MainListItems } from 'components/SideMenu/components/MainListItems'
import RegionalFilterDropdown from 'components/SideMenu/components/RegionalFilterDropdown'
import { useUserRole } from 'lib'
import { CountryFilterButton } from 'components/CountryFilter/CountryFilterButton'
import { observer } from 'mobx-react-lite'
import { useIsSpecificPathWatch } from 'lib/hooks'
import { addNewTSPage, dashboardPage, dashboardV2Page, pagesWithHiddenSidebar } from 'components/SideMenu/const'

export const RenderSideMenu: React.FC<{
  isAuthenticated: boolean
  dealIdContext: string
}> = observer(({
  isAuthenticated, dealIdContext
}) => {
  const { isTaaS, isInvestor, isSSS } = useUserRole()
  const isDashboardPath = useIsSpecificPathWatch(dashboardPage)
  const isSidebarElementsHiddenForSpecificPages = useIsSpecificPathWatch(pagesWithHiddenSidebar)
  const isAddNewTSPage = useIsSpecificPathWatch(addNewTSPage)
  const isDashboardV2Path = useIsSpecificPathWatch(dashboardV2Page)
  const isRegionalFilterDropdownVisible = !(isDashboardV2Path && isDashboardPath) && (isTaaS || isInvestor || isSSS)
  const isGeoFilterEnabled: boolean = isInvestor || isTaaS

  if (isSidebarElementsHiddenForSpecificPages && !isAddNewTSPage) {
    return <div className='w-60' />
  }
  return (
    <div className='w-60'>
      {isAuthenticated && (
        <div className='p-4'>
          <div className='flex flex-col'>
            {isRegionalFilterDropdownVisible && <RegionalFilterDropdown />}
            {isGeoFilterEnabled && (
              <div className='mt-4 flex flex-col'>
                <CountryFilterButton />
              </div>
            )}
          </div>
          {(isRegionalFilterDropdownVisible) && (<hr className='my-4 border-gray-300' />)}

          <MainListItems />

        </div>
      )}
    </div>
  )
})
