/* eslint-disable max-len */
import { mergeDeepRight, find, pipe, propOr, pathOr, pathEq } from 'ramda'

import { useCallback, useEffect, useMemo, useState } from 'react'

import { useDealTermsheets } from 'containers/TermsheetInformation/useDealTermsheets'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { StoreModel } from 'store/store'
import { SortedTermsheet } from 'utils/generateTermsheetOptionsArray'
import { useUserPreferences } from 'lib'
import { useStores } from 'store/rootStore'
import { Termsheet } from 'store/deals'

function findAvailableTermsheetKey (termsheets: SortedTermsheet[]): string {
  for (const key of Object.keys(termsheets)) {
    const termsheet = termsheets[key]
    if (termsheet.termsheetObject?.available === true) {
      return key
    }
  }

  return '0' // Shouldn't happen but returning the first one for safety
}

export const useContextValueHandler = (): { value: any } => {
  const [isUsingDashboardForAll] = useUserPreferences<boolean>(['isUsingDashboardForAll'])
  const { userStore } = useStores()
  const isLoadedUserProfile = userStore.isLoaded
  const { termsheets, setTermsheets } = useDealTermsheets()
  const [selectedTermsheetId, setSelectedTermsheetId] = useState('')

  useEffect(() => {
    if (!isLoadedUserProfile) return
    if (Object.keys(termsheets).length === 0) return
    const termsheetKey = findAvailableTermsheetKey(termsheets)

    setSelectedTermsheetId(pathOr('', [termsheetKey, 'termsheetObject', 'termsheetId'], termsheets))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(termsheets), isLoadedUserProfile, isUsingDashboardForAll])
  const selectedTermsheet = pipe(find(pathEq(['termsheetObject', 'termsheetId'], selectedTermsheetId)), propOr({}, 'termsheetObject'))(termsheets) as Termsheet
  const { getDeal } = useStoreState<StoreModel>((state) => state.deals)
  const { setDeal } = useStoreActions<StoreModel>((actions) => actions.deals)
  const storedDeal = getDeal(selectedTermsheet.dealId)
  if (storedDeal) {
    selectedTermsheet.dealState = storedDeal.state
  }
  const updateTermsheetView = useCallback((data): void => {
    const updatedTermsheets = termsheets.map((termsheet) => {
      if (termsheet.termsheetObject.termsheetId === data.termsheetId) {
        return mergeDeepRight(termsheet, {
          termsheetObject: mergeDeepRight(termsheet.termsheetObject, data)
        })
      }
      return termsheet
    })
    const dealId = updatedTermsheets[0].termsheetObject.dealId
    const deal = getDeal(dealId)
    deal.termsheets = updatedTermsheets
    setDeal(deal)
    setTermsheets(updatedTermsheets)
  }, [getDeal, setDeal, setTermsheets, termsheets])
  const clearAttachments = useCallback((termsheetId): void => {
    const updatedTermsheets = termsheets.map((termsheet) => {
      if (termsheet.termsheetObject.termsheetId === termsheetId) {
        return mergeDeepRight(termsheet, {
          termsheetObject: mergeDeepRight(termsheet.termsheetObject, { attachments: [] })
        })
      }
      return termsheet
    })
    setTermsheets(updatedTermsheets)
  }, [setTermsheets, termsheets])
  const value = useMemo(
    () => ({
      termsheets,
      setTermsheets,
      selectedTermsheet,
      updateTermsheetView,
      selectedTermsheetId,
      setSelectedTermsheetId,
      clearAttachments
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [termsheets, setTermsheets, selectedTermsheet, updateTermsheetView]
  )

  return {
    value
  }
}
