import React, { createContext, useContext, useState } from 'react'
import { ModalComponent } from 'components/ModalComponent'
import { AddBookBuildingMessage } from 'contexts/modal-context/AddBookBuildingMessage'
import { ConfirmRemovingBookBuildingMessage } from 'contexts/modal-context/ConfirmRemovingBookBuildingMessage'
import { EditBookBuildingMessage } from 'contexts/modal-context/EditBookBuildingMessage'
import { ConfirmDeleteDraftModal } from 'contexts/modal-context/TermsheetModals/ConfirmDeleteDraftModal'
import { ConfirmPublishModal } from 'contexts/modal-context/TermsheetModals/ConfirmPublishModal'
import { PriceDealModal } from 'contexts/modal-context/TermsheetModals/PriceDealModal'
import { WithdrawModal } from 'contexts/modal-context/TermsheetModals/WithdrawModal'
import { TermsheetPreview } from 'contexts/modal-context/TermsheetModals/TermsheetPreviewModal'
import { CommonMessageModal } from 'contexts/modal-context/TermsheetModals/CommonMessageModal'
import { ConfirmDeleteFileModal } from 'contexts/modal-context/TermsheetModals/ConfirmDeleteFileModal'

const TermsheetModalContext = createContext<any>(undefined)

interface TermsheetModalProviderProps {
  children: any
}

const TermsheetModalProvider = (props: TermsheetModalProviderProps): any => {
  const [isOpen, setIsOpen] = useState(false)
  const [submitHandler, setSubmitHandler] = useState<any>()
  const [modalSelector, setModalSelector] = useState<any>()
  const [modalSize, setModalSize] = useState<string>('md')
  const [deleteHandler, setDeleteHandler] = useState<any>()
  const [bookMessageForEditing, setBookMessageForEditing] = useState<any>()
  const [messages, setMessages] = useState<string[]>()
  const [filename, setFilename] = useState<string>('')

  const value = React.useMemo(
    () => ({
      isOpen,
      setIsOpen,
      setModalSelector,
      setSubmitHandler,
      setDeleteHandler,
      setBookMessageForEditing,
      setMessages,
      setFilename
    }),
    [isOpen]
  )

  const onDelete = React.useCallback(() => {
    if (deleteHandler as boolean) {
      // This handler must be wrapped in additional layer of function because otherwise state reducer calls this function as an action
      const actualHandler = deleteHandler()
      actualHandler && actualHandler() // eslint-disable-line
    }
  }, [deleteHandler])

  const modalRouter = React.useMemo(
    () => ({
      confirmPublish: {
        component: <ConfirmPublishModal submitHandler={submitHandler} setIsModalOpen={setIsOpen} />,
        size: 'xs'
      },
      confirmWithdraw: {
        component: <ConfirmPublishModal title='Withdraw' submitHandler={submitHandler} setIsModalOpen={setIsOpen} />,
        size: 'xs'
      },
      confirmFileDelete: {
        component: <ConfirmDeleteFileModal filename={filename} submitHandler={submitHandler} setIsModalOpen={setIsOpen} />,
        size: 'xs'
      },
      commonMessages: {
        component: <CommonMessageModal messages={messages} setIsModalOpen={setIsOpen} />,
        size: 'xs'
      },
      priceDeal: {
        component: <PriceDealModal setIsModalOpen={setIsOpen} />,
        size: 'xs'
      },
      withdraw: {
        component: <WithdrawModal setIsModalOpen={setIsOpen} />,
        size: 'xs'
      },
      termsheetPreview: { component: <TermsheetPreview />, size: 'lg' },
      deleteDraft: {
        component: <ConfirmDeleteDraftModal submitHandler={onDelete} setIsModalOpen={setIsOpen} />,
        size: 'sm'
      },
      addBookBuildingMessage: {
        component: <AddBookBuildingMessage />,
        size: 'md'
      },
      editBookBuildingMessage: {
        component: (
          <EditBookBuildingMessage
            value={bookMessageForEditing?.value ?? {}} // Prevent from console errors...
            index={bookMessageForEditing?.index ?? 0} // Prevent from console errors...
            termsheetId={bookMessageForEditing?.termsheetId ?? ''} // Prevent from console errors...
          />
        ),
        size: 'md'
      },
      removeBookBuildingMessage: {
        component: <ConfirmRemovingBookBuildingMessage submitHandler={onDelete} setIsModalOpen={setIsOpen} />
      }
    }),
    [submitHandler, setIsOpen, onDelete, bookMessageForEditing, messages, filename]
  )
  React.useEffect(() => {
    setModalSize(modalRouter[modalSelector]?.size)
  }, [modalRouter, modalSelector])
  return (
    <TermsheetModalContext.Provider value={value}>
      {props.children}
      <ModalComponent maxWidth={modalSize as any} isOpen={isOpen} handleModalClose={() => setIsOpen(false)}>
        {modalRouter[modalSelector]?.component}
      </ModalComponent>
    </TermsheetModalContext.Provider>
  )
}

function useTermsheetModalContext (): any {
  const context = useContext<any>(TermsheetModalContext)
  if (!(context as boolean)) {
    throw new Error('useTermsheetModalContext must be inside TermsheetModalProvider')
  }

  return context
}

export { TermsheetModalProvider, useTermsheetModalContext }
