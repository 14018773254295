import { API, APIInput } from './aws-api'
import { cond, equals } from 'ramda'

export interface BookbuildMessageDTO {
  dealId: string
  termsheetId: string
  bookBuildingMessages: BookbuildMessage[]
}

export interface BookbuildMessage {
  messageText: string
  publishingBankerName: string
  timeStamp: string
  userEmail: string
}

export const getApi = cond([
  [equals('add'), () => API<BookbuildMessageDTO>().post],
  [equals('edit'), () => API<BookbuildMessageDTO>().put],
  [equals('remove'), () => API<BookbuildMessageDTO>().del]
]) as (type: string) => (x: APIInput) => Promise<BookbuildMessageDTO>

export async function updateBookbuildMessage (
  termsheetId: string,
  {
    params,
    newMsg,
    type,
    sendFeedNotifications,
    sendEmailNotifications
  }: {
    params: BookbuildMessageDTO
    newMsg: BookbuildMessage | null
    sendFeedNotifications: boolean
    type: string
    sendEmailNotifications: boolean
  }): Promise<BookbuildMessageDTO> {
  return await getApi(type)({
    path: `/v2/bookbuildMessage/${params.termsheetId}/bookbuilds`,
    init: {
      body: {
        params,
        newMsg,
        sendFeedNotifications,
        sendEmailNotifications
      }
    }
  })
}
