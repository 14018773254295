import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import CancelIcon from '@material-ui/icons/Cancel'
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone'
import { useDealFormContext } from 'containers/DealForm/context'
import { TableTopSectionButtons } from 'components/Buttons/Dashboard/TableTopSectionButtons'
import { PreviewAndPublish } from 'containers/DealForm/Step2Form/components/PreviewAndPublish'
import { useSubmitHandler } from 'containers/DealForm/Step2Form/useSubmitHandler'
import { TextField } from '@material-ui/core'
import { useTermsheetModalContext } from 'contexts/modal-context/TermsheetModals/context'
import { useUserRole } from 'lib'
import { useFeatureFlags } from 'lib/useFeatureFlags'
import { FEATURES } from 'constants/features'
import { ConfirmModalStyled } from 'contexts/modal-context/TermsheetModals/ConfirmModalStyled'
import { observer } from 'mobx-react-lite'

export const ConfirmPublishModal: React.FC<{
  submitHandler: Function
  setIsModalOpen: Function
  title?: string
}> = observer(({ submitHandler, setIsModalOpen, title }) => {
  const { isTaaS } = useUserRole()
  const [confirmTextBoxState, setConfirmTextBoxState] = useState('')
  const { formValues } = useDealFormContext()
  const { handleSubmit } = useSubmitHandler()
  const { setSubmitHandler } = useTermsheetModalContext()
  const issuerName = formValues.issuerName
  const isDisabled = confirmTextBoxState !== 'OK'
  const [shouldSendFeedNotifications, setShouldSendFeedNotifications] = useState<boolean>(true)
  const [shouldSendEmailNotifications, setShouldSendEmailNotifications] = useState<boolean>(true)
  const { isFeatureFlagEnabled } = useFeatureFlags()
  const isEmailNotificationsFeatureEnabled: boolean = isFeatureFlagEnabled(FEATURES.emailNotifications)

  useEffect(() => {
    return () => setSubmitHandler(null)
  }, [setSubmitHandler])

  const onSendFeedNotificationsChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setShouldSendFeedNotifications(event.target.checked)
  }, [setShouldSendFeedNotifications])

  const onSendEmailNotificationsChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setShouldSendEmailNotifications(event.target.checked)
  }, [setShouldSendEmailNotifications])
  const titleLowerCase = title ? title.toLowerCase() : 'publish'
  return (
    <ConfirmModalStyled>
      <div onClick={() => setIsModalOpen(false)} className='confirmation-modal-cancel'>
        <CancelIcon />
      </div>
      <div className='confirmation-modal-bell'>
        <NotificationsNoneIcon />
      </div>
      <h3>You are about to {titleLowerCase} a Term Sheet</h3>
      <p className='confirmation-modal-issuer-name'>{issuerName}</p>
      <div className='confirmation-modal-edit'>
        <TableTopSectionButtons onClick={() => setIsModalOpen(false)}>Edit</TableTopSectionButtons>
      </div>
      <p className='confirmation-modal-text'>
        To confirm, type ‘OK’ in the box below and hit {titleLowerCase}
      </p>
      <div className='confirmation-modal-confirm'>
        <TextField
          variant='outlined'
          value={confirmTextBoxState}
          onChange={(e) => setConfirmTextBoxState(e.target.value)}
          inputProps={{ 'data-qa': 'ConfirmPublishModal-TextInput' }}
        />
        <PreviewAndPublish
          onClick={() => {
            setIsModalOpen(false)
            typeof submitHandler === 'function'
              ? submitHandler(formValues, shouldSendFeedNotifications, shouldSendEmailNotifications)
              : handleSubmit(shouldSendFeedNotifications, shouldSendEmailNotifications)
          }}
          title={title}
          disabled={isDisabled}
          data-qa='ConfirmPublishModal'
        />
      </div>
      {isTaaS && (
        <div className='mt-6 mb-4 flex justify-center'>
          <input
            id='feed-notifications'
            className='mr-3'
            type='checkbox'
            checked={shouldSendFeedNotifications}
            onChange={onSendFeedNotificationsChange}
          />
          <label
            htmlFor='notifications'
            className='cursor-pointer'
          >
            Send feed notifications?
          </label>
        </div>
      )}
      {isEmailNotificationsFeatureEnabled && (
        <div className='mt-6 mb-4 flex justify-center'>
          <input
            id='email-notifications'
            className='mr-3'
            type='checkbox'
            checked={shouldSendEmailNotifications}
            onChange={onSendEmailNotificationsChange}
          />
          <label
            htmlFor='notifications'
            className='cursor-pointer'
          >
            Send email notifications?
          </label>
        </div>
      )}
    </ConfirmModalStyled>
  )
})
