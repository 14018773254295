import {
  CountriesWithNumberOfActiveDeals,
  DealListByTypeItem,
  DealListByTypeItemV2,
  IFilterSettings
} from 'store/types'
import {
  all,
  filter,
  mapObjIndexed,
  mergeDeepWith,
  defaultTo,
  pipe,
  propEq,
  reduce, partial
} from 'ramda'
import { DashboardRegion, OFFERING_TYPE } from 'constants/index'
import memoize from 'fast-memoize'
import { DashboardDealViewDTO, DealOrTermsheetsDTOs } from 'services/deals'
import { applySorting, applyTransforms, applyUserFilters } from 'routes/DashboardPageV2/helpers/utils'
import { getAnnouncedDeals, getLiveDeals, partitionDeals } from 'routes/DashboardPageV2/helpers/partition'
import { dashboardDealTransform } from 'routes/DashboardPageV2/helpers/transform'
import { OFFERING_TYPE_TRANSFORM_MAPPING } from 'routes/DashboardPage/data/transform'
import { DashboardV2SectionType } from 'routes/DashboardPageV2/types'

export const getPartitionedDeals = memoize((
  filterSettings: IFilterSettings,
  deals: DealOrTermsheetsDTOs[],
  countries: string[],
  allowedCountries: string[]
): DealListByTypeItem => {
  return mapObjIndexed((
    transform,
    offeringType
  ) =>
    pipe(
      (data: DealOrTermsheetsDTOs[]): DealOrTermsheetsDTOs[] => filterSettings.isGeoFilterEnabled
        ? applyUserFilters(
          filterSettings,
          allowedCountries,
          countries,
          data
        )
        : data,
      filter(propEq('offeringType', offeringType)),
      partitionDeals,
      partial(applySorting, ['v1']),
      applyTransforms(transform as never)
    )(deals),
  OFFERING_TYPE_TRANSFORM_MAPPING as any
  ) as DealListByTypeItem
})

export const getPartitionedDealsV2 = memoize((
  filterSettings: IFilterSettings,
  deals: DashboardDealViewDTO[],
  countries: string[],
  allowedCountries: string[]
): DealListByTypeItemV2 => {
  return reduce((acc, offeringType) => ({
    ...acc,
    [offeringType]: pipe(
      (data: DashboardDealViewDTO[]): DashboardDealViewDTO[] => filterSettings.isGeoFilterEnabled
        ? applyUserFilters(
          filterSettings,
          allowedCountries,
          countries,
          data
        )
        : data,
      filter(propEq('offeringType', offeringType)) as (arg: DashboardDealViewDTO[]) => DashboardDealViewDTO[],
      partitionDeals,
      partial(applySorting, ['v2']) as never,
      applyTransforms<DashboardV2SectionType, DashboardDealViewDTO>(dashboardDealTransform)
    )(deals)
  }), {}, Object.keys(OFFERING_TYPE))
})

export const getFilteredDealsV2 = memoize((
  filterSettings: IFilterSettings,
  deals: DashboardDealViewDTO[],
  countries: string[],
  allowedCountries: string[]
): DashboardDealViewDTO[] => {
  return filterSettings.isGeoFilterEnabled
    ? applyUserFilters(
      filterSettings,
      allowedCountries,
      countries,
      deals
    )
    : deals
})

export const getDealsByRegion = memoize((dealsList) =>
  reduce((acc, item) => mergeDeepWith(
    (left, right) => all(Array.isArray)([left, right])
      ? [...left, ...right]
      : right,
    acc, {
      [DashboardRegion.ALL]: [item],
      [item.region]: [item]
    }), {}, dealsList)) as <T extends {region: string}>(dealsList: T[]) => {[region: string]: T[]}
export const getAmountOfActiveDealsByCountries = memoize(
  pipe(
    defaultTo([]) as (dealList?: DealOrTermsheetsDTOs[]) => DealOrTermsheetsDTOs[],
    (dealsList: DealOrTermsheetsDTOs[]): CountriesWithNumberOfActiveDeals => {
      const announced = getAnnouncedDeals(dealsList)
      const live = getLiveDeals(dealsList)
      const deals = [...announced, ...live]
      return deals.flatMap(deal => deal.countryCodes ?? [])
        .reduce((countries, code) => {
          return {
            ...countries,
            [code]: Number(countries[code] || 0) + 1
          }
        }, {})
    }
  )
) as unknown as (dealList?: DealOrTermsheetsDTOs[]) => CountriesWithNumberOfActiveDeals
