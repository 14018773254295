import qs from 'query-string'
import React, { ReactNode } from 'react'
import { useForm } from 'react-hook-form-v7'
import { Link, useLocation } from 'react-router-dom'
import { Form, FormFieldText, PrimaryButton } from 'ui'

interface FormFields {
  email: string
  password: string
}

interface Props {
  children: ReactNode
  onSubmit: (f: FormFields) => Promise<void>
}

export default function SignInForm ({ children, onSubmit }: Props): JSX.Element {
  const { email: queryStringEmail } = qs.parse(useLocation().search ?? '')

  const { handleSubmit, register, getValues, formState } = useForm<FormFields>({
    shouldUseNativeValidation: true,
    defaultValues: {
      email: queryStringEmail as string ?? '',
      password: ''
    }
  })

  const { isDirty, isSubmitting, errors } = formState

  return (
    <Form title='Sign in to your account' onSubmit={handleSubmit(onSubmit)} dataQa='SignIn-Form'>
      <FormFieldText
        {...register('email')}
        label='Email address'
        type='email'
        autoComplete='email'
        errors={errors}
        required
        dataQa='SignIn-EmailField'
      />

      <FormFieldText
        {...register('password')}
        label='Password'
        name='password'
        type='password'
        autoComplete='current-password'
        errors={errors}
        required
        dataQa='SignIn-PasswordField'
      />

      <div className='flex items-center justify-between'>
        <div className='text-sm' data-qa='SignIn-ForgotPassword'>
          <Link to={() => `/forgot-password?email=${encodeURIComponent(getValues('email'))}`} className='font-medium text-indigo-600 hover:text-indigo-500'>
            Forgot your password?
          </Link>
        </div>
      </div>

      {children}
      <PrimaryButton disabled={!isDirty || isSubmitting}>Sign In</PrimaryButton>

      <div className='text-center text-sm mt-50'>No Account?</div>
      <Link to='/buy-side-sign-up'>
        <div className='text-center text-indigo-600 hover:text-indigo-500'>
          Create a free account to access a real time dashboard now!
        </div>
      </Link>
    </Form>

  )
}
