import styled from '@emotion/styled'

export const StyledTextField = styled.div`
  height: auto;
  width: 100%;
  & > div:first-of-type {
    display: flex;
    align-items: center;
    svg {
      margin: 8px 0 0 3px;
      font-size: 15px;
      color: ${({ theme }) => theme.blue_700};
    }
  }

  .error-line {
    margin-top: 3px;
    color: red;
  }
`
