import { RootStore } from 'store/rootStore'
import { makeAutoObservable } from 'mobx'

export class RfnJourneyStore {
  private readonly rootStore: RootStore
  private _showRfnSignUpRequestForm: boolean = true

  constructor (rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }

  set showRfnSignUpRequestForm (value: boolean) {
    this._showRfnSignUpRequestForm = value
  }

  get showRfnSignUpRequestForm (): boolean {
    return this._showRfnSignUpRequestForm
  }
}
