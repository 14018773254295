import React from 'react'
import { ActionButtonAdmin } from '../../components/Buttons/AdminPage/ActionButton'
import { TransparentDashboardFilterButton } from '../../components/Buttons/DashboardFilters/TransparentDashboardFilterButton'
import { TextField } from '../../components/TextField/MainTextField'
import styled from '@emotion/styled'
import { useBookbuildingSubmit } from 'components/Termsheet/View/BookbuildingMessages/hooks'
import { useForm } from 'react-hook-form'
import { useTermsheetModalContext } from './TermsheetModals/context'
import { useDealFormContext } from '../../containers/DealForm/context'

export function AddBookBuildingMessage (): JSX.Element {
  const { handleSubmit, register } = useForm()

  const { applyBookBuildMessages } = useBookbuildingSubmit()

  const { setIsOpen, setModalSelector, setSubmitHandler } = useTermsheetModalContext()

  const { updateFormValues } = useDealFormContext()

  const [isMounted, setIsMounted] = React.useState<boolean>(true)

  React.useEffect(() => {
    return () => setIsMounted(false)
  }, [])

  const onSubmit = (data): void => {
    setModalSelector('confirmPublish')
    setIsOpen(true)
    updateFormValues(data)
    setSubmitHandler(() => async (data, shouldSendFeedNotifications, shouldSendEmailNotifications) =>
      await applyBookBuildMessages({
        type: 'add',
        data,
        shouldSendFeedNotifications,
        shouldSendEmailNotifications
      }))
  }

  return (
    <ModalStyled onSubmit={handleSubmit((data) => isMounted && onSubmit(data))}>
      <h2>Add new bookbuild message</h2>
      <TextField name='bookBuildingMessage' inputRef={register} isMultiline rows={3} placeholder='Type your message here...' label='' data-qa='ViewTermsheet-BookBuildingModal-Field' />
      <div>
        <ActionButtonAdmin label='Send' isBlue type='submit' data-qa='ViewTermsheet-BookBuildingModal-SendButton' />
        <TransparentDashboardFilterButton label='Cancel' handleClick={() => setIsOpen(false)} />
      </div>
    </ModalStyled>
  )
}

const ModalStyled = styled.form`
  h2 {
    padding: 0 0 14px 0;
    margin: 0;
    border-bottom: ${({ theme }) => `3px solid ${theme.grey_250 as string}`};
  }
  p {
    color: ${({ theme }) => theme.blue_700};
    font-size: 13px;
    margin: 23px 0;
  }
  & > div:last-of-type {
    margin-top: 23px;
    display: flex;
    & > div {
      margin-right: 15px;
    }
  }
`
