import React from 'react'
import PropTypes from 'prop-types'

import { FormLabel } from '../../FormLabel'
import { TooltipReusable } from '../../TooltipReusable'

export function Label ({ formTooltipText, label }): JSX.Element {
  return (
    <div>
      <FormLabel>{label}</FormLabel>
      {formTooltipText?.length > 0 && <TooltipReusable helperText={formTooltipText} />}
    </div>
  )
}

Label.propTypes = {
  label: PropTypes.string,
  formTooltipText: PropTypes.string
}
