import { DealOrTermsheetsDTOs } from 'services/deals'
import { TermsheetState } from 'constants/index'
import { PartitionedDealsFromAPI } from 'routes/DashboardPage/types'
import memoize from 'fast-memoize'
import { pathOr } from 'ramda'

const isDraft = (dealOrTermsheet: DealOrTermsheetsDTOs): boolean => Boolean(
  pathOr(false, ['draft'], dealOrTermsheet)
)
const isPriced = ({ state }: DealOrTermsheetsDTOs): boolean =>
  state === TermsheetState.PRICED
const isAnnounced = ({ state }: DealOrTermsheetsDTOs): boolean =>
  state === TermsheetState.ANNOUNCED
const isLive = ({ state }: DealOrTermsheetsDTOs): boolean =>
  state === TermsheetState.LIVE
const isWitdhDrawn = ({ state }: DealOrTermsheetsDTOs): boolean =>
  state === TermsheetState.WITHDRAWN || state === TermsheetState.POSTPONED

const getDraftDeals = <T extends DealOrTermsheetsDTOs>(dealsOrTermsheets: T[]): T[] => {
  return dealsOrTermsheets.filter(isDraft)
}
const getWithdrawn = <T extends DealOrTermsheetsDTOs>(dealsOrTermsheets: T[]): T[] => {
  return dealsOrTermsheets.filter(isWitdhDrawn)
}

export const getLiveDeals = memoize(<T extends DealOrTermsheetsDTOs>(dealsOrTermsheets: T[]): T[] => {
  return dealsOrTermsheets
    .filter(dealOrTermsheet => !isDraft(dealOrTermsheet))
    .filter(dealOrTermsheet => isLive(dealOrTermsheet))
})

const getPricedDeals = <T extends DealOrTermsheetsDTOs>(dealsOrTermsheets: T[]): T[] => {
  return dealsOrTermsheets
    .filter(dealOrTermsheet => !isDraft(dealOrTermsheet))
    .filter(dealOrTermsheet => isPriced(dealOrTermsheet))
}

export const getAnnouncedDeals = memoize(<T extends DealOrTermsheetsDTOs>(dealsOrTermsheets: T[]): T[] => {
  return dealsOrTermsheets
    .filter(dealOrTermsheet => !isDraft(dealOrTermsheet))
    .filter(dealOrTermsheet => isAnnounced(dealOrTermsheet))
})

export const partitionDeals = <T extends DealOrTermsheetsDTOs>(
  dealsOrTermsheets: T[]
): PartitionedDealsFromAPI<T> => {
  return {
    announced: getAnnouncedDeals(dealsOrTermsheets),
    live: getLiveDeals(dealsOrTermsheets),
    priced: getPricedDeals(dealsOrTermsheets),
    drafts: getDraftDeals(dealsOrTermsheets),
    withdrawn: getWithdrawn(dealsOrTermsheets)
  }
}
