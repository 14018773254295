import { CACHED_QUERY_PROPS } from 'constants/queries'
import { useQuery, UseQueryResult } from 'react-query'
import { getSyndicateBankers, SyndicateBankerDto } from 'services/organizations'

export function useGetSyndicateBankers (): UseQueryResult<SyndicateBankerDto[]> {
  return useQuery<SyndicateBankerDto[]>('get-syndicate-bankers', getSyndicateBankers, {
    ...CACHED_QUERY_PROPS,
    staleTime: 30 * 60 * 1000
  })
}
