/* eslint-disable @typescript-eslint/restrict-template-expressions */
import styled from '@emotion/styled'

interface PriceDealModalStyledProps {
  isGreenshoe?: boolean
  offeringType: string
}

export const PriceDealModalStyled = styled.div<PriceDealModalStyledProps>`
  padding: 0 7px;

  h2 {
    border-bottom: ${({ theme }) => `2px solid ${theme.grey_250}`};
    margin: 0px;
    padding: 0 0 17px;
    font-size: 18px;
    color: ${({ theme }) => theme.blue_900};
  }
  form {
    .price-deal-currency,
    .price-deal-shares,
    .price-deal-pickers {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    .price-deal-currency {
      margin-top: 10px;
      & > div:first-of-type {
        width: 27%;
      }
      & > div:last-of-type {
        width: 69%;
      }
    }
    .price-deal-shares {
      margin-top: 13px;
      & > div:last-of-type {
        width: ${({ offeringType }) => offeringType !== 'EQL' ? '100%' : '48%'};
      }
      & > div {
        width: 48%;
      }
    }
    .price-deal-pickers {
      margin-top: 10px;
      & > div {
        width: 48%;
      }
    }
    .price-deal-greenshoe {
      border-radius: 3px;
      margin-top: 25px;
      padding: ${({ isGreenshoe }) => (isGreenshoe ? '14px 18px 25px 18px' : '5px 18px')};
      background: ${({ theme }) => theme.grey_300};
      .price-deal-greenshoe-heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;
        font-weight: 600;
      }
      .price-deal-greenshoe-shares {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        & > div {
          width: 100%;
        }
        & > div:nth-of-type(1),
        & > div:nth-of-type(2) {
          width: 48%;
        }
      }
    }
  }
  .price-deal-buttons {
    .price-deal-buttons-line {
      margin: 26px auto;
      height: 3px;
      width: 70%;
      background: ${({ theme }) => theme.grey_250};
    }
  }
`

export const PriceDealDatePickerStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: ${({ theme }) => `1px solid ${theme.grey_350}`};
  border-radius: 4px;
  padding: 3px 14px;


  .MuiInputBase-input {
    padding: 10px 14px;
  }
  .MuiFormControl-root {
    flex-direction: row;
  }
  .MuiInput-underline::before {
    content: none;
  }
  .MuiSvgIcon-root {
    font-size: 1.5rem;
  }
`
