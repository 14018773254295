import React from 'react'
import { Button } from '@material-ui/core'
import styled from '@emotion/styled'

interface ActionButtonAdminProps {
  label: string
  handleClick?: any
  isBlue?: boolean

  [p: string]: any
}

export function ActionButtonAdmin ({ label, handleClick, isBlue, ...rest }: ActionButtonAdminProps): JSX.Element {
  return (
    <ActionButtonStyled isBlue={isBlue}>
      <Button onClick={handleClick} {...rest}>
        {label}
      </Button>
    </ActionButtonStyled>
  )
}

interface ActionButtonStyledProps {
  isBlue?: boolean
}

const ActionButtonStyled = styled.div<ActionButtonStyledProps>`
  .MuiButton-root {
    background: ${({ theme, isBlue }) => (isBlue === true ? theme.blue_900 : theme.grey_100)};
    color: ${({ theme, isBlue }) => (isBlue === true ? theme.white : theme.blue_900)};
  }

  .MuiButton-label {
    font-size: 12px;
    padding: 0 20px;
    text-transform: none;
  }
`
