import React from 'react'
import { LoadingSpinner } from 'components/LoadingSpinner'
import {
  AutocompleteMultiSelectHooked,
  AutocompleteMultiSelectHookedProps
} from 'containers/DealForm/Step2Form/components/AutocompleteMultiSelectHooked'
import { useGetStockExchanges } from 'lib/queries/useGetStockExchanges'
import { StockExchange } from 'services/stock-exchanges'
import ErrorLoading from 'components/ErrorLoading'

interface ListingExchangeProps extends Omit<AutocompleteMultiSelectHookedProps, 'optionsArray'|'defaultValue'>{
  defaultValue: StockExchange[]|null
}

export const ListingExchange = ({
  watch,
  errors,
  register,
  setValue,
  defaultValue,
  ...rest
}: ListingExchangeProps
): JSX.Element|null => {
  const { isLoading, isError, data } = useGetStockExchanges()

  if (isLoading) {
    return (<LoadingSpinner />)
  } else if (isError || data === undefined) {
    return (
      <ErrorLoading error='Could not load listing exchanges.' styles={{ margin: 'mt-4 mb-6' }} />
    )
  } else {
    // Override set value to send the whole item not just the name of the selected stock exchanges
    const customSetValue = (name: string, value: string[], options: unknown): void =>
      setValue(name,
        value.map(exchangeName => {
          return data.find(({ displayName }) => displayName === exchangeName)
        }, options))

    return (
      <AutocompleteMultiSelectHooked
        watch={watch}
        errors={errors}
        optionsArray={data?.map(({ displayName }) => displayName) ?? []}
        register={register}
        setValue={customSetValue}
        defaultValue={defaultValue?.map(({ displayName }) => displayName) ?? []}
        {...rest}
      />

    )
  }
}
