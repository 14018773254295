import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useGetBookrunners } from 'lib/queries/useGetBookrunners'
import { DateTime } from 'luxon'
import PropTypes from 'prop-types'
import React from 'react'
import { Controller, FormProvider } from 'react-hook-form'
import { DismissButton, PublishPriceButton } from '../../../../components/Buttons/TermsheetInformation/PriceDealModalButtons'
import { DatePickerComponent } from '../../../../components/DatePicker'
import { getErrorLine } from '../../../../components/ErrorLine/getErrorLine'
import { FormLabel } from '../../../../components/FormLabel'
import {
  getGreenshoeConfig,
  getGreenshoeTimetableConfig,
  getPriceDealConfigShares,
  getPriceDealConfigValue,
  getPriceDealPriceRange,
  getPriceDealTimetableConfig,
  getFinalPricedDataConfig
} from '../../../../config/formConfigs/configs/priceDealConfig'
import { stabAgent } from '../../../../config/formTypes/inputFields'
import { useDealFormContext } from '../../../../containers/DealForm/context'
import { FieldSelector } from '../../../../containers/DealForm/FieldSelector'
import { PriceDealDatePickerStyled, PriceDealModalStyled } from './styles'
import { usePriceDealModalForm } from './usePriceDealModalForm'

export function PriceDealModal ({ setIsModalOpen }): JSX.Element {
  const { setFormValues, formValues } = useDealFormContext()
  const { formMethods, onSubmit } = usePriceDealModalForm()

  const [isGreenshoe, setIsGreenshoe] = React.useState(false)

  const primarySecodnary = formMethods.watch('primarySecondaryString')
  const priceDealQuestions = getPriceDealConfigShares(primarySecodnary)

  const datePickers = getPriceDealTimetableConfig()

  const renderField = (field: any): JSX.Element => <FieldSelector key={field.name} {...field} />

  const datePicker = (picker: any): JSX.Element => (
    <div key={picker.name}>
      <FormLabel>{picker.label}</FormLabel>
      <Controller
        as={
          <PriceDealDatePickerStyled>
            <DatePickerComponent
              handleDateChange={(value) => {
                const formattedValue = DateTime.fromHTTP(value).setZone('UTC', { keepLocalTime: true })
                  .set({ hour: 12, minute: 0, second: 0 }).toHTTP()
                formMethods.setValue(picker.name, formattedValue, {
                  shouldValidate: true
                })
              }}
              selectedDate={formMethods.watch(picker.name)}
            />
          </PriceDealDatePickerStyled>
        }
        control={formMethods.control}
        name={picker.name}
        rules={{ required: true }}
        defaultValue=''
      />
      {getErrorLine(formMethods.errors, picker.name, picker.label)}
    </div>
  )

  const bookrunnersQuery = useGetBookrunners()
  const bookrunnerNames = (bookrunnersQuery.data ?? []).map(br => br.legalPublishingName)

  const greenshoeConfig = getGreenshoeConfig().map(field => {
    if (field.name === stabAgent.name) {
      field.optionsArray = bookrunnerNames
    }
    return field
  })
  const greenshoeFields = greenshoeConfig.map(renderField)
  const finalPricedDataFields = getFinalPricedDataConfig().map(renderField)
  const offeringType = formValues.offeringType
  const priceDealShares = offeringType === 'EQL' ? finalPricedDataFields : priceDealQuestions.map(renderField)
  return (
    <PriceDealModalStyled offeringType={offeringType} isGreenshoe={isGreenshoe}>
      <h2>Final offer details</h2>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit((data) => onSubmit(data))}>
          <div className='price-deal-shares'>{priceDealShares}</div>

          <div className='price-deal-currency'>{getPriceDealConfigValue().map(renderField)}</div>

          <div className='price-deal-pickers'>
            {offeringType !== 'EQL' ? getPriceDealPriceRange().map(renderField) : null}
            {datePickers.map(datePicker)}
          </div>

          <div className='price-deal-greenshoe'>
            <div className='price-deal-greenshoe-heading' onClick={() => setIsGreenshoe(!isGreenshoe)}>
              <span>Greenshoe details (optional)</span>
              <span>{isGreenshoe ? <ExpandLessIcon /> : <ExpandMoreIcon />}</span>
            </div>
            {isGreenshoe && (
              <>
                <div className='price-deal-greenshoe-shares'>{greenshoeFields}</div>
                {getGreenshoeTimetableConfig().map(datePicker)}
              </>
            )}
          </div>
          <div className='price-deal-buttons'>
            <div className='price-deal-buttons-line' />
            <DismissButton
              onClick={() => {
                setFormValues({})
                setIsModalOpen(false)
              }}
            />
            <PublishPriceButton type='submit' title='Publish as priced' />
          </div>
        </form>
      </FormProvider>
    </PriceDealModalStyled>
  )
}

PriceDealModal.propTypes = {
  setIsModalOpen: PropTypes.func.isRequired
}
