import { DashboardRegion } from 'constants/index'
import { makeAutoObservable } from 'mobx'
import { RootStore } from 'store/rootStore'
import { StockExchange } from 'services/stock-exchanges'
import { ClientConfigDTO } from 'interfaces/Config'
import { FeatureFlagDto } from 'services/users'
import { BUNDLE_MODE } from 'store/types'
import { equals } from 'ramda'
import { Bookrunner, Organization } from 'services/organizations'
export class CommonStore {
  private _bundleMode: BUNDLE_MODE = BUNDLE_MODE.ECM

  private _bookrunners: Bookrunner[] = []
  private readonly _rootStore: RootStore
  private _ff: {
    initialized: boolean
    loaded: boolean
    global: FeatureFlagDto[]
    userSpecific: FeatureFlagDto[]
  } = {
    initialized: false,
    loaded: false,
    global: [],
    userSpecific: []
  }

  private _stockExchanges: StockExchange[] = []
  private _dashboardRegion: DashboardRegion = DashboardRegion.ALL
  private _globalSpinner: boolean = false
  private _config: ClientConfigDTO | null = null
  private _bankers: Organization[] = []
  private _bankersById: {[key: string]: Organization} = {}
  private _bankersByName: {[key: string]: Organization} = {}

  titleState: string = ''

  constructor (rootStore: RootStore) {
    this._rootStore = rootStore
    makeAutoObservable(this)
  }

  set bookrunners (value: Bookrunner[]) {
    this._bookrunners = value
  }

  get bookrunners (): Bookrunner[] {
    return this._bookrunners
  }

  set bundleMode (value: BUNDLE_MODE) {
    this._bundleMode = value
  }

  get bundleMode (): BUNDLE_MODE {
    return this._bundleMode
  }

  set globalFf (value: FeatureFlagDto[]) {
    if (!equals(this._ff.global, value)) {
      this._ff.initialized = true
      this._ff.global = value
    }
  }

  get globalFf (): FeatureFlagDto[] {
    return this._ff.global
  }

  set userSpecificFf (value: FeatureFlagDto[]) {
    if (!equals(this._ff.userSpecific, value)) {
      this._ff.initialized = true
      this._ff.userSpecific = value
    }
  }

  get userSpecificFf (): FeatureFlagDto[] {
    return this._ff.userSpecific
  }

  get ff (): FeatureFlagDto[] {
    return [...this._ff.global, ...this._ff.userSpecific]
  }

  set config (value: ClientConfigDTO | null) {
    if (!equals(this._config, value)) {
      this._config = value
    }
  }

  get config (): ClientConfigDTO | null {
    return this._config
  }

  set globalSpinner (value: boolean) {
    if (!equals(this._globalSpinner, value)) {
      this._globalSpinner = value
    }
  }

  get globalSpinner (): boolean {
    return this._globalSpinner
  }

  set dashboardRegion (value: DashboardRegion) {
    if (!equals(this._dashboardRegion, value)) {
      this._dashboardRegion = value
    }
  }

  get dashboardRegion (): DashboardRegion {
    return this._dashboardRegion
  }

  set stockExchanges (value: StockExchange[]) {
    if (!equals(this._stockExchanges, value)) {
      this._stockExchanges = value
    }
  }

  get stockExchanges (): StockExchange[] {
    return this._stockExchanges
  }

  set isFFInitialized (value: boolean) {
    if (!equals(this._ff.initialized, value)) {
      this._ff.initialized = value
    }
  }

  get isFFInitialized (): boolean {
    return this._ff.initialized
  }

  set isFFLoaded (value: boolean) {
    this._ff.loaded = value
  }

  get isFFLoaded (): boolean {
    return this._ff.loaded
  }

  setTitle (newTitle: string): void {
    this.titleState = newTitle
  }

  isFeatureFlagEnabled (featureFlagName: string): boolean {
    return (this.ff || [])
      .find(featureFlag => featureFlag.name === featureFlagName)?.enabled ?? false
  }

  set bankers (value: Organization[]) {
    this._bankers = value
    this._bankersById = value.reduce((acc, item) => ({
      ...acc,
      [item.id]: item
    }), {})
    this._bankersByName = value.reduce((acc, item) => ({
      ...acc,
      [item.name]: item
    }), {})
  }

  get bankers (): Organization[] {
    return this._bankers
  }

  get bankersById (): {[key: string]: Organization} {
    return this._bankersById
  }

  get bankersByName (): {[key: string]: Organization} {
    return this._bankersByName
  }
}
