/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React from 'react'
import PropTypes from 'prop-types'
import Linkify from 'linkify-react'
import { linkifyDefaults } from '../../../../../config/linkifyDefaults'
import styled from '@emotion/styled'
import punycode from 'punycode/'
const componentDecorator = (href, text, key): JSX.Element => (
  <a href={href} key={key}>
    {punycode.toASCII(text)}
  </a>
)

export function FullWidthRow ({ termsheetObject, label, name, isChanged }): JSX.Element {
  return (
    // eslint-disable-next-line no-prototype-builtins
    termsheetObject.hasOwnProperty(name) &&
    termsheetObject[name] !== '' && (
      <FullWidthRowStyled isChanged={isChanged}>
        <p>{label}</p>
        <Linkify
          options={linkifyDefaults}
          {...componentDecorator}
        >
          <p>{termsheetObject[name]}</p>
        </Linkify>
      </FullWidthRowStyled>
    )
  )
}

FullWidthRow.propTypes = {
  termsheetObject: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isChanged: PropTypes.bool
}

interface FullWidthRowStyledProps {
  isChanged: boolean
}

const FullWidthRowStyled = styled.div<FullWidthRowStyledProps>`
  background: ${({ isChanged, theme }) => isChanged && theme.yellow_100};
  font-size: 11px;
  margin: 10px 0;
  padding: 10px 5px;
  white-space: pre-wrap;
  grid-column: 1 / 3;
  column-span: all;
  border-top: 1px solid rgba(216, 216, 216, 0.5);
  & > p:first-of-type {
    font-weight: bold;
    margin: 3px 0;
  }
`
