import { useCallback, useState } from 'react'
import { useTermsheetContext } from 'containers/TermsheetInformation/context'
import { useTermsheetModalContext } from 'contexts/modal-context/TermsheetModals/context'
import { useProfileHook } from 'contexts/user-auth-context'
import { useToast } from 'lib'
import { updateBookbuildMessage } from 'services/bookbuild-messages'
import { ApplyArgs } from 'components/Termsheet/View/BookbuildingMessages/types'
import { getBookBuildDataByType } from 'components/Termsheet/View/BookbuildingMessages/utils'

export function useBookbuildingSubmit (): {
  applyBookBuildMessages: (data: ApplyArgs) => Promise<void>
  isLoadingSpinner: boolean
} {
  const [profile] = useProfileHook()
  const { updateTermsheetView, selectedTermsheet, termsheets } = useTermsheetContext()
  const { dealId, termsheetId } = selectedTermsheet
  const { setIsOpen } = useTermsheetModalContext()
  const { toastify } = useToast()

  const [isLoadingSpinner, setIsLoadingSpinner] = useState(false)

  const applyBookBuildMessages = useCallback(async ({
    data,
    type,
    shouldSendFeedNotifications,
    shouldSendEmailNotifications
  }: ApplyArgs) => {
    const { bookBuildingMessages, successMsg, errorMsg, newMsg } = getBookBuildDataByType({
      type,
      data,
      selectedTermsheet,
      profile,
      termsheets
    })
    const params = {
      dealId: dealId,
      termsheetId: termsheetId,
      bookBuildingMessages: bookBuildingMessages
    }

    setIsLoadingSpinner(true)
    try {
      await toastify(
        updateBookbuildMessage(params.termsheetId, {
          params,
          type,
          newMsg,
          sendFeedNotifications: shouldSendFeedNotifications,
          sendEmailNotifications: shouldSendEmailNotifications
        }),
        successMsg, errorMsg
      )
      updateTermsheetView(params)
    } finally {
      setIsLoadingSpinner(false)
    }

    setIsOpen(false)
  }, [dealId, profile, selectedTermsheet, setIsOpen, termsheetId, termsheets, toastify, updateTermsheetView])

  return { isLoadingSpinner, applyBookBuildMessages }
}
