import { ppLogo } from 'assets'
import { PRIMARY_PORTAL_ORGANIZATION_ID } from 'constants/organizations'
import { Organization } from 'services/organizations'
import { TermsheetLogo } from './types'

const ppLogoConfig = { logo: ppLogo.toString(), size: '100%' }

export const getLogoProps = (organization: Organization|undefined|null, publishingAccountOrganizationId: any): TermsheetLogo => {
  if (organization === null || [undefined, PRIMARY_PORTAL_ORGANIZATION_ID].includes(publishingAccountOrganizationId)) {
    return ppLogoConfig
  }
  return {
    logo: organization?.logo?.url ?? '',
    size: organization?.logo?.size ?? ''
  }
}
