import { useStoreActions, useStoreState } from 'easy-peasy'
import { useCallback, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { findDealIdByTermsheetId } from 'services/deals'
import { useProfileHook } from 'contexts/user-auth-context'
import { Deal, DealsStoreModel } from 'store/deals'
import { StoreModel } from 'store/store'
import { useFeatureFlags } from 'lib/useFeatureFlags'
import { FEATURES } from 'constants/features'
import { useStores } from 'store/rootStore'

enum SearchType {
  DEAL = 'deal',
  TERMSHEET = 'termsheet',
  DEAL_AND_TERMSHEET = 'dealAndTermsheet'
}

export const useDealTermsheets = (): { termsheets: any[], setTermsheets: ((value: any[]) => void) } => {
  const { pathname } = useLocation()
  const { isFeatureFlagEnabled } = useFeatureFlags()
  const history = useHistory()
  const { tempTermsheetsStore } = useStores()

  const [termsheets, setTermsheets] = useState<any[]>([])
  const [profile] = useProfileHook()
  const { accountType } = profile
  const accountOrganizationId = profile.accountOrganizationId

  let [, secondUrlPart, dealId, termsheetId] = pathname.split('/')
  let searchType = SearchType.DEAL
  if (pathname.includes('deals') && pathname.includes('termsheets')) {
    const [, , dealIdNew, , termsheetIdNew] = pathname.split('/')
    dealId = dealIdNew
    termsheetId = termsheetIdNew
    searchType = SearchType.DEAL_AND_TERMSHEET
  } else if (pathname.split('/')[1] === 'termsheets') { // what? You know how to do it better? Go ahead!
    const [, , termsheetIdNew] = pathname.split('/')
    termsheetId = termsheetIdNew
    searchType = SearchType.TERMSHEET
  }
  const { fetchFromBackend } = useStoreActions<DealsStoreModel>((actions) => actions.deals)
  const { getDeal } = useStoreState<StoreModel>((state) => state.deals)

  const fetchData = useCallback(async (searchType) => {
    if (isFeatureFlagEnabled(FEATURES.enableTsv2ViewPage)) {
      return
    }
    // check secondUrlPart is super dirty temporary fix for fetchData happening on other pages
    if (
      !['termsheet', 'deals', 'termsheets'].includes(secondUrlPart) ||
        [undefined, null, '', 'add-new'].includes(dealId) ||
        [undefined, null, ''].includes(accountOrganizationId)
    ) {
      return
    }
    let deal: Deal

    if (searchType === SearchType.DEAL_AND_TERMSHEET) {
      deal = await fetchFromBackend({ dealId, accountOrganizationId, accountType, termsheetId })
    } else if (searchType === SearchType.TERMSHEET) {
      deal = await fetchFromBackend({ dealId: undefined, accountOrganizationId, accountType, termsheetId, termsheet: tempTermsheetsStore.tempTermsheet })
    } else {
      deal = await fetchFromBackend({ dealId, accountOrganizationId, accountType })
    }

    const foundTermsheetsCount = deal?.termsheets?.length ?? 0
    if (foundTermsheetsCount === 0) {
      const foundDealId = await findDealIdByTermsheetId(termsheetId)
      if (foundDealId !== null) {
        return history.push(`/termsheet/${foundDealId}/${String(termsheetId)}`)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealId, accountOrganizationId, fetchFromBackend, searchType])

  useEffect(() => {
    void fetchData(searchType)
  }, [dealId, accountOrganizationId, pathname, accountType, fetchData, searchType])

  useEffect(() => {
    const storedDeal: Deal | undefined = getDeal(dealId)
    if (storedDeal !== undefined) {
      setTermsheets(storedDeal.termsheets)
    } else {
      setTermsheets([])
    }
  }, [getDeal, dealId])

  return {
    termsheets,
    setTermsheets
  }
}
