import React from 'react'
import { TextField as TextFieldMui } from '@material-ui/core'
import { Label } from './TextFieldLabel'
import { getErrorLine } from '../../ErrorLine/getErrorLine'
import { StyledTextField } from './StyledTextField'
import { numberWithCommas } from '../../../utils/numberWithCommas'

interface TextFieldProps {
  name?: string
  label?: string
  value?: string
  defaultValue?: string
  inputRef?: () => any
  onChange?: any
  autoComplete?: string
  rows?: number
  type?: string
  placeholder?: string
  size?: 'small' | 'medium'
  isMultiline?: boolean
  disabled?: boolean
  fullWidth?: boolean
  hasCommas?: boolean
  formTooltipText?: string
  errors?: Record<string, Record<string, string>>
  'data-qa'?: string
}

export function TextField ({
  name,
  label,
  value,
  defaultValue,
  inputRef,
  onChange,
  autoComplete,
  rows,
  type,
  size = 'small',
  fullWidth = true,
  isMultiline = true,
  disabled,
  hasCommas,
  placeholder,
  formTooltipText,
  errors,
  'data-qa': dataQA = 'EditForm-InputField'
}: TextFieldProps): JSX.Element {
  return (
    <StyledTextField>
      <Label formTooltipText={formTooltipText} label={label} />
      <TextFieldMui
        name={name}
        variant='outlined'
        disabled={disabled}
        value={value}
        defaultValue={defaultValue}
        placeholder={placeholder}
        inputRef={inputRef}
        onChange={onChange}
        autoComplete={autoComplete}
        rows={rows}
        type={type}
        size={size}
        multiline={isMultiline}
        fullWidth={fullWidth}
        onKeyUp={(e: any) => {
          e.target.value = hasCommas === true ? numberWithCommas(e.target.value) : e.target.value
        }}
        inputProps={{ 'data-qa': `${dataQA}` }}
      />
      {getErrorLine(errors, name, label)}
    </StyledTextField>
  )
}
