import { makeAutoObservable } from 'mobx'
import { RootStore } from 'store/rootStore'
import { ConnectionRecord, EnabledStatusType, OrgItem } from 'routes/ConnectionsPage/types'
import { difference, pick, without } from 'ramda'
import { ConnectionStatus } from 'routes/ConnectionsPage/api'

export enum SelectedCheckBoxType {
  OTHER_BANKS,
  SENDER_BANK
}
export class ConnectionsStore {
  private connectionsInstance: ConnectionRecord[] = []
  rootStore: RootStore
  checkboxOrgs: Map<SelectedCheckBoxType, OrgItem[]>
  enabledStatus: EnabledStatusType = null

  constructor (rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)
    this.checkboxOrgs = new Map()
  }

  set connections (value: ConnectionRecord[] | undefined) {
    this.connectionsInstance = value ?? []
  }

  get connections (): ConnectionRecord[] {
    return this.connectionsInstance
  }

  selectedCheckboxAmount (checkboxType: SelectedCheckBoxType): number {
    const checkBoxes = this.checkboxOrgs.get(checkboxType) ?? []
    return checkBoxes.length
  }

  isAllSelectionEnabled = (checkboxType: SelectedCheckBoxType): boolean => {
    const selectedAmount = this.selectedCheckboxAmount(checkboxType)
    return Boolean(selectedAmount)
  }

  setCheckboxOrgs = (items: OrgItem[], checkboxType: SelectedCheckBoxType): void => {
    this.checkboxOrgs.set(checkboxType, items)
  }

  ifCheckboxExist = (record: Partial<ConnectionRecord>, checkboxType: SelectedCheckBoxType): boolean => {
    const item = pick(['organizationName', 'investorId', 'bankId', 'isCentralisedContactExists'], record)
    const checkBoxes = this.checkboxOrgs.get(checkboxType) ?? []
    return checkBoxes.length > 0 && difference([item], checkBoxes).length === 0
  }

  removeCheckboxOrg = (item: OrgItem, checkboxType: SelectedCheckBoxType): void => {
    const checkBoxes = this.checkboxOrgs.get(checkboxType) ?? []
    const items = without([item], checkBoxes)
    this.setCheckboxOrgs(items, checkboxType)
  }

  addCheckboxOrg = (item: OrgItem, checkboxType: SelectedCheckBoxType): void => {
    const checkBoxes = this.checkboxOrgs.get(checkboxType) ?? []
    const items = [...checkBoxes, item]
    this.setCheckboxOrgs(items, checkboxType)
  }

  setEnabledStatus = (value: ConnectionStatus | null): void => {
    this.enabledStatus = value
  }

  resetFilters = (checkboxType: SelectedCheckBoxType): void => {
    this.enabledStatus = null
    this.setCheckboxOrgs([], checkboxType)
  }
}
