import styled from '@emotion/styled'
import { useGetBookrunners } from 'lib/queries/useGetBookrunners'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { AutocompleteCustom } from '../../../components/AutocompleteCustom'
import { LabelDropdownNoHook } from '../../../components/Dropdowns/NotHookedDropdowns/LabelDropdownNoHook'
import { getErrorLine } from '../../../components/ErrorLine/getErrorLine'
import { TextField } from '../../../components/TextField/MainTextField'
import { publishingBankRole } from '../../../config/formTypes/inputFields'

const otherSyndicateBanks = 'otherSyndicateBanks'

export function OtherSyndicateBanks ({ setValue, watch, errors, register }: any): JSX.Element {
  const watchSyndicates: any[] = watch(otherSyndicateBanks)

  const [otherSyndicatesState, setOtherSyndicatesState] = useState<any[]>(watchSyndicates ?? [])
  const [bankSelected, setBankSelected] = useState<any>('')
  const [syndicateBanksInput, setSyndicateBanksInput] = useState<any>({
    item: ''
  })
  const [bankRoleDropdownsState, setBankRoleDropdownsState] = useState<any>('')
  const [otherBankRoleState, setOtherBankRoleState] = useState<any>('')

  const handleBankSelect = (e): void => {
    setBankSelected(e.target.name)
    setSyndicateBanksInput({ item: e.target.name })
  }

  const handleBankRoleDropdowns = (title, item): void => {
    setBankRoleDropdownsState(item)
  }

  const handleOtherBankRole = (e): void => {
    setOtherBankRoleState(e.target.value)
  }

  const handleInput = (e): void => {
    setSyndicateBanksInput({ [e.target.name]: e.target.value })
    e.target.value !== bankSelected && setBankSelected('')
  }

  const addOtherSyndicates = useCallback(
    (name, role, id) => {
      if (bankSelected.length > 0 && bankRoleDropdownsState.length > 0) {
        setOtherSyndicatesState([...otherSyndicatesState, { name, role, id }])
        setBankSelected('')
        setBankRoleDropdownsState('')
        setOtherBankRoleState('')
        setSyndicateBanksInput({ item: '' })
      }
    },
    [bankSelected.length, bankRoleDropdownsState.length, otherSyndicatesState]
  )

  const deleteOtherSyndicates = (id): void => {
    const filteredSyndicateBanks = otherSyndicatesState.filter((syndicate) => syndicate.id !== id)
    setOtherSyndicatesState(filteredSyndicateBanks)
  }

  useEffect(() => {
    register({ name: otherSyndicateBanks })
    if (otherSyndicatesState !== undefined) {
      setValue(otherSyndicateBanks, otherSyndicatesState, {
        shouldValidate: true,
        shouldDirty: true
      })
    }
    addOtherSyndicates(bankSelected, bankRoleDropdownsState === 'Other' ? otherBankRoleState : bankRoleDropdownsState, uuidv4())
  }, [register, setValue, addOtherSyndicates, otherSyndicatesState, bankSelected, bankRoleDropdownsState, otherBankRoleState])

  const isSelectedOtherRole = useMemo(() => {
    return bankRoleDropdownsState === 'Other'
  }, [bankRoleDropdownsState])

  const bookrunnersQuery = useGetBookrunners()
  const legalPublishingNames = (bookrunnersQuery.data ?? []).map(br => br.legalPublishingName)

  return (
    <>
      <OtherSyndicateBanksFormStyled>
        <AutocompleteCustom
          array={legalPublishingNames}
          handleSelect={handleBankSelect}
          handleInput={handleInput}
          inputValue={syndicateBanksInput}
          selected={bankSelected}
          title='Add other syndicate bank'
        />
        <DropdownStyled>
          <LabelDropdownNoHook
            optionsArray={publishingBankRole.optionsArray.map((ROLE) => ({
              // The required input format for label dropdown no hook
              label: ROLE,
              value: ROLE
            }))}
            title='Bank role'
            name='publishingBankRole'
            value={bankRoleDropdownsState}
            handleChange={handleBankRoleDropdowns}
          />
        </DropdownStyled>

        {isSelectedOtherRole && <TextField label='Other Bank Role' name='otherPublishingBankRole' onChange={handleOtherBankRole} value={otherBankRoleState} />}

        {otherSyndicatesState !== undefined &&
          Object.values(otherSyndicatesState).map((item: any, index) => (
            <OtherSyndicateBanksSelected key={index}>
              <span>
                {item.name} | {item.role}
              </span>
              <span onClick={() => deleteOtherSyndicates(item.id)}>X</span>
            </OtherSyndicateBanksSelected>
          ))}
      </OtherSyndicateBanksFormStyled>
      {getErrorLine(errors, otherSyndicateBanks)}
    </>
  )
}

OtherSyndicateBanks.propTypes = {
  setValue: PropTypes.func.isRequired,
  register: PropTypes.any,
  watch: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired
}

const OtherSyndicateBanksFormStyled = styled.div`
  position: relative;
  margin-top: 16px;
  padding: 6px 18px 20px 12px;
  border-radius: 4px;
  border: 2px solid ${({ theme }) => theme.grey_250};
`
const DropdownStyled = styled.div`
  display: flex;
  justify-content: space-between;
`

const OtherSyndicateBanksSelected = styled.div`
  text-align: center;
  font-weight: 600;
  display: inline-block;
  background: #d8d8d8;
  border-radius: 30px;
  margin: 10px 4px 0 6px;
  padding: 7px 15px;
  span {
    padding: 0 4px;
  }
  span:last-of-type {
    margin-left: 5px;
    cursor: pointer;
    &:hover {
      color: rgba(0, 0, 0, 0.3);
    }
  }
`
