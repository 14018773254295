import styled from '@emotion/styled'
import CancelIcon from '@material-ui/icons/Cancel'
import ErrorIcon from '@material-ui/icons/Error'
import partition from 'lodash.partition'
import PropTypes from 'prop-types'
import React from 'react'
import { BANK_ROLE, BANK_ROLE_ABBREVIATIONS, BANK_ROLES, TermsheetRegion } from '../../constants'

/* eslint-disable @typescript-eslint/strict-boolean-expressions */

const sortOrder = [...BANK_ROLES].reverse()
const sortSyndicateEntities = (entities: any): any => {
  // if entity.role is undefined, it should be sorted to the end of the list
  return entities.sort((a, b) => {
    const indexOfA = Math.max(sortOrder.indexOf(a.role), 0)
    const indexOfB = Math.max(sortOrder.indexOf(b.role), 0)
    return indexOfB - indexOfA
  })
}

/**
 * Entities should be listed in this order:
 * 1) Publishing Bank
 * 2) LLB
 * 3) All remaining entites (in the usual priority order)
 */
export function UnderwritingSyndicateModal ({ setIsModal, array, publishingBank, region }): JSX.Element {
  const [llbSyndicateEntity, remainingSyndicateEntities] = partition(array, (entity) => entity.role === BANK_ROLE.LLB)
  const [sponsorEntity, otherSyndicateEntities] =
  partition([...remainingSyndicateEntities], (entity) => entity.role === BANK_ROLE.SPONSOR)

  const syndicateRoles = (region === TermsheetRegion.APAC)
    ? [publishingBank, ...sponsorEntity, ...llbSyndicateEntity, ...sortSyndicateEntities(otherSyndicateEntities)].filter(Boolean)
    : [publishingBank, ...llbSyndicateEntity, ...sortSyndicateEntities(remainingSyndicateEntities)].filter(Boolean)

  return (
    <UnderwritingSyndicatesStyled>
      <HeaderStyled>
        <div>
          <h3>Underwriters</h3>
          <span>Details shown are merged contact info</span>
          <ErrorIcon />
        </div>
        <CancelIcon onClick={() => setIsModal(false)} />
      </HeaderStyled>

      <table>
        <thead>
          <tr>
            <th>Bank</th>
            <th>Role</th>
            <th>Underwriting split</th>
            <th>Contact</th>
            <th>Telephone</th>
            <th>Email</th>
          </tr>
        </thead>
        <tbody>
          {syndicateRoles.map((syndicate, index) => (
            <tr key={index}>
              <td>{syndicate.name}</td>
              <SyndicateRole role={syndicate.role}>
                <span>{BANK_ROLE_ABBREVIATIONS[syndicate.role] ?? syndicate.role}</span>
              </SyndicateRole>
              <td>{syndicate.underwritingSplit || '-'}</td>
              <td>{syndicate.contact || '-'}</td>
              <td>{syndicate.telephone || '-'}</td>
              <td>{syndicate.email || '-'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </UnderwritingSyndicatesStyled>
  )
}

UnderwritingSyndicateModal.propTypes = {
  setIsModal: PropTypes.func.isRequired,
  array: PropTypes.array.isRequired,
  publishingBank: PropTypes.object.isRequired,
  region: PropTypes.string.isRequired
}

const roleBackgroundColors = {
  [BANK_ROLE.LLB]: '#F8E4E1',
  [BANK_ROLE.SGC]: '#F8E4E1',
  [BANK_ROLE.JGC]: '#F8E4E1',
  [BANK_ROLE.SBR]: '#E4EFFC',
  [BANK_ROLE.JBR]: '#E4EFFC',
  [BANK_ROLE.CBR]: '#E4EFFC',
  [BANK_ROLE.LM]: '#DBE5EA',
  [BANK_ROLE.JLM]: '#DBE5EA',
  [BANK_ROLE.CLM]: '#DBE5EA',
  [BANK_ROLE.CM]: '#DBE5EA',
  [BANK_ROLE.SPONSOR]: '#DBE5EA'
}

interface SyndicateRoleProps {
  role: string
}

const SyndicateRole = styled.td<SyndicateRoleProps>`
  span {
    padding: 6px 10px;
    border-radius: 4px;
    background: ${({ role }) => roleBackgroundColors[role]};
  }
`

const HeaderStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  h3 {
    margin: 0;
    margin-right: 50px;
    font-size: 20px;
  }
  span {
    font-weight: 300;
    color: #6d6d6d;
    margin-right: 6px;
  }
  & > div {
    display: flex;
    align-items: center;
    /* error svg */
    svg {
      font-size: 1rem;
      color: black;
    }
  }
  /* close modal svg */
  & > svg {
    font-size: 1.5rem;
    cursor: pointer;
    color: ${({ theme }) => theme.blue_700};
    opacity: 0.8;
    &:hover {
      opacity: 1;
    }
  }
`

const UnderwritingSyndicatesStyled = styled.div`
  table {
    border-collapse: collapse;
    width: 100%;
  }
  th,
  td {
    text-align: center;
    min-width: 120px;
    padding: 3px 15px;
    max-width: 220px;
    word-wrap: break-word;
    &:last-of-type {
      word-break: normal;
    }
    @media (max-width: 1100px) {
      min-width: 100px;
    }
  }

  thead th {
    font-weight: 600;
    color: ${({ theme }) => theme.blue_700};
  }
  tbody tr {
    font-weight: 500;
    border-bottom: 1px solid #d0d0d0;
    color: black;
  }
  tbody tr:last-of-type {
    border-bottom: none;
  }
  thead th:first-of-type,
  tbody td:first-of-type {
    text-align: left;
    padding: 10px 30px 10px 0;
  }
`
