import moment from 'moment'
import { DateTime } from 'luxon'
import { DATE_FORMAT_STANDARD } from 'constants/dateFormats'

export const formatDateString = (date: any, format?: string): string | undefined => {
  const momentDate = moment(new Date(date))
  return momentDate.isValid() ? momentDate.format(format ?? DATE_FORMAT_STANDARD) : 'TBA'
}

export function isDateInThePast (inputDate: any): boolean {
  const oldDate = new Date(inputDate)
  const newDate = moment()
  return moment(oldDate).isBefore(newDate)
}

export function isYoungerThan (inputDate: any, days: number): boolean {
  if (inputDate !== undefined) {
    const dateToCheck = new Date(inputDate * 1)
    const past = moment().clone().subtract(days, 'days').startOf('day')
    return !moment(dateToCheck).isBefore(past)
  }
  return false
}

export const parseAndFormatDateV2 = (date: string | null | undefined, format?: string): string => {
  const parsedDate = DateTime.fromFormat(date ?? '', 'yyyy-LL-dd')
  return parsedDate.isValid ? parsedDate.toFormat(format ?? 'dd-MMM-yyyy') : 'TBA'
}

export const parseFromIsoAndFormatDateV2 = (date: string | null | undefined, format?: string): string => {
  const parsedDate = DateTime.fromISO(date ?? '')
  return parsedDate.isValid ? parsedDate.toFormat(format ?? 'dd-MMM-yyyy') : 'TBA'
}

export const parseAndFormatDateFromTimestamp = (timestamp: number, format?: string): string => {
  const parsedDate = DateTime.fromMillis(timestamp)
  return parsedDate.isValid ? parsedDate.toFormat(format ?? 'dd-MMM-yyyy') : 'TBA'
}

export const isSecondAfterFirstDate = (first: string, second: string): boolean => {
  const d1 = DateTime.fromISO(first)
  const d2 = DateTime.fromISO(second)
  if ((d1.isValid && !d2.isValid) || (!d2.isValid && !d1.isValid)) {
    return false
  }
  if (d2.isValid && !d1.isValid) {
    return true
  }
  return d2 > d1
}
