import { API } from 'services/aws-api'
import { BANK_ROLE, OFFERING_TYPE, TermsheetRegion, TermsheetState } from 'constants/index'
import { StockExchange } from 'services/stock-exchanges'
import { ITermsheetV2Entity, PreviousDealDTO } from 'store/types'
interface GetByTermsheetIdType {
  dealId: string | null
}

export async function findDealIdByTermsheetId (termsheetId: string): Promise<string | null> {
  const result = await API<GetByTermsheetIdType>().get({
    path: `/v2/deals/find-deal-idv2-by-termsheet-id/${termsheetId}`
  })
  return result.dealId ?? null
}

export interface DealOrTermsheetDTO {
  termsheetId?: string
  dealId: string
  draft?: boolean
  offeringType: OFFERING_TYPE
  distribution: string
  pricingDate: string | null
  offeringSharePriceRange: string | null
  subscriptionRatio: string | null
  closeOfBookbuildingDate: string | null
  endOfSubscriptionPeriodDate: string | null
  countryCodes?: string[]
  issuerName: string
  publishingBankName?: string
  publishingBanksNames?: string[]
  canViewRelatedTermsheet?: boolean
  region: TermsheetRegion
  state: TermsheetState
  isOrderExists: boolean
  dealV2Id: string
}
export interface PublishingBank {
  organizationId: string
  publishingBankName: string
  publishingBankRole: BANK_ROLE
}

export interface PublishingBankWithShortName extends PublishingBank {
  shortName: string
}

export interface OfferingSize {
  value?: number
  currency?: string
}
export interface DashboardDealViewDTO {
  PK: string
  draft?: boolean
  issuerName: string
  isATermsheetViewableOnThisDeal: boolean
  dealV2Id: string
  offeringType: OFFERING_TYPE
  state: TermsheetState
  createdAt: string
  updatedAt: string
  pricingDate?: string
  dealAnnouncementDate?: string
  closeOfBookbuildingDate?: string
  endOfSubscriptionPeriodDate?: string
  impliedDealSizeUSD?: number
  region: TermsheetRegion
  listingExchange?: StockExchange[]
  publishingBankNames: PublishingBank[]
  termsheetIds: string[]
  orderId?: string
  orderStatus?: string
  countryCodes: string[]
  mostSeniorPublishingBankNames?: PublishingBankWithShortName[]
  offeringSharePriceRangeMin?: number
  offeringSharePriceRangeMax?: number
  finalPriceLocalCcy?: number
  offeringCurrencyType?: string
  subscriptionPrice?: number
  issuePriceHigh?: number
  issuePriceLow?: number
  offeringSizeValue?: OfferingSize
}

export interface DashboardDealViewDTOWithCountryCode extends DashboardDealViewDTO {
  countryCodes: string[]
}

export type DealOrTermsheetsDTOs = DealOrTermsheetDTO | DashboardDealViewDTO

export async function getDashboardDealAPI (region: TermsheetRegion[]):
Promise<DealOrTermsheetDTO[]> {
  const params = region.map((str, i) => `region[${i}]=${str as string}`).join('&')
  return await API<DealOrTermsheetDTO[]>()
    .get({ path: `/v2/deals?${params}` })
}

export async function getDashboardTermsheetsAPI (region: TermsheetRegion[]):
Promise<DealOrTermsheetDTO[]> {
  const params = region.map((str, i) => `region[${i}]=${str as string}`).join('&')
  return await API<DealOrTermsheetDTO[]>()
    .get({ path: `/v2/termsheets?${params}` })
}

interface MergeDealsDto {
  output: string[]
}

export async function mergeDeals (survivingDealId: string, childrenDealIds: string[]): Promise<MergeDealsDto> {
  return await API<MergeDealsDto>().post({
    path: '/v2/deals/merge',
    init: {
      body: {
        survivingDealId, childrenDealIds
      }
    }
  })
}

export const getDashboardDealsV2 = async (cacheKey: string):
Promise<DashboardDealViewDTO[]> => {
  return await API<DashboardDealViewDTO[]>()
    .get({ path: `/v2/dashboard?cacheKey=${cacheKey}` })
}

export const fetchDashboardDeals =
    async (cacheKey: string): Promise<DashboardDealViewDTO[]> => await getDashboardDealsV2(cacheKey)

export const getDealV1IdByDealV2Id = async (dealV2Id: string): Promise<string> => {
  const { dealId } = await API<{ dealId: string }>().get({ path: `/v2/deals/find-dealId-by-dealV2Id/${dealV2Id}` })
  return dealId
}

export const getDashboardDrafts = async ():
Promise<ITermsheetV2Entity[]> => {
  return await API<ITermsheetV2Entity[]>()
    .get({ path: '/v2/termsheetsv2draft/all/by-user-org' })
}

export const getPreviousDeals = async (dealId: string):
Promise<PreviousDealDTO[]> => {
  return await API<PreviousDealDTO[]>()
    .get({ path: `/v2/dealsv2/${dealId}/previousDeals` })
}
