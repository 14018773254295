import { DashboardRegion, TermsheetRegion } from 'constants/index'
import {
  EmailNotificationFormat,
  EmailNotificationSettings,
  EmailNotificationTypes
} from 'interfaces/EmailNotifications'
import { ViewType } from 'routes/DashboardPageV2/components/ActionBar/types'
import { SectionType } from 'routes/DashboardPageV2/components/Section/types'

const getDefaultCardPreferences = (): Record<SectionType, {isCollapsed: boolean}> => ({
  LIVE: { isCollapsed: true },
  ANNOUNCED: { isCollapsed: true },
  PRICED: { isCollapsed: true },
  DRAFTS: { isCollapsed: true },
  WITHDRAWN: { isCollapsed: true }
})

export const defaultUserPreferences = {
  isNotificationCenterOpen: false,
  isUsingDashboardForAll: true,
  emailNotificationPreferences: {
    emailType: EmailNotificationFormat.SUMMARY,
    notificationSettings: Object.keys(TermsheetRegion).reduce((acc, item) => ({
      ...acc,
      [TermsheetRegion[item]]: {
        emailNotificationTypes: [EmailNotificationTypes.REAL_TIME]
      }
    }), {}) as EmailNotificationSettings
  },
  dashboardCards: {
    IPO: getDefaultCardPreferences(),
    ABO: getDefaultCardPreferences(),
    OTH: getDefaultCardPreferences(),
    SPAC: getDefaultCardPreferences(),
    RI: getDefaultCardPreferences(),
    EQL: getDefaultCardPreferences()
  },
  dashboardRegion: DashboardRegion.EMEA,
  viewType: 'grid' as ViewType,
  dashboardFilters: {}
}
