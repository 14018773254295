import React from 'react'
import { ORGANIZATION_TYPES } from 'constants/organizations'
import { useProfileHook } from 'contexts/user-auth-context'
import { DashboardForAllSwitcher } from 'components/SideMenu/components/DashboardForAllSwitcher'
import { useIsSpecificPathWatch } from 'lib/hooks'

export const MainListItems: React.FC = () => {
  const [profile] = useProfileHook()
  const { accountType } = profile
  const isSellSideSalesView = ORGANIZATION_TYPES.SELL_SIDE_SALES === accountType
  const isDashboardV2Path = useIsSpecificPathWatch([{
    path: '/ecm/dashboardv2',
    compare: 'includes'
  }])
  if (isSellSideSalesView) {
    return <></>
  }

  return !isDashboardV2Path ? <DashboardForAllSwitcher /> : <></>
}
