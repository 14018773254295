import React from 'react'
import styled from '@emotion/styled'

interface FormLabelProps {
  children?: string | any[]
}

export const FormLabel = ({ children }: FormLabelProps): JSX.Element => <FormLabelWrapper>{children}</FormLabelWrapper>

const FormLabelWrapper = styled('div')`
  font-size: 12px;
  font-weight: 600;
  margin-top: 12px;
  margin-bottom: 5px;
`
