import React from 'react'
import styled from '@emotion/styled'

interface TableTopSectionButtonsProps {
  onClick?: any
  children?: any
}

export function TableTopSectionButtons ({ onClick, children }: TableTopSectionButtonsProps): JSX.Element {
  return <ButtonStyled onClick={onClick}>{children}</ButtonStyled>
}

const ButtonStyled = styled.button`
  border: none;
  min-width: 84px;
  max-height: 22px;
  margin-left: 8px;
  padding: 4px 12px;
  font-size: 10px;
  font-weight: 600;
  border-radius: 25px;
  background: ${({ theme }) => theme.grey_250};
  &:hover {
    cursor: pointer;
  }
`
