import { ARRAY_OF_DOMICILE } from 'constants/countries'

import { DATE_FORMAT_HOURS, DATE_FORMAT_STANDARD } from 'constants/dateFormats'

export { CURRENCIES_ARRAY } from './currecies'
export {
  ARRAY_OF_DOMICILE,
  // Date formats
  DATE_FORMAT_STANDARD,
  DATE_FORMAT_HOURS
}

export enum PRIMARY_OR_SECONDARY {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  PRIMARY_AND_SECONDARY = 'PRIMARY AND SECONDARY'
}

export enum PRIMARY_OR_SECONDARY_UPPER_FIRST {
  Primary = 'Primary',
  Secondary = 'Secondary',
  Both = 'Primary and Secondary'
}

export const PRIMARY_SECONDARY_OPTIONS = Object.values(PRIMARY_OR_SECONDARY_UPPER_FIRST)

export enum ABO_DEAL_SUB_TYPES {
  delta = 'Delta',
  privatePlacement = 'Private Placement',
  publicSideTransaction = 'Public Side Transaction',
  reverseABO = 'Reverse ABO',
  rump = 'Rump',
  unregisteredBlockTrade = 'Unregistered Block Trade',
}

export enum SPAC_DEAL_SUB_TYPES {
  businessCombination = 'Business Combination',
}

export enum OTH_DEAL_SUB_TYPES {
  fullyMarketedOffering = 'Fully Marketed Offering',
  privatePlacement = 'Private Placement',
}
export const TBA = 'TBA'

export enum DASHBOARD_TYPE {
  DEAL = 'DEAL',
  TERMSHEET = 'TERMSHEET'
}
export const SECURITY_TYPES = [
  'ADS (American Depository Share)',
  'ADR (American Depository Receipt)',
  'DS (Depository Share)',
  'DR (Depository Receipt)',
  'GDR (Global Depository Receipt)',
  'GDS (Global Depository Share)',
  'ORD (Ordinary Shares)',
  'OTH (Other)'
]

export const SECURITY_TYPES_EQL = [
  'Convertible Bond',
  'Mandatory Convertible',
  'Exchangeable Bond',
  'Mandatory Exchangeable'
]

export const SECURITY_TYPES_OPTIONS = SECURITY_TYPES.map((value) => ({ label: value, value }))
export const SECURITY_TYPES_OPTIONS_EQL = SECURITY_TYPES_EQL.map((value) => ({ label: value, value }))
export enum OFFERING_TYPE {
  ABO = 'ABO',
  EQL = 'EQL',
  FMO = 'FMO',
  IPO = 'IPO',
  RI = 'RI',
  OTH = 'OTH',
  SPAC = 'SPAC',
}

export const OFFERING_TYPES = {
  ABO: 'ABO',
  EQL: 'EQL',
  FMO: 'Marketed Offerings',
  IPO: 'IPO',
  RI: 'Rights issue',
  OTH: 'Other Offerings',
  SPAC: 'SPAC'
}

export enum TermsheetState {
  LIVE = 'LIVE',
  ANNOUNCED = 'ANNOUNCED',
  PRICED = 'PRICED',
  WITHDRAWN = 'WITHDRAWN',
  POSTPONED = 'POSTPONED'
}

export const STATE_COLORS = {
  [TermsheetState.ANNOUNCED]: 'yellow-500',
  [TermsheetState.LIVE]: 'green-700',
  [TermsheetState.PRICED]: 'red-700',
  [TermsheetState.WITHDRAWN]: 'indigo-600',
  [TermsheetState.POSTPONED]: 'grey-400'
}

export const PILL_STATUS_TYPES = {
  [TermsheetState.ANNOUNCED]: 'warning-filled',
  [TermsheetState.LIVE]: 'progress-filled',
  [TermsheetState.PRICED]: 'success-filled',
  [TermsheetState.WITHDRAWN]: 'inactive-filled'
}

export const STATE_ORDERING = {
  [TermsheetState.ANNOUNCED]: 2,
  [TermsheetState.LIVE]: 1,
  [TermsheetState.PRICED]: 3,
  [TermsheetState.WITHDRAWN]: 4
}
export enum TermsheetRegion {
  EMEA = 'EMEA',
  APAC = 'APAC',
  AMRS = 'AMRS'
}

export enum DashboardRegion {
  ALL = 'ALL',
  EMEA = 'EMEA',
  APAC = 'APAC',
  AMRS = 'AMRS'
}

export const DashboardRegionLabels = {
  [DashboardRegion.ALL]: 'ALL',
  [DashboardRegion.EMEA]: 'EMEA',
  [DashboardRegion.APAC]: 'APAC',
  [DashboardRegion.AMRS]: 'AMRS'
}

export const RECENTLY_PRICED_NUMBER_OF_DAYS = {
  IPO: 100,
  ABO: 100,
  EQL: 100,
  RI: 100,
  OTH: 100,
  SPAC: 100
}

// The constants that we use to describe the chosen value as data structure / also used on the back-end.
export const QIBS = 'QIBS'
export const REG_S_ONLY = 'REG_S_ONLY'

// The distribution types as they should be displayed in the add new deal form and termsheet page.
export const DISTRIBUTION_TYPES = {
  [QIBS]: 'To QIBs in accordance with an available exemption',
  [REG_S_ONLY]: 'Reg S only'
}

export enum BANK_ROLE {
  SGC = 'Sole Global Coordinator',
  JGC = 'Joint Global Coordinator',
  SBR = 'Sole Bookrunner',
  JBR = 'Joint Bookrunner',
  CBR = 'Co-Bookrunner',
  LM = 'Lead Manager',
  JLM = 'Joint Lead Manager',
  CLM = 'Co-Lead Manager',
  CM = 'Co Manager',
  SPONSOR = 'Sponsor',
  PA = 'Placing Agent',
  SA = 'Selling Agent',
  LLB = 'Lead Left Bookrunner',
  ADV = 'Advisor',
  OTHER = 'Other',
}

export const BANK_ROLES_OPTIONS = Object.keys(BANK_ROLE).map((key) => ({
  label: BANK_ROLE[key],
  value: BANK_ROLE[key]
}))

export const BANK_ROLES = [
  BANK_ROLE.SGC,
  BANK_ROLE.JGC,
  BANK_ROLE.SBR,
  BANK_ROLE.JBR,
  BANK_ROLE.CBR,
  BANK_ROLE.LM,
  BANK_ROLE.JLM,
  BANK_ROLE.CLM,
  BANK_ROLE.CM,
  BANK_ROLE.SPONSOR,
  BANK_ROLE.PA,
  BANK_ROLE.SA,
  BANK_ROLE.LLB,
  BANK_ROLE.ADV,
  BANK_ROLE.OTHER
]

export const BANK_ROLE_ABBREVIATIONS = {
  [BANK_ROLE.SGC]: 'SGC',
  [BANK_ROLE.JGC]: 'JGC',
  [BANK_ROLE.SBR]: 'SBR',
  [BANK_ROLE.JBR]: 'JBR',
  [BANK_ROLE.CBR]: 'CBR',
  [BANK_ROLE.LM]: 'LM',
  [BANK_ROLE.JLM]: 'JLM',
  [BANK_ROLE.CLM]: 'CLM',
  [BANK_ROLE.CM]: 'CM',
  [BANK_ROLE.SPONSOR]: 'Sponsor',
  [BANK_ROLE.PA]: 'Placing Agent',
  [BANK_ROLE.SA]: 'Selling Agent',
  [BANK_ROLE.LLB]: 'LLB',
  [BANK_ROLE.ADV]: 'ADV',
  [BANK_ROLE.OTHER]: 'Other'
}

const ARRAY_OF_SECURITY_TYPES = {
  [OFFERING_TYPE.ABO]: [
    { name: 'American Depository Share', code: 'ADS' },
    { name: 'American Depository Receipt', code: 'ADR' },
    { name: 'Depository Share', code: 'DS' },
    { name: 'Depository Receipt', code: 'DR' },
    { name: 'Global Depository Receipt', code: 'GDR' },
    { name: 'Global Depository Share', code: 'GDS' },
    { name: 'Ordinary Shares', code: 'ORD' },
    { name: 'Other', code: 'OTH' }
  ],
  [OFFERING_TYPE.EQL]: [
    { name: 'Convertible Bond', code: 'CB' },
    { name: 'Mandatory Convertible ', code: 'MCB' },
    { name: 'Exchangeable Bond', code: 'EXB' },
    { name: 'Mandatory Exchangeable', code: 'MEB' }
  ],
  [OFFERING_TYPE.FMO]: [
    { name: 'American Depository Share', code: 'ADS' },
    { name: 'American Depository Receipt', code: 'ADR' },
    { name: 'Depository Receipt', code: 'DR' },
    { name: 'Depository Share', code: 'DS' },
    { name: 'Global Depository Receipt', code: 'GDR' },
    { name: 'Global Depository Share', code: 'GDS' },
    { name: 'Ordinary Shares', code: 'ORD' },
    { name: 'Other', code: 'OTH' }
  ],
  [OFFERING_TYPE.IPO]: [
    { name: 'American Depository', code: 'ADS' },
    { name: 'American Depository Receipt', code: 'ADR' },
    { name: 'Depository Receipt', code: 'DR' },
    { name: 'Depository Share', code: 'DS' },
    { name: 'Global Depository Receipt', code: 'GDR' },
    { name: 'Global Depository Share', code: 'GDS' },
    { name: 'Ordinary Shares', code: 'ORD' },
    { name: 'Other', code: 'OTH' }
  ],
  [OFFERING_TYPE.RI]: [{ name: 'Rights issue', code: 'RI' }],
  [OFFERING_TYPE.OTH]: [
    { name: 'American Depository', code: 'ADS' },
    { name: 'American Depository Receipt', code: 'ADR' },
    { name: 'Depository Receipt', code: 'DR' },
    { name: 'Depository Share', code: 'DS' },
    { name: 'Global Depository Receipt', code: 'GDR' },
    { name: 'Global Depository Share', code: 'GDS' },
    { name: 'Ordinary Shares', code: 'ORD' },
    { name: 'Other', code: 'OTH' }
  ]
}

export const SECURITY_TYPES_LABELS = (offeringType: OFFERING_TYPE): string[] => {
  return ARRAY_OF_SECURITY_TYPES[offeringType]
    .map((type: { name: string, code: string }) => `${type.code} (${type.name})`)
}

export const SECURITY_TYPES_LABELS_EQL = (): string[] => ARRAY_OF_SECURITY_TYPES[OFFERING_TYPE.EQL]
  .map((type) => type.name)

export const IOI_DOMAIN = 'ioi.primary-portal.com'
export const DEAL_TYPE_OPTIONS = [
  {
    label: 'IPO',
    value: 'IPO'
  },
  {
    label: 'ABO',
    value: 'ABO'
  },
  {
    label: 'Other Offerings',
    value: 'OTH'
  },
  {
    label: 'SPAC',
    value: 'SPAC'
  },
  {
    label: 'Rights Issues',
    value: 'RI'
  },
  {
    label: 'Equity Linked',
    value: 'EQL'
  }
]

export enum OfferingTypeLowerCase {
  IPO = 'ipo',
  ABO = 'abo',
  EQL = 'eql',
  RI = 'ri',
  SPAC = 'spac',
  OTH = 'oth'
}

export enum OfferingTypeTimetableLowerCase {
  IPO_TIMETABLE = 'ipoTimetable',
  ABO_TIMETABLE = 'aboTimetable',
  EQL_TIMETABLE = 'eqlTimetable',
  RI_TIMETABLE = 'riTimetable',
  SPAC_TIMETABLE = 'spacTimetable',
  OTH_TIMETABLE = 'othTimetable'
}
