import styled from '@emotion/styled'
/* eslint-disable @typescript-eslint/restrict-template-expressions */

interface TermsheetTableStyledProps {
  logoUrl: string
  resetPosition?: boolean
}

export const TermsheetTableStyled = styled.div<TermsheetTableStyledProps>`
  box-shadow: 3px 3px 3px 0 #ececec;
  border-radius: 3px;
  grid-column: 1/3;
  padding: 0 10px;
  border: ${({ theme }) => `1px solid ${theme.gray_bg}`};
  h2 {
    font-size: 16px;
  }
  p {
    margin: 10px 0;
  }
  /* background image */
  /* Water mark logo */
  background: ${({ theme }) => theme.white};
  position: ${({ resetPosition }) => resetPosition ? 'initial' : 'relative'};
  z-index: 1;
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: ${({ logoUrl }) => `url(${logoUrl})`};
    background-repeat: no-repeat;
    background-size: 80%;
    background-position: center;
    opacity: 0.1;
    z-index: -1;
    text-align: center;
  }
  .row {
    padding: 5px;
    margin: 7px 0;
    white-space: pre-wrap;
    break-inside: avoid;
    border-bottom: 1px solid rgba(216, 216, 216, 0.5);
    p {
      margin: 0;
    }
  }
  .row-header {
    font-weight: bold;
    display: flex;
    align-items: center;

    svg {
      margin: 0 3px 2px 3px;
      font-size: 1rem;
    }
  }
`

export const TermsheetTableTitleStyled = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: ${({ theme }) => theme.borderBottom};
  h2 {
    margin: 10px 0;
  }
  & > div {
    display: flex;
    align-items: center;
  }
`

export const TermsheetTableBodyStyled = styled.div`
  columns: 2;
  column-gap: 15px;
  column-rule: 1px solid #d8d8d8;
  overflow-wrap: break-word;
  & > div {
    break-inside: avoid;
  }
  & > div:last-of-type {
    border-bottom: none;
  }
`

export const TermsheetTableHeaderStyled = styled.div`
  margin-left: 4px;
  margin-top: 12px;
  margin-bottom: 12px;
  white-space: pre-wrap;
  font-size: 11px;
  overflow-wrap: break-word;
`
