import { ISpecificPath } from 'lib/types'

export const pagesWithHiddenSidebar: ISpecificPath[] = [{
  path: '/ecm/termsheet/',
  compare: 'includes'
}, {
  path: '/ecm/deals/',
  compare: 'includes'
}, {
  path: '/ecm/termsheets/',
  compare: 'includes'
}, {
  path: '/ecm/investor/syndicate-contacts',
  compare: 'strict'
}, {
  path: '/ecm/investor/banker-connections',
  compare: 'strict'
}, {
  path: '/ecm/admin/investor-connections',
  compare: 'strict'
}]
export const dashboardPage: ISpecificPath[] = [{
  path: '/ecm/dashboard',
  compare: 'strict'
}]

export const dashboardV2Page: ISpecificPath[] = [{
  path: '/ecm/dashboardv2',
  compare: 'strict'
}]

export const addNewTSPage: ISpecificPath[] = [{
  path: '/ecm/deals/add-new',
  compare: 'strict'
}]
