/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Button } from '@material-ui/core'
import { useFieldArray } from 'react-hook-form'

import { TextField } from '../../../components/TextField/MainTextField'
import { getErrorLine } from '../../../components/ErrorLine/getErrorLine'

export function Shareholders ({ control, register, errors }): JSX.Element {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'sellingShareholders'
  })

  return (
    <>
      {fields.map((shareholder, index) => (
        <InputsStyled key={shareholder.id}>
          <div>
            <TextField
              inputRef={register()}
              defaultValue={`${shareholder.shareholder}`}
              label={`Shareholder (display) ${index + 1} out of ${fields.length}`}
              name={`sellingShareholders[${index}].shareholder`}
            />
            <LegalLockUpStyled>
              <TextField
                inputRef={register()}
                defaultValue={`${shareholder.shareholderLegal}`}
                label='Shareholder (legal)'
                name={`sellingShareholders[${index}].shareholderLegal`}
              />
              <TextField inputRef={register()} defaultValue={`${shareholder.lockup}`} label='Lock up (days)' name={`sellingShareholders[${index}].lockup`} />
            </LegalLockUpStyled>
          </div>
          <DeleteButton onClick={() => remove(index)}>Delete</DeleteButton>
        </InputsStyled>
      ))}

      <AddButton
        onClick={() => {
          append({ shareholder: '-', shareholderLegal: '-', lockup: '-' })
        }}
      >
        + click to add shareholder
      </AddButton>
      {getErrorLine(errors, 'sellingShareholders')}
    </>
  )
}

Shareholders.propTypes = {
  control: PropTypes.object.isRequired,
  register: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired
}

// eslint-disable-next-line react/prop-types
function AddButton ({ children, ...rest }): JSX.Element {
  return (
    <AddButtonStyled>
      <Button {...rest}>{children}</Button>
    </AddButtonStyled>
  )
}
// eslint-disable-next-line react/prop-types
function DeleteButton ({ children, ...rest }): JSX.Element {
  return (
    <DeleteButtonStyled>
      <Button {...rest}>{children}</Button>
    </DeleteButtonStyled>
  )
}

const AddButtonStyled = styled.div`
  margin-top: 5px;
  .MuiButton-label {
    font-size: 12px;
    font-weight: 500;
    text-transform: none;
  }
  .MuiButton-root {
    background: ${({ theme }) => theme.grey_250};
  }
`

const DeleteButtonStyled = styled.div`
  .MuiButton-root {
    padding-left: 0;
  }
  .MuiButton-label {
    font-weight: 500;
    color: ${({ theme }) => theme.orange};
    text-transform: none;
  }
`

const InputsStyled = styled.div`
  padding: 6px 18px 6px 12px;
  border: 2px solid ${({ theme }) => theme.grey_250};
  border-radius: 4px;
  margin: 10px 0;
  & > div:first-of-type {
    width: 100%;
  }
`
const LegalLockUpStyled = styled.div`
  display: flex;
  & > div:last-of-type {
    width: 40%;
    margin-left: 15px;
  }
`
