import React, { useMemo } from 'react'
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import { IErrorLoadingProps, IErrorLoadingStyles } from 'components/ErrorLoading/types'
import { join, mergeDeepLeft, pipe, values } from 'ramda'
import { defaultStyles } from 'components/ErrorLoading/const'

const ErrorLoading: React.FC<IErrorLoadingProps> = ({ error, styles }) => {
  const additionalClasses = useMemo(() =>
    pipe(mergeDeepLeft(styles as IErrorLoadingStyles), values, join(' '))(defaultStyles),
  [styles]
  )
  return (
    <div className={`flex flex-col items-center justify-center ${additionalClasses}`}>
      <ExclamationCircleIcon className='w-12 h-12 text-red-400 mb-4' />
      <div>{error}</div>
    </div>
  )
}
export default React.memo(ErrorLoading)
