export enum BookBuildModalType{
  ADD,
  EDIT,
  CONFIRM_PUBLISH,
  CONFIRM_EDIT,
  CONFIRM_REMOVE
}

export const confirmationText = {
  [BookBuildModalType.CONFIRM_PUBLISH]: 'You are about to publish a bookbuilding message',
  [BookBuildModalType.CONFIRM_EDIT]: 'You are about to edit a bookbuilding message',
  [BookBuildModalType.CONFIRM_REMOVE]: 'You are about to remove a bookbuilding message'
}

export const toastText = {
  [BookBuildModalType.CONFIRM_PUBLISH]: {
    successMsg: 'Message added successfully',
    errorMsg: 'Could not add message, please try again'
  },
  [BookBuildModalType.CONFIRM_EDIT]: {
    successMsg: 'Message saved',
    errorMsg: 'Could not save message, please try again'
  },
  [BookBuildModalType.CONFIRM_REMOVE]: {
    successMsg: 'Message removed',
    errorMsg: 'Could not save message, please try again'
  }
}
