import { CACHED_QUERY_PROPS } from 'constants/queries'
import { useQuery, UseQueryResult } from 'react-query'
import { getBankerOrganizations, Organization } from 'services/organizations'
import { useStores } from 'store/rootStore'

export function useGetBankerOrganizations (): UseQueryResult<Organization[]> {
  const { commonStore } = useStores()
  return useQuery<Organization[]>('get-organizations', getBankerOrganizations, {
    ...CACHED_QUERY_PROPS,
    staleTime: 30 * 60 * 1000,
    onSuccess: (data) => {
      commonStore.bankers = data
    }
  })
}
