import React from 'react'
import PropTypes from 'prop-types'

import styled from '@emotion/styled'

import { FormLabel } from '../../../components/FormLabel'

export function PrimarySecondaryTotal ({ watch }): JSX.Element {
  const primaryShares = Number(watch('offeringSizeNrSharesPrimary'))
  const secondaryShares = Number(watch('offeringSizeNrSharesSecondary'))
  const totalNumberOfShares = primaryShares + secondaryShares

  return (
    <PrimarySecondaryTotalStyled number={totalNumberOfShares}>
      <FormLabel>Total number of shares offered</FormLabel>
      <div className='primary-secondary-total-value'>
        <p>{totalNumberOfShares}</p>
      </div>
    </PrimarySecondaryTotalStyled>
  )
}

PrimarySecondaryTotal.propTypes = {
  watch: PropTypes.func.isRequired
}

interface PrimarySecondaryTotalStyledProps {
  number: number
}

const PrimarySecondaryTotalStyled = styled.div<PrimarySecondaryTotalStyledProps>`
  .primary-secondary-total-value {
    border-radius: 4px;
    background: #e0e0e0;
    padding: 10px 14px;
    font-size: 16px;
    font-weight: 500;
    & > p {
      visibility: ${({ number }) => (isNaN(number) || number === 0 ? 'hidden' : 'visible')};
    }
  }
`
