import React from 'react'
import { TableCell, TableRow } from '../Table/Table'
import { Row } from 'react-table'

const RenderRow = (prepareRow: (row: Row<object>) => void, rows: Array<Row<object>>, dataQa?: string) =>
  ({ index: rowIdx, style }: { index: number, style: any }): JSX.Element => {
    const row = rows[rowIdx]

    prepareRow(row)
    return (
      // Apply the row props
      <TableRow
        {...row.getRowProps()}
        dataQa={`${dataQa ?? ''}-Row`}
        rowIdx={rowIdx} key={`row-${rowIdx.toString()}`}
        style={style}
      >
        {
          row.cells.map((cell, cellIdx: number) => {
            return (
              // Apply the cell props
              <TableCell
                {...cell.getCellProps()}
                key={`cell-${rowIdx}-${cellIdx}`}
                additionalClasses={cell.column.width as string}
              >
                {// Render the cell contents
                  cell.render('Cell')
                }
              </TableCell>
            )
          })
        }
      </TableRow>
    )
  }

export default RenderRow
