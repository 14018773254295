import React, { forwardRef } from 'react'
import clsx from 'clsx'
import { ReactHookFormProps } from 'ui/Form/types'
import { FormLabel } from 'ui/Form/FormLabel'
import { FormError } from 'ui/Form/FormError'

interface Option {
  id: string
  name: string
}

interface Props extends ReactHookFormProps {
  label: string
  options: Option[]
  required?: boolean
  dataQa?: string
}

export const FormFieldSelect = forwardRef<HTMLSelectElement, Props>((props: Props, ref): JSX.Element => {
  const className = clsx(
    'mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md'
  )

  const htmlFor = props.name
  return (
    <div>
      <FormLabel htmlFor={htmlFor} label={props.label} />
      <div className='mt-2'>
        <select
          id={htmlFor}
          ref={ref}
          required={props.required}
          name={props.name}
          onChange={props.onChange}
          onBlur={props.onBlur}
          className={className}
          data-qa={props.dataQa}
        >
          <option value='' disabled className='hidden' />
          {props.options.map(({ id, name }) => {
            return <option key={id} value={id}>{name}</option>
          })}
        </select>
      </div>
      <FormError errors={props.errors ?? {}} name={props.name} />
    </div>
  )
})
