import { lightThemeColors } from '../../theme'
import { createTheme } from '@material-ui/core'

export const datePickerTheme = createTheme({
  props: {
    MuiInput: {
      disableUnderline: true
    }
  },
  overrides: {
    MuiInput: {
      underline: {
        '&:after': {
          borderBottom: 'none'
        }
      }
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: lightThemeColors.grey_250
      }
    },
    MuiPickersToolbarText: {
      toolbarTxt: { color: lightThemeColors.black, opacity: '0.7' },
      toolbarBtnSelected: { color: lightThemeColors.black }
    },
    MuiButton: {
      textPrimary: {
        color: lightThemeColors.black
      }
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: lightThemeColors.grey_250,
        opacity: 0.7,
        '&:hover': {
          backgroundColor: lightThemeColors.grey_250,
          opacity: 1
        },
        color: lightThemeColors.black
      },
      current: {
        color: lightThemeColors.white,
        backgroundColor: lightThemeColors.grey_250
      }
    },
    MuiPickerDTTabs: {
      tabs: {
        color: lightThemeColors.black,
        backgroundColor: lightThemeColors.grey_250
      }
    }
  }
})
