import React from 'react'

import styled from '@emotion/styled'
import { Button } from '@material-ui/core'

export function DismissButton (props): JSX.Element {
  return (
    <DismissButtonStyled>
      <Button fullWidth {...props}>
        Dismiss
      </Button>
    </DismissButtonStyled>
  )
}

const DismissButtonStyled = styled.div`
  .MuiButton-root {
    background: ${({ theme }) => theme.grey_250};
    text-transform: none;
    font-weight: 600;
    margin-bottom: 10px;
    font-size: 12px;
  }
`

export function PublishPriceButton (props): JSX.Element {
  return (
    <PublishPriceButtonStyled>
      <Button fullWidth {...props}>
        {props.title}
      </Button>
    </PublishPriceButtonStyled>
  )
}

const PublishPriceButtonStyled = styled.div`
  .MuiButton-root {
    background: ${({ theme }) => theme.orange};
    text-transform: none;
    color: ${({ theme }) => theme.white};
    font-weight: 600;
    font-size: 12px;
  }
`
