import { DateTime } from 'luxon'
import { anyPass, intersection, isEmpty, pipe, propOr } from 'ramda'
import { DashboardDealViewDTO, DealOrTermsheetsDTOs } from 'services/deals'
import { OFFERING_TYPE, STATE_ORDERING } from 'constants/index'
import memoize from 'fast-memoize'
import { IFilterSettings } from 'store/types'
import { PartitionedDealsFromAPI } from 'routes/DashboardPage/types'
import { rootStore } from 'store/rootStore'
import { AccountType } from 'lib/types'
import { isSecondAfterFirstDate } from 'utils/dateHelpers'

export const isNullishOrEmpty = (str: undefined|null|string): boolean => str == null || str === ''

export const sortByNewest = <T extends DealOrTermsheetsDTOs>(deals: T[], version: 'v1' | 'v2'): T[] => {
  return sortByDate(deals, true, version)
}

export const sortByOldest = <T extends DealOrTermsheetsDTOs>(deals: T[], version: 'v1' | 'v2'): T[] => {
  return sortByDate(deals, false, version)
}

const isDateUnknown = (fieldKey: string) =>
  (deal: DealOrTermsheetsDTOs) => isNullishOrEmpty(deal[fieldKey]) || deal[fieldKey] === 'TBA'

const sortByDate = <T extends DealOrTermsheetsDTOs>(deals: T[], reverseSort: boolean, version: 'v1' | 'v2'): T[] => {
  const isRi = deals.length > 0 && deals?.[0].offeringType === OFFERING_TYPE.RI
  const sortationDateKey = isRi ? 'endOfSubscriptionPeriodDate' : 'closeOfBookbuildingDate'
  const dealsTBA = deals.filter(isDateUnknown(sortationDateKey))
  const dealsWithDate = deals
    .filter((deal) => !isDateUnknown(sortationDateKey)(deal))
    .sort((a, b) => {
      const { aDateTime, bDateTime } = version === 'v2'
        ? {
            aDateTime: DateTime.fromFormat(a[sortationDateKey] ?? '', 'yyyy-LL-dd').toMillis(),
            bDateTime: DateTime.fromFormat(b[sortationDateKey] ?? '', 'yyyy-LL-dd').toMillis()
          }
        : {
            aDateTime: DateTime.fromHTTP(a[sortationDateKey] as string).toMillis(),
            bDateTime: DateTime.fromHTTP(b[sortationDateKey] as string).toMillis()
          }
      return reverseSort ? bDateTime - aDateTime : aDateTime - bDateTime
    })
  return [...dealsWithDate, ...dealsTBA]
}

const memoizedFilter = memoize((
  filterSettings: IFilterSettings,
  countries: string[],
  allowedCountries: string[],
  countryCodes: string[]
) => {
  if (filterSettings.includeWithNoStockExchanges && countryCodes.length === 0) {
    return true
  }
  const filteredCountryCodes = intersection(countryCodes, countries)
  return filteredCountryCodes?.length > 0 && intersection(filteredCountryCodes, allowedCountries)?.length > 0
})

export const applyUserFilters = memoize(<T extends DealOrTermsheetsDTOs>(
  filterSettings: IFilterSettings,
  allowedCountries: string[],
  countries: string[],
  data: T[]
): T[] =>
    data.filter(pipe(
      propOr([], 'countryCodes'),
      anyPass([
        () => isEmpty(countries),
        (countryCodes: string[]) => memoizedFilter(filterSettings, countries, allowedCountries, countryCodes)
      ])
    )))

export const applyTransforms = <T, S extends DealOrTermsheetsDTOs> (fn: (deal: S) => T) => (data: PartitionedDealsFromAPI<S>) => {
  return {
    announced: data.announced.map(fn),
    live: data.live.map(fn),
    priced: data.priced.map(fn),
    drafts: data.drafts.map(fn),
    withdrawn: data.withdrawn.map(fn)
  }
}
export const applySorting = <T extends DealOrTermsheetsDTOs>(version: 'v1' | 'v2', data: PartitionedDealsFromAPI<T>): PartitionedDealsFromAPI<T> => ({
  announced: sortByOldest(data.announced, version),
  live: sortByOldest(data.live, version),
  priced: sortByNewest(data.priced, version),
  drafts: sortByOldest(data.drafts, version),
  withdrawn: sortByNewest(data.withdrawn, version)
})

export const isDealAvailable = (deal: DashboardDealViewDTO): boolean => rootStore.userStore.user.profile?.user?.accountType === AccountType.INVESTOR
  ? Boolean(deal.isATermsheetViewableOnThisDeal)
  : true

export const stateSorting = (rowA: any, rowB: any, columnId: any): number => {
  const firstPosition = STATE_ORDERING[rowA.getValue(columnId).toUpperCase()]
  const secondPosition = STATE_ORDERING[rowB.getValue(columnId).toUpperCase()]
  if (firstPosition === secondPosition) {
    return 0
  }
  return STATE_ORDERING[rowA.getValue(columnId).toUpperCase()] < STATE_ORDERING[rowB.getValue(columnId).toUpperCase()]
    ? 1
    : -1
}

export const booksClosedDateSorting = (rowA: any, rowB: any): number => {
  const { closeOfBookbuildingDate: closeOfBookbuildingDateA } = rowA.original
  const { closeOfBookbuildingDate: closeOfBookbuildingDateB } = rowB.original
  if (closeOfBookbuildingDateB === closeOfBookbuildingDateA) {
    return 0
  }
  return isSecondAfterFirstDate(closeOfBookbuildingDateB, closeOfBookbuildingDateA) ? 1 : -1
}
