import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

import styled from '@emotion/styled'
import SearchIcon from '@material-ui/icons/Search'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import { TextField } from '../TextField/MainTextField'

export function AutocompleteCustom ({ array, handleSelect, title, handleInput, inputValue, selected, isMultiSelect }): JSX.Element {
  const [isInputClicked, setIsInputClicked] = useState(false)
  const detectOutsideClickRef = useRef<HTMLDivElement>(null)

  const handleInputClick = (): void => setIsInputClicked(true)

  const filteredArray = [
    ...array.filter((item: any) => Boolean(item)).filter((item: string) => item.substring(0, inputValue.item.length).toLocaleLowerCase() === inputValue.item.toLowerCase()),
    ...array.filter((item: any) => Boolean(item)).filter((item: string) => item.toLowerCase().includes(inputValue.item.toLowerCase()))
  ].filter((item, i, ar) => ar.indexOf(item) === i).slice(0, 20)

  const handleClickOutsideInput = (e): void => {
    if ((detectOutsideClickRef.current != null) && !detectOutsideClickRef.current.contains(e.target)) {
      setIsInputClicked(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideInput, true)
    return () => {
      document.addEventListener('click', handleClickOutsideInput, true)
    }
  }, [detectOutsideClickRef])

  return (
    <div>
      <FormFieldWithSearchIconStyled onClick={handleInputClick} ref={detectOutsideClickRef}>
        {!isInputClicked && !(inputValue.item as boolean) && <SearchIcon />}

        <TextField label={title} name='item' onChange={handleInput} value={inputValue.item} />
      </FormFieldWithSearchIconStyled>

      {inputValue.item !== '' && filteredArray.length > 0 && (
        <SearchResultsStyled selected={selected} isMultiSelect={isMultiSelect}>
          <div>
            {filteredArray.slice(0, filteredArray.length / 2).map((item, index) => (
              <div key={index}>
                <FormControlLabel control={<Checkbox color='primary' onChange={handleSelect} name={item} />} label={item} />
              </div>
            ))}
            {filteredArray.slice(filteredArray.length / 2, filteredArray.length).map((item, index) => (
              <div key={index}>
                <FormControlLabel control={<Checkbox color='primary' onChange={handleSelect} name={item} />} label={item} />
              </div>
            ))}
          </div>
        </SearchResultsStyled>
      )}
    </div>
  )
}

const FormFieldWithSearchIconStyled = styled.div`
  position: relative;

  .MuiSvgIcon-root {
    position: absolute;
    top: 43px;
    left: 12px;
  }
`

interface SearchResultsStyledProps {
  selected: string
  isMultiSelect: boolean
}

const SearchResultsStyled = styled.div<SearchResultsStyledProps>`
  width: 100%;
  display: ${({ selected, isMultiSelect }) => (isMultiSelect ? 'block' : selected === '' ? 'block' : 'none')};
  position: relative;

  & > div {
    width: 100%;
    bottom: 32px;
    z-index: 1000;
    position: absolute;
    background: ${({ theme }) => theme.gray_bg};
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-radius: 3px;
    border: ${({ theme }) => `1px solid ${theme.grey_250 as string}`};
    margin: 10px 0;
    padding: 5px 10px;

    .MuiTypography-body1 {
      font-size: 12px;
      color: ${({ theme }) => theme.blue_900};
    }

    .MuiCheckbox-root {
      padding: 5px 14px;

      svg {
        color: ${({ theme }) => theme.blue_500};
        font-size: 1.2rem;
      }
    }
  }
`

AutocompleteCustom.propTypes = {
  handleSelect: PropTypes.func.isRequired,
  array: PropTypes.instanceOf(Array).isRequired,
  title: PropTypes.string.isRequired,
  handleInput: PropTypes.func.isRequired,
  inputValue: PropTypes.instanceOf(Object).isRequired,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  isMultiSelect: PropTypes.bool
}

AutocompleteCustom.defaultProps = {
  isMultiSelect: false
}
