import React from 'react'
import PropTypes from 'prop-types'

import CancelIcon from '@material-ui/icons/Cancel'
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone'
import styled from '@emotion/styled'

import { useDealFormContext } from '../../containers/DealForm/context'
import { TableTopSectionButtons } from '../../components/Buttons/Dashboard/TableTopSectionButtons'
import { PreviewAndPublish } from '../../containers/DealForm/Step2Form/components/PreviewAndPublish'
import { useSubmitHandler } from '../../containers/DealForm/Step2Form/useSubmitHandler'
import { TextField } from '@material-ui/core'
import { useTermsheetModalContext } from './TermsheetModals/context'

export function ConfirmRemovingBookBuildingMessage ({ submitHandler, setIsModalOpen }): JSX.Element {
  const [confirmTextBoxState, setConfirmTextBoxState] = React.useState('')
  const { formValues } = useDealFormContext()
  const { handleSubmit } = useSubmitHandler()
  const { setSubmitHandler } = useTermsheetModalContext()
  const issuerName = formValues.issuerName
  const isDisabled = confirmTextBoxState !== 'OK'

  React.useEffect(() => {
    return () => setSubmitHandler(null)
  }, [setSubmitHandler])

  return (
    <ConfirmModalStyled>
      <div onClick={() => setIsModalOpen(false)} className='confirmation-modal-cancel'>
        <CancelIcon />
      </div>
      <div className='confirmation-modal-bell'>
        <NotificationsNoneIcon />
      </div>
      <h3>You are about to remove a Bookbuilding Message</h3>
      <p className='confirmation-modal-issuer-name'>{issuerName}</p>
      <div className='confirmation-modal-edit'>
        <TableTopSectionButtons onClick={() => setIsModalOpen(false)}>Cancel</TableTopSectionButtons>
      </div>
      <p className='confirmation-modal-text'>To confirm, type ‘OK’ in the box below and hit Delete</p>
      <div className='confirmation-modal-confirm'>
        <TextField variant='outlined' value={confirmTextBoxState} onChange={(e) => setConfirmTextBoxState(e.target.value)} inputProps={{ 'data-qa': 'ConfirmPublishModal-TextInput' }} />
        <PreviewAndPublish
          onClick={() => {
            setIsModalOpen(false)
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            submitHandler ? submitHandler(formValues) : handleSubmit()
          }}
          disabled={isDisabled}
          isDelete
          data-qa='ConfirmPublishModal'
        />
      </div>
    </ConfirmModalStyled>
  )
}

ConfirmRemovingBookBuildingMessage.propTypes = {
  submitHandler: PropTypes.func,
  setIsModalOpen: PropTypes.func
}

const ConfirmModalStyled = styled.div`
  margin: 0 auto;
  text-align: center;
  .confirmation-modal-cancel {
    text-align: right;
    &:hover {
      cursor: pointer;
    }
    svg {
      color: ${({ theme }) => theme.blue_900};
    }
  }
  .confirmation-modal-bell {
    margin-bottom: 19px;
    svg {
      color: ${({ theme }) => theme.orange};
      font-size: 45px;
    }
  }
  h3 {
    margin-bottom: 20px;
  }
  .confirmation-modal-issuer-name {
    font-weight: 14px;
    margin-bottom: 15px;
    font-weight: 500;
    color: #585fff;
  }
  .confirmation-modal-edit {
    & > button {
      margin-left: 0;
      padding: 3px 20px;
      font-weight: 400;
      font-size: 12px;
      min-width: auto;
    }
  }
  .confirmation-modal-text {
    margin-top: 42px;
    margin-bottom: 20px;
  }
  .confirmation-modal-confirm {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    width: 70%;
    & > div {
      width: 48%;
    }
    & > div:last-of-type {
      margin: 0;
    }
    .MuiOutlinedInput-input {
      padding: 10px 14px;
    }
    .MuiButton-text {
      padding: 9px 8px;
    }
  }
`
