import React from 'react'
import { Alert } from 'ui'
import SignInForm from './SignInForm'
import useSignInPage from './useSignInPage'

export function SignInPage (): JSX.Element {
  const { onLogin, alert } = useSignInPage()
  return (
    <SignInForm onSubmit={onLogin}>
      <Alert {...alert} />
    </SignInForm>
  )
}
