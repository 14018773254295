import React from 'react'
import { useSaudiArabiaListingExchanges } from 'lib/useSaudiArabiaListingExchanges'
import { mergeDeepRight } from 'ramda'
import { useHistory } from 'react-router-dom'
import { createDraftTermsheet, createTermsheet, publishDraftTermsheet, TermsheetV1Dto, updateTermsheet } from 'services/termsheets-v1'
import { TermsheetState } from '../../../constants'
import { DealContext } from '../../../contexts/deals-context'
import { useProfileHook } from '../../../contexts/user-auth-context'
import { useToast } from '../../../lib/useToast'
import { useTermsheetContext } from '../../TermsheetInformation/context'
import { useDealFormContext } from '../context'
import { useStores } from 'store/rootStore'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { StoreModel } from 'store/store'
import { Deal } from 'store/deals'

export const useSubmitHandler = (): any => {
  const history = useHistory()
  const { setFormValues, formValues, updateFormValues } = useDealFormContext()
  const [userProfile] = useProfileHook()
  const { dealsOrTermsheetsStore } = useStores()
  const { toastify } = useToast()
  const { getDeal } = useStoreState<StoreModel>((state) => state.deals)
  const { setDeal } = useStoreActions<StoreModel>((actions) => actions.deals)

  const { dealIdContext, isEditModeOnContext, setIsEditModeOnContext, setIsPriceDealModeContext, setIsPriceDealDisabled } = React.useContext<any>(DealContext)

  const { updateTermsheetView, setSelectedTermsheetId } = useTermsheetContext()

  const { accountOrganizationId } = userProfile

  const saudiArabiaListingExchanges: string[] = useSaudiArabiaListingExchanges()

  const handleEditTermsheet = async (shouldSendFeedNotifications: boolean, shouldSendEmailNotifications: boolean): Promise<any> => {
    if (formValues?.listingExchange !== undefined && formValues?.listingExchange.length > 0) {
      if (formValues?.listingExchange.some(e => saudiArabiaListingExchanges.includes(e.displayName)) === false) {
        formValues.distributionIntoSaudiArabia = undefined
      }
    } else {
      formValues.distributionIntoSaudiArabia = undefined
    }

    const inputData = mergeDeepRight(formValues, {
      dealId: formValues.dealId,
      updateAtDate: Date.now()
    })
    let result: TermsheetV1Dto
    const IS_DRAFT = (inputData as any).draft === true
    if (IS_DRAFT) {
      result = await toastify(
        publishDraftTermsheet(formValues.termsheetId, {
          termsheet: inputData,
          sendEmailNotifications: shouldSendEmailNotifications,
          sendFeedNotifications: shouldSendFeedNotifications
        }),
        'Changes saved',
        'Could not save changes, please try again'
      )
    } else {
      result = await toastify(
        updateTermsheet(formValues.termsheetId, {
          termsheet: inputData,
          sendEmailNotifications: shouldSendEmailNotifications,
          sendFeedNotifications: shouldSendFeedNotifications
        }),
        'Changes saved',
        'Could not save changes, please try again'
      )
    }
    updateTermsheetView({ ...inputData, draft: false, attachments: result.attachments })

    setSelectedTermsheetId(formValues.termsheetId)

    if (isEditModeOnContext as boolean) {
      setIsEditModeOnContext(false)
    }

    setFormValues({})
  }

  const handlePriceDeal = async (data, shouldSendFeedNotifications: boolean, shouldSendEmailNotifications: boolean): Promise<any> => {
    const itemPricedAtDate = Date.now()
    const updateAtDate = Date.now()
    const state = TermsheetState.PRICED
    const result = await toastify(updateTermsheet(
      data.termsheetId,
      {
        termsheet: mergeDeepRight(data, {
          dealId: data.dealId,
          itemPricedAtDate,
          isTermsheetPriced: true,
          updateAtDate,
          state
        }),
        sendEmailNotifications: shouldSendEmailNotifications,
        sendFeedNotifications: shouldSendFeedNotifications
      }
    ),
    'Deal priced successfully',
    'Could not price deal, please try again'
    )
    const deals = dealsOrTermsheetsStore.deals
    const dashboardDeal = deals.find(deal => deal.dealId === result.dealId)
    if (dashboardDeal) {
      dashboardDeal.state = state
      dealsOrTermsheetsStore.deals = deals
    }
    updateTermsheetView(
      mergeDeepRight(data, {
        itemPricedAtDate,
        isTermsheetPriced: true,
        updateAtDate,
        state
      })
    )
    setIsEditModeOnContext(false)
    setIsPriceDealModeContext(false)

    setSelectedTermsheetId(data.termsheetId)
    setFormValues({})
  }
  const handleWithdrawDeal = async (data, shouldSendFeedNotifications: boolean, shouldSendEmailNotifications: boolean): Promise<any> => {
    try {
      const updateAtDate = Date.now()
      const state = TermsheetState.WITHDRAWN
      const updateResult = updateTermsheet(
        data.termsheetId,
        {
          termsheet: mergeDeepRight(data, {
            dealId: data.dealId,
            updateAtDate,
            state
          }),
          sendEmailNotifications: shouldSendEmailNotifications,
          sendFeedNotifications: shouldSendFeedNotifications
        }
      )
      const result = await toastify(updateResult,
        'Deal withdrawn successfully',
        'Could not withdraw deal, please try again'
      )
      updateFormValues(data)
      const storedDeal = getDeal(result.dealId) as Deal
      storedDeal.state = state
      setDeal(storedDeal)
      const deals = dealsOrTermsheetsStore.deals
      const dashboardDeal = deals.find(deal => deal.dealId === result.dealId)
      if (dashboardDeal) {
        dashboardDeal.state = state
        dealsOrTermsheetsStore.deals = deals
      }
      updateTermsheetView(
        mergeDeepRight(data, {
          state: state,
          updateAtDate
        })
      )
      setIsEditModeOnContext(false)
      setIsPriceDealModeContext(false)

      setSelectedTermsheetId(data.termsheetId)
      setFormValues({})
    } finally {
      setIsPriceDealDisabled(false)
    }
  }

  const handlePublishDeal = async (shouldSendFeedNotifications: boolean, shouldSendEmailNotifications: boolean): Promise<any> => {
    const existingDealIdState = dealIdContext as boolean && dealIdContext.length > 0 ? dealIdContext : false

    if (formValues.state === undefined) {
      formValues.state = TermsheetState.LIVE
    }

    if (formValues?.listingExchange !== undefined && formValues?.listingExchange.length > 0) {
      if (formValues?.listingExchange.some(e => saudiArabiaListingExchanges.includes(e.displayName)) === false) {
        formValues.distributionIntoSaudiArabia = undefined
      }
    } else {
      formValues.distributionIntoSaudiArabia = undefined
    }

    const apiTermsheetParams = {
      ...formValues,
      ...userProfile,
      dealId: existingDealIdState,
      itemCreatedAtDateClient: Date.now().toString()
    }

    let result: TermsheetV1Dto
    const IS_DRAFT = formValues.draft === true
    if (!IS_DRAFT) {
      result = await toastify<TermsheetV1Dto>(
        createTermsheet({
          termsheet: apiTermsheetParams,
          sendFeedNotifications: shouldSendFeedNotifications,
          sendEmailNotifications: shouldSendEmailNotifications
        }),
        'Published successfully, redirecting',
        'Could not publish, please try again'
      )
    } else {
      result = await toastify<TermsheetV1Dto>(
        createDraftTermsheet({
          termsheet: apiTermsheetParams,
          sendFeedNotifications: shouldSendFeedNotifications,
          sendEmailNotifications: shouldSendEmailNotifications
        }),
        'Published successfully, redirecting',
        'Could not publish, please try again'
      )
    }

    accountOrganizationId as any as boolean && history.push(`/termsheet/${result.dealId}`)
    setFormValues({})
  }

  const handleSubmit = async (shouldSendFeedNotifications: boolean, shouldSendEmailNotifications: boolean): Promise<any> => {
    return (isEditModeOnContext as boolean ? await handleEditTermsheet(shouldSendFeedNotifications, shouldSendEmailNotifications) : await handlePublishDeal(shouldSendFeedNotifications, shouldSendEmailNotifications))
  }

  return {
    handleSubmit,
    handlePriceDeal,
    handleWithdrawDeal
  }
}
