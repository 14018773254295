import { BANK_ROLES, BANK_ROLE_ABBREVIATIONS } from '../constants'

export function convertSyndicateBanks (otherSyndicateBanks): { allBanks: any[] } {
  // Create a result object;
  const resultObj: { allBanks: any[] } = {
    allBanks: []
  }
  // Check incoming params for errors
  if (!Array.isArray(otherSyndicateBanks)) {
    return resultObj
  } else if (otherSyndicateBanks.length === 0) {
    return resultObj
  }
  // For each role create an array of banks stored in the result object
  BANK_ROLES.forEach((ROLE) => {
    const role = otherSyndicateBanks
      // Filter the banks array on the role and store in object.
      .filter((val) => val.role === ROLE)
      // Map the correct name of the bank
      .map((bank) => bank.name)
    // Set the banks under the right role.
    if (role.length > 0) resultObj[BANK_ROLE_ABBREVIATIONS[ROLE]] = role

    // Also push the result to all banks
    resultObj.allBanks = resultObj.allBanks.concat(role)
  })
  return resultObj
}
