/**
 * This may need to be mocked in jest tests as import.meta
 * is not supported.
 *
 * The first argument to the URL object MUST be a string literal
 * for bundling reasons:
 * https://parceljs.org/languages/javascript/#url-dependencies
 */
export const ppLogoSmall = new URL('./logos/PP-small.jpeg', import.meta.url)
export const ppLogo = new URL('./logos/PP-logo.svg', import.meta.url)
export const ppLogoFull = new URL('./logos/primary-portal-color-full.svg', import.meta.url)
export const netRoadshowLogo = new URL('./logos/nrs.jpg', import.meta.url)
export const researchFnLogo = new URL('./logos/researchFN.png', import.meta.url)
export const prospectusRegistration = new URL('./logos/prospectus-registration.png', import.meta.url)

export const lockUpSample = new URL('./Lock-up_Sample.GIF', import.meta.url)
