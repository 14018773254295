import { ORGANIZATION_DASHBOARD_TYPE } from 'constants/organizations'
import { splitEvery } from 'ramda'
import { readFileAsData } from 'utils/readFileAsData'
import { API } from './aws-api'

interface LastUpdated { timestamp: string, email: string }

export interface Organization {
  id: string
  parentId?: string
  name: string
  type: string
  accountRole: string
  distributionStatus: string
  canAccessLockUpExpiry?: boolean
  canAccessDashboardForAll?: boolean
  dashboardType: ORGANIZATION_DASHBOARD_TYPE
  distributionStatusLastUpdate?: LastUpdated
  canAccessLockUpExpiryLastUpdate?: LastUpdated
  canAccessDashboardForAllLastUpdate?: LastUpdated
  canActAsAgentFor?: string[]
  dashboardTypeLastUpdate: LastUpdated
  country: string
  logo: {
    logo?: string
    url?: string
    size?: string
  }
}

export interface BookrunnerDTO {
  id: string
  parentId?: string
  name: string
  isPublishedByTaaS: boolean
}

export interface Bookrunner {
  id: string
  parentId?: string
  legalPublishingName: string
  isPublishedByTaaS: boolean
}

export interface SyndicateBankerDto {
  organizationId: string
  name: string
  email: string
  phone?: string
}

export const uploadOrganizationsCSV = async (file: Blob | undefined): Promise<void> => {
  const [header, ...data] = (await readFileAsData(file)).split('\n')
  const parts = splitEvery(2000, data)
  await Promise.all(
    parts.map(async (part) => await API().post({
      path: '/v2/organizations/bulk-upload',
      init: {
        body: {
          data: { file: [header, ...part].join('\n') }
        }
      }
    })
    ))
}

export const downloadOrganizationsCSV = async (): Promise<Blob> => {
  const csv = await API<string>().get({
    init: { headers: { Accept: 'application/CSV' } },
    path: '/v2/organizations'
  })
  const blob = new Blob([csv], { type: 'application/CSV;charset=utf-8' })
  return blob
}

export async function getOrganizations (): Promise<Organization[]> {
  return await API<Organization[]>().get({ path: '/v2/organizations' })
}

export async function getBankerOrganizations (): Promise<Organization[]> {
  return await API<Organization[]>().get({ path: '/v2/organizations/bankers' })
}

export async function updateOrganization (id: string, data): Promise<Organization> {
  return await API<Organization>().patch({ path: `/v2/organizations/${id}`, init: { body: data } })
}

export async function getSyndicateBankers (): Promise<SyndicateBankerDto[]> {
  return await API<SyndicateBankerDto[]>().get({ path: '/v2/organizations/syndicate-bankers' })
}

const bookrunnersDtoToBookrunners = (bookrunner: BookrunnerDTO): Bookrunner => ({
  id: bookrunner.id,
  legalPublishingName: bookrunner.name,
  isPublishedByTaaS: bookrunner.isPublishedByTaaS,
  parentId: bookrunner.parentId
})

export async function getBookrunners (): Promise<Bookrunner[]> {
  const bookrunnersDto = await API<BookrunnerDTO[]>().get({ path: '/v2/bookrunners' })
  return bookrunnersDto.map(bookrunnersDtoToBookrunners)
}
