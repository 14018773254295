import React from 'react'
import { SingleColumnRow } from './SingleColumnRow'
import { REG_S_ONLY } from '../../../../../constants'

interface UsaDistributionRowProps{
  label: string
  tooltipText?: string
  isChanged: boolean
  termsheetValue: string
}

export const UsaDistributionRow =
    ({ label, termsheetValue, tooltipText, isChanged }: UsaDistributionRowProps): JSX.Element => {
      // In case of USA distribution when termsheetValue is REG_S_ONLY a different value needs to be displayed
      const modifiedTermsheetValue = termsheetValue === REG_S_ONLY ? 'Reg S only' : termsheetValue

      return (
        <SingleColumnRow
          label={label} termsheetValue={modifiedTermsheetValue}
          tooltipText={tooltipText} isChanged={isChanged}
        />
      )
    }
