import clsx from 'clsx'
import React, { ReactNode } from 'react'

export interface ButtonProps {
  children: ReactNode
  className?: string
  disabled?: boolean
  handler?: (a: any) => any
  dataQa?: string
  removed?: boolean
}

export function PrimaryButton (props: ButtonProps): JSX.Element {
  const classNames = clsx(
    'text-white',
    props.disabled !== true ? 'bg-indigo-600 hover:bg-indigo-700' : 'bg-indigo-300 hover:bg-indigo-300',
    props.className
  )
  if (props.removed === true) return <div />
  return (<Button {...props} className={classNames} />
  )
}

export function SecondaryButton (props: ButtonProps): JSX.Element {
  const classNames = clsx(
    'text-gray-700 border-gray-300 hover:text-white',
    props.disabled !== true ? 'bg-white hover:bg-gray-50' : 'bg-white hover:bg-white',
    props.className
  )
  return <Button {...props} className={classNames} />
}

export function Button ({ disabled, children, handler, className, dataQa }: ButtonProps): JSX.Element {
  const classnames = clsx(
    'w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 hover:bg-indigo-700',
    className
  )

  return (
    <button
      disabled={disabled}
      type={handler !== undefined ? 'button' : 'submit'}
      onClick={handler}
      className={classnames}
      data-qa={dataQa}
    >
      {children}
    </button>
  )
}
