import PropTypes from 'prop-types'
import React, { createContext, useState } from 'react'

export const DealContext = createContext<any>(undefined)

export function DealsProvider ({ children }): JSX.Element {
  const [dealIdContext, setDealIdContext] = useState('')
  const [issuerNameOfferingTypeContext, setIssuerNameOfferingTypeContext] = useState({
    issuerName: null,
    offeringType: null
  })

  const [isEditModeOnContext, setIsEditModeOnContext] = useState(false)
  const [isPriceDealModeContext, setIsPriceDealModeContext] = useState(false)
  const [isPriceDealDisabled, setIsPriceDealDisabled] = useState(false)
  const [isBankerViewContext, setIsBankerViewContext] = useState(true)

  const toggleBankerView = (): void => setIsBankerViewContext(!isBankerViewContext)

  return (
    <DealContext.Provider
      value={{
        dealIdContext,
        isPriceDealDisabled,
        setIsPriceDealDisabled,
        setDealIdContext,
        issuerNameOfferingTypeContext,
        setIssuerNameOfferingTypeContext,
        toggleBankerView,
        isBankerViewContext,
        isEditModeOnContext,
        setIsEditModeOnContext,
        isPriceDealModeContext,
        setIsPriceDealModeContext
      }}
    >
      {children}
    </DealContext.Provider>
  )
}

DealsProvider.propTypes = {
  children: PropTypes.node.isRequired
}
