/**
 * IF YOU CHANGE THIS FILE,
 * YOU MUST ALSO UPDATE THE MATCHING FILE IN THE API REPO
 */
import {
  issuerName,
  publishingBankName,
  publishingBankRole,
  syndicateBanker,
  researchfnLink,
  netroadshowLink,
  prospectusRegistrationLink,
  offeringType,
  primarySecondaryString,
  issuerLegalName,
  underwritingSplit,
  attachments
} from '../../formTypes/inputFields'

export const formConfigStep1 = [
  issuerName,
  issuerLegalName,
  publishingBankName,
  publishingBankRole,
  offeringType,
  primarySecondaryString,
  syndicateBanker,
  underwritingSplit,
  researchfnLink,
  netroadshowLink,
  prospectusRegistrationLink,
  attachments
]
