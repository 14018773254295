import React from 'react'

import styled from '@emotion/styled'

interface HeadingButtonProps {
  label: string
  onClick: any
  'data-qa'?: string
}

export function HeadingButton ({ label, onClick, 'data-qa': dataQA }: HeadingButtonProps): JSX.Element {
  return (
    <SendTermsheetStyled onClick={onClick} data-qa={dataQA}>
      <span>{label}</span>
    </SendTermsheetStyled>
  )
}

const SendTermsheetStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 10px;
  font-weight: bold;

  > span {
    background: ${({ theme }) => theme.grey_100};
    padding: 7px 15px;
    border-radius: 25px;
    cursor: pointer;
  }
`
