import React from 'react'
import { SingleColumnRow } from './SingleColumnRow'

interface StockExchange {
  displayName: string
  marketIdentifierCode: string
  countryAlpha3ISOCode: string
  sortOrder: number | null
}

interface ListingExchangesRowProps{
  label: string
  exchanges: StockExchange[] | null
  tooltipText?: string|Object
  isChanged: boolean
}

export const ListingExchangeRow =
({ label, exchanges, tooltipText, isChanged }: ListingExchangesRowProps): JSX.Element => {
  const termsheetValue = Array.isArray(exchanges) && (exchanges.length > 0)
    ? exchanges.map(({ displayName }) => `${displayName}`)
    : '-'

  return (
    <SingleColumnRow
      label={label} termsheetValue={termsheetValue}
      isChanged={isChanged} tooltipText={tooltipText as string}
    />
  )
}
