import styled from '@emotion/styled'
import clsx from 'clsx'
import Linkify from 'linkify-react'
import punycode from 'punycode/'
import React from 'react'
import { linkifyDefaults } from '../../../../../config/linkifyDefaults'
import { TooltipReusable } from '../../../../TooltipReusable'

const componentDecorator = (href, text, key): JSX.Element => (
  <a href={href} key={key}>
    {punycode.toASCII(text)}
  </a>
)

export const SingleColumnRow = ({ label, termsheetValue, tooltipText, isChanged, contentHeavy }: SingleColumnRowProps): JSX.Element => {
  const isContentHeavy = contentHeavy ?? termsheetValue.toString().length > 30

  const classnames = clsx(
    isContentHeavy ? 'text-left' : 'text-right'
  )

  if (termsheetValue === '-') {
    return <></>
  }

  return (
    <SingleColumnRowStyled
      className='row' isChanged={isChanged}
      isContentHeavy={isContentHeavy} data-qa='ViewTermsheet-Field'
    >
      <div className='row-header'>
        <p>{label}</p>
        {tooltipText !== undefined && <TooltipReusable helperText={tooltipText} />}
      </div>
      <div className={classnames}>
        {Array.isArray(termsheetValue)
          ? termsheetValue.map((val, idx) => (
            <Linkify
              options={{ target: '_blank', className: 'text-purple-800 font-bold' }}
              key={`${label}-${idx}`}
              {...componentDecorator}
            >
              <div className='text-right'>{val}</div>
            </Linkify>))
          : <Linkify options={linkifyDefaults} {...componentDecorator}>{termsheetValue}</Linkify>}
      </div>
    </SingleColumnRowStyled>
  )
}

interface SingleColumnRowProps{
  label: string
  termsheetValue: string| string[] | number | boolean
  tooltipText?: string
  isChanged: boolean
  contentHeavy?: boolean
}

interface SingleColumnRowStyledProps {
  isChanged: boolean
  isContentHeavy: boolean
}

const SingleColumnRowStyled = styled.div<SingleColumnRowStyledProps>`
  background: ${({ isChanged, theme }) => isChanged && theme.yellow_100};

  display: ${({ isContentHeavy }) => (isContentHeavy ? 'block' : 'grid')};
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 5px;
  align-items: center;

  p {
    font-size: 12px;
  }

  & > p {
    text-align: ${({ isContentHeavy }) => (isContentHeavy ? 'left' : 'end')};
  }
`
