import { useUserPreferences } from 'lib'
import { useCallback, useState } from 'react'
import { QUERY_DASHBOARD, QUERY_DASHBOARD_V2 } from 'constants/queries'
import { getDealsActions } from 'store/store'
import { useQueryClient } from 'react-query'
import { IDashboardForAllHook, IViewTypeHook, ViewType } from 'routes/DashboardPageV2/components/ActionBar/types'
import { useStores } from 'store/rootStore'

export const useDashboardForAll = (): IDashboardForAllHook => {
  const queryClient = useQueryClient()
  const { dealsOrTermsheetsStore } = useStores()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [
    isUsingDashboardForAll,
    setIsUsingDashboardForAll
  ] = useUserPreferences<boolean>(['isUsingDashboardForAll'], { immediate: true })

  const changeDashboardForAllAccess = useCallback((): void => {
    setIsLoading(true)
    setIsUsingDashboardForAll(!isUsingDashboardForAll)
      .then(async () => {
        dealsOrTermsheetsStore.refreshCacheKey()
        dealsOrTermsheetsStore.dealsV2 = []
        await queryClient.invalidateQueries([QUERY_DASHBOARD])
        await queryClient.invalidateQueries([QUERY_DASHBOARD_V2])

        // This line is needed for refreshing termsheets for a deal on view mode
        // after switching between MyDeals/AllDeals
        // It should be refactored as soon we refactor old view termsheet page
        await getDealsActions().refreshFromBackend()
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [setIsUsingDashboardForAll, isUsingDashboardForAll, dealsOrTermsheetsStore, queryClient])
  return {
    changeDashboardForAllAccess,
    isLoading,
    isUsingDashboardForAll
  }
}

export const useViewType = (): IViewTypeHook => {
  const [
    viewType,
    setViewType
  ] = useUserPreferences<ViewType>(['viewType'], { quickUpdate: true })

  const changeViewType = useCallback((viewType: ViewType): void => {
    void setViewType(viewType)
  }, [setViewType])

  return {
    changeViewType,
    viewType: viewType || 'grid'
  }
}
