import styled from '@emotion/styled'
import { FormHelperText } from '@material-ui/core'
import { useGetSyndicateBankers } from 'lib/queries/useGetSyndicateBankers'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { DropdownHooked } from '../../../components/Dropdowns/DropdownHooked'
import { LoadingSpinner } from '../../../components/LoadingSpinner'
import { TextBox } from '../../../components/TextBox'
import { TextField } from '../../../components/TextField/MainTextField'
import { ORGANIZATION_TYPES } from '../../../constants/organizations'
import { useProfileHook } from '../../../contexts/user-auth-context'
import { getSyndicateDeskOptions } from '../../../utils/getSyndicateDeskOptions'
import { InfoMessageStyled } from '../Step1Form/styled'
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */

interface SyndicateBankerFieldProps {
  control: any
  watch: any
  setValue: any
  register: any
  label?: string
  name?: string
  errors: any
}

export const SyndicateBankerField = (
  { watch, errors, control, setValue, label, name, register }: SyndicateBankerFieldProps
): JSX.Element => {
  const [profile] = useProfileHook()

  const { email, family_name, given_name, phone_number } = profile

  const bankersQuery = useGetSyndicateBankers()

  const bankers = useMemo(() => {
    const bankers = bankersQuery.data ?? []
    return bankers
  }, [bankersQuery.data])

  const syndicateDeskOptions = {
    [`${given_name} ${family_name}`]: {
      designatedBankerPhone: phone_number,
      designatedBankerEmail: email
    },
    ...getSyndicateDeskOptions(bankers)
  }

  return (
    <>
      {bankersQuery.isSuccess
        ? <SyndicateBankerFieldProxy
            setValue={setValue}
            watch={watch}
            control={control}
            errors={errors}
            syndicateDeskOptions={syndicateDeskOptions}
            label={label}
            name={name}
            register={register}
          />
        : <LoadingSpinner />}
    </>
  )
}

const SyndicateBankerFieldProxy = ({ syndicateDeskOptions, watch, errors, control, setValue, label, name, register }): JSX.Element => {
  const [profile] = useProfileHook()
  const { accountType } = profile

  const designatedBankerName = watch(name)
  const banker = syndicateDeskOptions[designatedBankerName]

  const designatedBankerEmail = banker && banker.designatedBankerEmail

  const designatedBankerPhone = banker && banker.designatedBankerPhone

  const isTaasAccount = ORGANIZATION_TYPES.PRIMARY_PORTAL_AGENT === accountType

  React.useEffect(() => {
    // For TaaS Account Types we use regular text fields
    // For not TaaS (=BANKS) we get the contact details from the account profile.
    if (!isTaasAccount) {
      register({ name: 'designatedBankerEmail' })
      register({ name: 'designatedBankerPhone' })
      register({ name: 'designatedBankerName' })

      !!designatedBankerEmail && setValue('designatedBankerEmail', designatedBankerEmail)
      !!designatedBankerPhone && setValue('designatedBankerPhone', designatedBankerPhone)
    }
  }, [register, setValue, designatedBankerEmail, designatedBankerPhone, designatedBankerName, isTaasAccount])

  return (
    <div>
      {!isTaasAccount && (
        <DropdownHooked optionsArray={Object.keys(syndicateDeskOptions).sort()} label={label} name={name} watch={watch} control={control} errors={errors} />
      )}
      {designatedBankerName === 'Add new syndicate banker' && (
        <FormHelperText error>This functionality is coming soon. Please choose another option</FormHelperText>
      )}

      {isTaasAccount && (
        <>
          <InfoMessageStyled>Please fill in the contact details of the relevant syndicate banker.</InfoMessageStyled>
          <TextField errors={errors} inputRef={register} label='Full banker name' name='designatedBankerName' />
          <TextField errors={errors} inputRef={register} label='Email' name='designatedBankerEmail' />
          <TextField errors={errors} inputRef={register} label='Mobile phone' name='designatedBankerPhone' />
          <br />
          <br />
        </>
      )}

      {!isTaasAccount && (
        <TextBoxStyled disabled={designatedBankerName}>
          <TextBox text={designatedBankerName} placeholder='Name' />
          <TextBox text={designatedBankerEmail} placeholder='Email' />
          <TextBox text={designatedBankerPhone} placeholder='Phone number' />
        </TextBoxStyled>
      )}
    </div>
  )
}

SyndicateBankerFieldProxy.propTypes = {
  syndicateDeskOptions: PropTypes.object.isRequired,
  control: PropTypes.object.isRequired,
  watch: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  label: PropTypes.string,
  name: PropTypes.string,
  errors: PropTypes.object.isRequired
}

interface TextBoxStyledProps {
  disabled: boolean
}

const TextBoxStyled = styled.div<TextBoxStyledProps>`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  color: ${({ disabled }) => (disabled ? '#7F7F7F' : '#000')};

  & > div {
    margin: 12px 0 0;
    border: 1px solid #d8d8d8;
  }

  & > div:first-of-type,
  & > div:nth-of-type(2) {
    width: 48%;
  }

  & > div:last-of-type {
    width: 100%;
  }
`
