import { API } from 'services/aws-api'
import { TermsheetState } from 'constants/index'

export interface TermsheetV1Dto {
  dealId: string
  termsheetId: string
  [key: string]: unknown
}

export async function getTermsheetsByDeal (
  dealId: string
): Promise<{
    issuerName: string
    publishingBankName: string
    termsheets: TermsheetV1Dto[]
    state: TermsheetState
  }> {
  return await API<{
    issuerName: string
    publishingBankName: string
    termsheets: TermsheetV1Dto[]
    state: TermsheetState
  }>().get({
    path: `/v2/deals/${dealId}/termsheets`
  })
}

export async function getTermsheetByDealIdAndTermsheetId (
  dealId: string,
  termsheetId: string
): Promise<{
    issuerName: string
    publishingBankName: string
    termsheet: TermsheetV1Dto
    state: TermsheetState
  }> {
  return await API<{
    issuerName: string
    publishingBankName: string
    termsheet: TermsheetV1Dto
    state: TermsheetState
  }>().get({
    path: `/v2/deals/${dealId}/termsheets/${termsheetId}`
  })
}

export async function getTermsheetsById (
  termsheetId: string
): Promise<{
    issuerName: string
    publishingBankName: string
    termsheet: TermsheetV1Dto
    state: TermsheetState
  }> {
  return await API<{
    issuerName: string
    publishingBankName: string
    termsheet: TermsheetV1Dto
    state: TermsheetState
  }>().get({
    path: `/v2/termsheets/${termsheetId}`
  })
}

export async function getAllTermsheetsById (
  termsheetId: string
): Promise<{
    issuerName: string
    publishingBankName: string
    termsheets: TermsheetV1Dto[]
    state: TermsheetState
  }> {
  return await API<{
    issuerName: string
    publishingBankName: string
    termsheets: TermsheetV1Dto[]
    state: TermsheetState
  }>().get({
    path: `/v2/deals/find-termsheets-by-termsheet-id/${termsheetId}`
  })
}

export async function createDraftTermsheet (
  termsheet: Record<string, any>
): Promise<TermsheetV1Dto> {
  return await API<TermsheetV1Dto>().post({
    path: '/v2/termsheets/drafts',
    init: {
      body: {
        termsheet
      }
    }
  })
}

export async function updateDraftTermsheet (
  termsheetId: string,
  termsheet: Record<string, any>
): Promise<TermsheetV1Dto> {
  return await API<TermsheetV1Dto>().put({
    path: `/v2/termsheets/drafts/${termsheetId}`,
    init: {
      body: {
        termsheet
      }
    }
  })
}

export async function deleteDraftTermsheet (
  termsheetId: string
): Promise<TermsheetV1Dto> {
  return await API<TermsheetV1Dto>().del({
    path: `/v2/termsheets/drafts/${termsheetId}`
  })
}

export async function publishDraftTermsheet (
  termsheetId: string,
  {
    termsheet,
    sendFeedNotifications,
    sendEmailNotifications
  }: {
    termsheet: Record<string, any>
    sendFeedNotifications: boolean
    sendEmailNotifications: boolean
  }
): Promise<TermsheetV1Dto> {
  return await API<TermsheetV1Dto>().post({
    path: `/v2/termsheets/drafts/${termsheetId}/publish`,
    init: {
      body: {
        termsheet,
        sendFeedNotifications,
        sendEmailNotifications
      }
    }
  })
}

export async function createTermsheet ({
  termsheet,
  sendFeedNotifications,
  sendEmailNotifications
}: {
  termsheet: Record<string, any>
  sendFeedNotifications: boolean
  sendEmailNotifications: boolean
}): Promise<TermsheetV1Dto> {
  return await API<TermsheetV1Dto>().post({
    path: '/v2/termsheets',
    init: {
      body: {
        termsheet,
        sendFeedNotifications,
        sendEmailNotifications
      }
    }
  })
}

export async function updateTermsheet (
  termsheetId: string,
  {
    termsheet,
    sendFeedNotifications,
    sendEmailNotifications
  }: {
    termsheet: Record<string, any>
    sendFeedNotifications: boolean
    sendEmailNotifications: boolean
  }): Promise<TermsheetV1Dto> {
  return await API<TermsheetV1Dto>().put({
    path: `/v2/termsheets/${termsheetId}`,
    init: {
      body: {
        termsheet,
        sendFeedNotifications,
        sendEmailNotifications
      }
    }
  })
}
