import Switch from 'react-switch'
import React, { ReactElement } from 'react'
import { LoadingSpinner } from 'components/LoadingSpinner'
import styled from '@emotion/styled'
import { observer } from 'mobx-react-lite'
import { useStores } from 'store/rootStore'
import { useDashboardForAll } from 'routes/DashboardPageV2/components/Layout/hooks'

export const DashboardForAllSwitcher = observer(
  (): ReactElement => {
    const { userStore } = useStores()
    const canSeeDashboardForAllSwitch: boolean = userStore.user?.config?.canSeeDashboardForAllSwitch ?? false
    const { isLoading, changeDashboardForAllAccess, isUsingDashboardForAll } = useDashboardForAll()

    if (!canSeeDashboardForAllSwitch) return <></>

    return (
      <div>
        <InlineDiv>
          {/* TODO: move Switch to ui library */}
          <Switch
            disabled={isLoading}
            onChange={() => changeDashboardForAllAccess()}
            checked={isUsingDashboardForAll}
            className='react-switch'
            checkedIcon={(<LabelTextOn>All&nbsp;Deals</LabelTextOn>)}
            uncheckedIcon={(<LabelTextOff>My&nbsp;Deals</LabelTextOff>)}
            offColor='#0076d7'
            onColor='#003399'
            width={95}
          />
        </InlineDiv>
        <RightSpinnerDiv>{isLoading ? <LoadingSpinner size={30} inline /> : false}</RightSpinnerDiv>
      </div>
    )
  }
)
const LabelBase = styled.div`
  color: white;
  font-weight: bold;
  color: white;
  position: relative;
  top: 5px;
`

const LabelTextOff = styled(LabelBase)`
  left: -18px;
  alignitems: left;
  justifycontent: left;
`
const LabelTextOn = styled(LabelBase)`
  left: 5px;
  alignitems: right;
  justifycontent: right;
`

const InlineDiv = styled.div`
  display: inline-block;
`

const RightSpinnerDiv = styled(InlineDiv)`
  float: right;
  margin-left: 5px;
`
