import { DateTime } from 'luxon'
import React from 'react'
import { Controller, FormProvider } from 'react-hook-form'
import { DismissButton, PublishPriceButton } from 'components/Buttons/TermsheetInformation/PriceDealModalButtons'
import { DatePickerComponent } from 'components/DatePicker'
import { getErrorLine } from 'components/ErrorLine/getErrorLine'
import { FormLabel } from 'components/FormLabel'
import { getWithDrawConfig } from 'config/formConfigs/configs/priceDealConfig'
import { useDealFormContext } from 'containers/DealForm/context'
import { PriceDealDatePickerStyled, PriceDealModalStyled } from '../PriceDealModal/styles'
import { useWithdrawModalForm } from './useWithdrawModalForm'
interface WithdrawModalProps {
  setIsModalOpen: (boolean) => void
}
export function WithdrawModal ({ setIsModalOpen }: WithdrawModalProps): JSX.Element {
  const { setFormValues, formValues } = useDealFormContext()
  const { formMethods, onSubmit } = useWithdrawModalForm()
  const datePickers = getWithDrawConfig()
  const datePicker = (picker: any): JSX.Element => (
    <div key={picker.name}>
      <FormLabel>{picker.label}</FormLabel>
      <Controller
        as={
          <PriceDealDatePickerStyled>
            <DatePickerComponent
              handleDateChange={(value) => {
                const formattedValue = DateTime.fromHTTP(value).setZone('UTC', { keepLocalTime: true })
                  .set({ hour: 12, minute: 0, second: 0 }).toHTTP()
                formMethods.setValue(picker.name, formattedValue, {
                  shouldValidate: true
                })
              }}
              selectedDate={formMethods.watch(picker.name)}
            />
          </PriceDealDatePickerStyled>
        }
        control={formMethods.control}
        name={picker.name}
        rules={{ required: true }}
        defaultValue=''
      />
      {getErrorLine(formMethods.errors, picker.name, picker.label)}
    </div>
  )

  const offeringType = formValues.offeringType
  return (
    <PriceDealModalStyled offeringType={offeringType}>
      <h2 data-qa='WithdrawModalHeader'>Withdrawal details</h2>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit((data) => onSubmit(data))}>

          <div className='price-deal-pickers'>
            {datePickers.map(datePicker)}
          </div>

          <div className='price-deal-buttons'>
            <div className='price-deal-buttons-line' />
            <DismissButton
              onClick={() => {
                setFormValues({})
                setIsModalOpen(false)
              }}
            />
            <PublishPriceButton type='submit' title='Withdraw' />
          </div>
        </form>
      </FormProvider>
    </PriceDealModalStyled>
  )
}
