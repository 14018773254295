/* eslint-disable react/no-unused-prop-types */
import React from 'react'
import PropTypes from 'prop-types'

import FormControl from '@material-ui/core/FormControl'
import { ThemeProvider } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { FormLabel } from '../../FormLabel'
import { DropdownContainer, useDropdownStyles, dropdownTheme } from '../DropdownHooked/styled'

export const LabelDropdownNoHook = (props): JSX.Element => {
  const handleOnClick = (e): void => {
    props.handleChange(name, e.target.value)
  }

  const classes = useDropdownStyles()

  const { title, value, width, name, isMultiple, label, optionsArray, defaultValue } = props

  return (
    <DropdownContainer width={width}>
      <ThemeProvider theme={dropdownTheme}>
        <FormLabel>{title ?? label}</FormLabel>
        <FormControl className={classes.formControl}>
          <Select multiple={!!(isMultiple as boolean)} id={name} value={value} onChange={handleOnClick} disableUnderline className={`${classes.select}`} defaultValue={defaultValue}>
            {optionsArray.map((item, index) => (
              <MenuItem value={item.value} key={index}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </ThemeProvider>
    </DropdownContainer>
  )
}

LabelDropdownNoHook.propTypes = {
  handleChange: PropTypes.func.isRequired,
  optionsArray: PropTypes.instanceOf(Array),
  title: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.string,
  isMultiple: PropTypes.bool,
  valueArray: PropTypes.instanceOf(Array),
  array: PropTypes.instanceOf(Array),
  defaultValue: PropTypes.any
}

LabelDropdownNoHook.defaultProps = {
  value: '',
  width: '100%',
  isMultiple: false,
  valueArray: null
}
