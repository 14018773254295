/**
 * IF YOU CHANGE THIS FILE,
 * YOU MUST ALSO UPDATE THE MATCHING FILE IN THE API REPO
 */
import {
  sellingShareholders,
  otherSyndicateBanks,
  offeringSharePriceRange,
  offeringSizeNrSharesPrimary,
  offeringSizeNrSharesSecondary,
  offeringCurrencyType,
  distribution,
  listingExchange,
  stockTickerCode,
  isinNumber,
  securityType,
  freeFloat,
  upsizeOption,
  greenshoe,
  commitments,
  issuerDomicile,
  useOfProceeds,
  headerDisclaimerText,
  disclaimerText,
  mifidTargetMarket,
  offeringSizeValue,
  additionalInfo,
  otherSellingRestrictions,
  percentCapitalIncrease,
  offeringSizeNrShares,
  lockUpExpiryDays,
  finalOfferingSizeNrShares,
  finalOfferingSizeNrSharesPrimary,
  finalOfferingSizeNrSharesSecondary,
  finalOfferingSizeValue,
  finalOfferingSharePrice,
  otherSecurityType,
  greenshoePrimaryShares,
  greenshoeSecondaryShares,
  minimumOrder,
  allocationCriteria,
  commissions,
  marketCap,
  documentation,
  otherOfferingType,
  sector,
  businessDescription,
  issuedShareCapital,
  conditionsForCompletion,
  distributionIntoSaudiArabia,
  discountRange, previousClose, domesticSplit, demandAllocationPolicy, underwritingShare, fxRate
} from '../../formTypes/inputFields'

import {
  dealAnnouncementDate,
  researchPublicationDate,
  startOfBookbuildingDate,
  closeOfBookbuildingDate,
  pricingDate,
  startOfTradingDate,
  settlementDate,
  finalSettlementDate,
  expectedPricingDate,
  prospectusPublicationDate,
  lastDayOfCRTradingDate,
  startOfRightsTradingDate,
  startOfSubscriptionPeriodDate,
  endOfRightsTradingDate,
  endOfSubscriptionPeriodDate,
  startOfTradingNewSharesDate,
  settlementOfNewSharesDate,
  rumpPlacementDate,
  exDate,
  recordDate,
  egmDate
} from '../../formTypes/datePickers'

export const mainQuestionsOth = [
  headerDisclaimerText,
  otherSyndicateBanks,
  otherOfferingType,
  securityType,
  otherSecurityType,
  useOfProceeds,
  sellingShareholders,
  lockUpExpiryDays,
  offeringSharePriceRange,
  finalOfferingSharePrice,
  offeringCurrencyType,
  offeringSizeNrShares,
  offeringSizeNrSharesPrimary,
  offeringSizeNrSharesSecondary,
  finalOfferingSizeNrShares,
  finalOfferingSizeNrSharesPrimary,
  finalOfferingSizeNrSharesSecondary,
  offeringSizeValue,
  finalOfferingSizeValue,
  distribution,
  otherSellingRestrictions,
  listingExchange,
  distributionIntoSaudiArabia,
  stockTickerCode,
  isinNumber,
  disclaimerText
]

export const additionalQuestionsOth = [
  issuerDomicile,
  percentCapitalIncrease,
  upsizeOption,
  freeFloat,
  commitments,
  greenshoe,
  greenshoePrimaryShares,
  greenshoeSecondaryShares,
  mifidTargetMarket,
  minimumOrder,
  allocationCriteria,
  documentation,
  commissions,
  marketCap,
  conditionsForCompletion,
  issuedShareCapital,
  sector,
  businessDescription,
  additionalInfo
]

export const timetableOth = [
  dealAnnouncementDate,
  researchPublicationDate,
  startOfBookbuildingDate,
  closeOfBookbuildingDate,
  recordDate,
  egmDate,
  prospectusPublicationDate,
  lastDayOfCRTradingDate,
  exDate,
  startOfRightsTradingDate,
  startOfSubscriptionPeriodDate,
  endOfRightsTradingDate,
  endOfSubscriptionPeriodDate,
  startOfTradingNewSharesDate,
  settlementOfNewSharesDate,
  startOfTradingDate,
  rumpPlacementDate,
  settlementDate,
  finalSettlementDate,
  pricingDate,
  expectedPricingDate
]

export const additionalQuestionsOthApac = [
  discountRange,
  previousClose,
  domesticSplit,
  demandAllocationPolicy,
  underwritingShare,
  fxRate
]
