/**
 * IF YOU CHANGE THIS FILE,
 * YOU MUST ALSO UPDATE THE MATCHING FILE IN THE API REPO
 */
import {
  otherSyndicateBanks,
  offeringCurrencyType,
  distribution,
  lockUpExpiryDays,
  listingExchange,
  issuerDomicile,
  useOfProceeds,
  offeringMaturity,
  issuePrice,
  couponRange,
  conversionPremium,
  referenceSharePrice,
  underlyingSecurity,
  investorPut,
  antiDilutionProtection,
  settlementUponConversion,
  changeOfControl,
  issuerCall,
  headerDisclaimerText,
  disclaimerText,
  mifidTargetMarket,
  securityTypeEQL,
  issuerRating,
  offeringSizeValue,
  additionalInfo,
  otherSellingRestrictions,
  bondRating,
  finalOfferingSizeValue,
  denomination,
  dividendProtection,
  greenshoePrimaryShares,
  greenshoeSecondaryShares,
  stockTickerCode,
  stabAgent,
  yieldToMaturity,
  redemptionPrice,
  creditSpread,
  costOfBorrow,
  impliedVolatility,
  delta,
  pricingAssumptionsSection,
  isinOfTheBond,
  statusOfTheBonds,
  formOfTheBonds,
  initialConversionPrice,
  conversionRights,
  conversionPeriod,
  cleanUpCall,
  taxCallGrossUp,
  crossDefaultAcceleration,
  negativePledge,
  eventOfDefault,
  settlementUponRedemption,
  guarantor,
  fairMarketCallPut,
  governingLaw,
  emptySpaceSection,
  securitiesOffered,
  exchangeProperty,
  exchangePremium,
  businessDescription,
  sector,
  distributionIntoSaudiArabia,
  finalCoupon,
  finalYieldToMaturity,
  finalConversionPremium,
  finalIssuePrice
} from '../../formTypes/inputFields'

import {
  dealAnnouncementDate,
  startOfBookbuildingDate,
  closeOfBookbuildingDate,
  settlementDate,
  pricingDate,
  expectedPricingDate,
  maturityDate,
  lastDayOfStabTrading,
  finalSettlementDate
} from '../../formTypes/datePickers'

export const mainQuestionsEql = [
  securityTypeEQL,
  otherSyndicateBanks,
  offeringCurrencyType,
  offeringSizeValue,
  finalCoupon,
  finalYieldToMaturity,
  finalConversionPremium,
  finalIssuePrice,
  finalOfferingSizeValue,
  stockTickerCode,
  offeringMaturity,
  issuePrice,
  redemptionPrice,
  couponRange,
  yieldToMaturity,
  conversionPremium,
  useOfProceeds,
  distribution,
  mifidTargetMarket,
  otherSellingRestrictions,
  lockUpExpiryDays,
  headerDisclaimerText,
  disclaimerText
]

export const additionalQuestionsEql = [
  greenshoePrimaryShares,
  greenshoeSecondaryShares,
  stabAgent,
  listingExchange,
  distributionIntoSaudiArabia,
  issuerRating,
  bondRating,
  issuerDomicile,
  securitiesOffered,
  exchangeProperty,
  exchangePremium,
  underlyingSecurity,
  referenceSharePrice,
  issuerCall,
  investorPut,
  antiDilutionProtection,
  settlementUponConversion,
  changeOfControl,
  denomination,
  dividendProtection,
  pricingAssumptionsSection,
  creditSpread,
  costOfBorrow,
  impliedVolatility,
  delta,
  emptySpaceSection,
  isinOfTheBond,
  statusOfTheBonds,
  formOfTheBonds,
  initialConversionPrice,
  conversionRights,
  conversionPeriod,
  cleanUpCall,
  taxCallGrossUp,
  crossDefaultAcceleration,
  negativePledge,
  eventOfDefault,
  settlementUponRedemption,
  guarantor,
  fairMarketCallPut,
  governingLaw,
  sector,
  businessDescription,
  additionalInfo
]

export const timetableEql = [
  dealAnnouncementDate,
  startOfBookbuildingDate,
  closeOfBookbuildingDate,
  pricingDate,
  expectedPricingDate,
  settlementDate,
  finalSettlementDate,
  maturityDate,
  lastDayOfStabTrading
]
