/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import clsx from 'clsx'
import React, { ReactNode, useState } from 'react'
import { Input } from '@ppui/ui-components'
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import { Button } from '../Button'

export interface ConfirmActionFormProps{
  confirmationText: string
  onSuccess: () => void
  dataQa?: string
}

interface ConfirmInputFieldProps{
  placeholder: string
  isError: boolean
  onChange: (event) => void
  dataQa?: string
}

interface ButtonProps {
  children: ReactNode
  className?: string
  disabled?: boolean
  handler: () => any
  dataQa?: string
}
export interface ValidationErrorTextProps {
  message: string
}

export function ConfirmActionForm ({ confirmationText, onSuccess, dataQa }: ConfirmActionFormProps): JSX.Element {
  const placeholder = `type ${confirmationText} to confirm`
  const [submission, setSubmission] = useState('')
  const [isError, setIsError] = useState(false)

  const handleSubmit = (): void => {
    if (submission === confirmationText) {
      onSuccess()
    } else {
      setIsError(true)
    }
  }

  return (
    <div className='flex flex-row gap-x-7 justify-center mt-4'>
      <div className='flex-initial'>
        <ConfirmInputField
          placeholder={placeholder} onChange={(event) => {
            setSubmission(event.target.value)
          }}
          dataQa={dataQa}
          isError={isError}
        />
        {isError && (<ValidationErrorText message={`Please type '${confirmationText}'`} />)}
      </div>
      <div className='flex-initial'>
        <ConfirmButton handler={handleSubmit} dataQa={dataQa}>Confirm</ConfirmButton>
      </div>
    </div>
  )
}

export function ConfirmInputField ({ placeholder, onChange, isError, dataQa }: ConfirmInputFieldProps): JSX.Element {
  const classNames = clsx(
    'block w-full rounded-md sm:text-sm',
    isError
      ? 'pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500'
      : 'shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300'

  )

  return (
    <div className='relative rounded-md shadow-sm'>
      <Input
        onChange={onChange}
        placeholder={placeholder}
        type='text'
        name={`${dataQa ?? ''}-ConfirmActionFormOKTextInput`}
      />
      {isError && (
        <div className='absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none'>
          <ExclamationCircleIcon className='h-5 w-5 text-red-500' aria-hidden='true' />
        </div>
      )}
    </div>
  )
}

export function ValidationErrorText ({ message }: ValidationErrorTextProps): JSX.Element {
  return (
    <p className='mt-2 text-sm text-red-600' id='confirmation-error'>
      {message}
    </p>
  )
}

export function ConfirmButton (props: ButtonProps): JSX.Element {
  const classNames = clsx(
    'text-white',
    props.disabled !== true ? 'bg-red-600 hover:bg-red-700' : 'bg-red-300 hover:bg-red-300',
    props.className
  )

  return (
    <Button
      {...props}
      className={classNames}
      dataQa={`${props.dataQa ?? ''}-ConfirmActionFormSubmit`}
    />
  )
}
