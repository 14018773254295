import { CACHED_QUERY_PROPS } from 'constants/queries'
import { useQuery, UseQueryResult } from 'react-query'
import { Bookrunner, getBookrunners } from 'services/organizations'
import { useStores } from 'store/rootStore'

export function useGetBookrunners (): UseQueryResult<Bookrunner[]> {
  const { commonStore } = useStores()
  return useQuery<Bookrunner[]>('get-bookrunners', getBookrunners, {
    ...CACHED_QUERY_PROPS,
    staleTime: 30 * 60 * 1000,
    onSuccess: (data) => {
      commonStore.bookrunners = data
    }
  })
}
