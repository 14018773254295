import { WebSocketHook } from 'react-use-websocket/src/lib/types'
import { PublishingBank } from 'services/deals'

export enum AccountRole {
  DEFAULT_USER = 'DEFAULT_USER',
  TAAS = 'TAAS',
  ADMIN = 'ADMIN'
}

export enum AccountType {
  INVESTOR = 'INVESTOR',
  BANKER = 'BANKER',
  PRIMARY_PORTAL_AGENT = 'PRIMARY_PORTAL_AGENT',
  SELL_SIDE_SALES = 'SELL_SIDE_SALES'
}

export enum DistributionStatus {
  REG_S_ONLY = 'REG_S_ONLY',
  QIBS = 'QIBS'
}

export interface ValidationError {
  target?: Record<string, any>
  property: string
  value?: any
  constraints?: {
    [type: string]: string
  }
  children?: ValidationError[]
  contexts?: {
    [type: string]: any
  }
}

export interface Options {
  immediate?: boolean
  quickUpdate?: boolean
}

export interface IWebSocketHook extends WebSocketHook{
  sendJsonMessage: (jsonMessage: {
    service: string
    action: string
    payload?: any
  }, keep?: boolean) => void

}

export interface ISpecificPath {
  path: string
  compare: 'strict' | 'includes'
}

export interface IUseUnavailableModalProps {
  publishingBankNamesFull?: PublishingBank[]
  publishingBankNames?: string[]
  issuerName: string
}

export interface IUseLegacyViewTermsheetPageProps {
  id: string
  type: string
  issuerName: string
}
