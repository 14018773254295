import React, { useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { useStores } from 'store/rootStore'
import { equals, findIndex } from 'ramda'
import Tabs from 'components/Tabs/Tabs'
import { XIcon } from '@heroicons/react/solid'
import { Button } from '@ppui/ui-components'
import { DashboardRegion, TermsheetRegion } from 'constants/index'
import { useRegionList } from 'lib/regionHooks'
import TabContainer from 'components/CountryFilter/TabContainer'
import { useIsSpecificPathWatch } from 'lib/hooks'

export const CountryFilter: React.FC = observer((): JSX.Element | null => {
  const { commonStore, filterStore } = useStores()
  const regionList: TermsheetRegion[] = useRegionList(DashboardRegion.ALL)
  const isDashboardV2Path = useIsSpecificPathWatch([{
    path: '/ecm/dashboardv2',
    compare: 'includes'
  }])
  const defaultTabIndex = useMemo(() => {
    const index = findIndex(equals(commonStore.dashboardRegion as unknown as TermsheetRegion), regionList)
    return index === -1 ? 0 : index
  },
  [commonStore.dashboardRegion, regionList]
  )
  if (!filterStore.isFilterOpen) return null
  return (
    <div data-qa='CountryFilter' className='py-3 px-8 bg-white shadow-lg rounded-md my-2 mx-2'>
      <Tabs<TermsheetRegion, Record<'version', 'v1' | 'v2'>>
        additionalContainerProps={{ version: isDashboardV2Path ? 'v2' : 'v1' }}
        TabContainer={TabContainer}
        tabLabels={regionList}
        defaultActiveTabIndex={() => {
          filterStore.activeFilterTab = regionList[defaultTabIndex]
          return defaultTabIndex
        }}
        onTabChange={(value) => { filterStore.activeFilterTab = value }}
        rightComponent={(
          <>
            <Button
              theme='primary'
              onClick={() => { filterStore.resetFilter() }}
              label='Clear Filters'
              type='button'
              dataQa='CountryFilter-Clear'
            />
            <Button
              theme='secondary'
              onClick={() => { filterStore.isFilterOpen = false }}
              label='Close'
              type='button'
              rightIcon={<XIcon />}
              dataQa='CountryFilter-Close'
            />
          </>

      )}
      />
    </div>
  )
})
