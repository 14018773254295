import React from 'react'
import PropTypes from 'prop-types'

import { TimetableRender } from './TimetableRender'
import { getTimetableFieldsConfig, isPricingDate } from '../../../../config/formConfigs'

const termsheetValues = (offeringType, termsheetObject): any[] => {
  const getQuestions = (obj: any): any =>
    obj.map((item) => ({
      name: item.name,
      label: item.label
    }))
  return [...getQuestions(getTimetableFieldsConfig(offeringType, isPricingDate(termsheetObject.pricingDate)))]
}

export const getKeysFromTermsheetObject = (offeringType, termsheetObject): any[] => {
  const filteredValues: any[] = []
  termsheetValues(offeringType, termsheetObject).filter((item) =>
    Object.entries(termsheetObject).find(([key, value]) => {
      if (item.name === key) {
        return filteredValues.push({
          name: key,
          date: value,
          label: item.label
        })
      }
      return false
    })
  )
  return filteredValues
}

export function TermsheetTimetable ({ termsheetObject, readOnly }: { termsheetObject: any, readOnly?: boolean }): JSX.Element {
  const { offeringType } = termsheetObject

  return (
    <TimetableRender
      readOnly={readOnly}
      termsheetObject={termsheetObject}
      termsheetTimetableObject={getKeysFromTermsheetObject(offeringType, termsheetObject)}
    />
  )
}

TermsheetTimetable.propTypes = {
  termsheetObject: PropTypes.object
}
