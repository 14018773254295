import { state } from 'config/formTypes/inputFields'
import PropTypes from 'prop-types'
import { isEmpty, isNil } from 'ramda'
import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import { getAdditionalQuestionsConfig, getMainQuestionsConfig, isPricingDate } from '../../../../config/formConfigs'
import { TermsheetTableBodyStyled } from './styles'
import { BankSyndicatesRow } from './TermsheetTableRows/BankSyndicatesRow'
import { FullWidthRow } from './TermsheetTableRows/FullWidthRow'
import { ListingExchangeRow } from './TermsheetTableRows/ListingExchangeRow'
import { SellingShareholdersRow } from './TermsheetTableRows/SellingShareholdersRow'
import { SingleColumnRow } from './TermsheetTableRows/SingleColumnRow'
import { UsaDistributionRow } from './TermsheetTableRows/UsaDistributionRow'

const getKeysFromTermsheetObject = (data, offeringTypeAbbreviation): any[] => {
  const dataWithDashValues = addDashValueToEmptyAlwaysFields(data, offeringTypeAbbreviation)
  const filteredValues: any[] = []
  const labelListToNotDisplay: string[] = [state.label]
  termsheetValues(data, offeringTypeAbbreviation).filter((item) =>
    Object.entries(dataWithDashValues).find(([key, value]) => {
      if (item.name === key && !isNil(value) && !isEmpty(value) && !labelListToNotDisplay.includes(item.label)) {
        return filteredValues.push({
          key,
          value,
          label: item.label,
          type: item.type,
          rank: item.rank,
          tooltipText: item.tooltipText
        })
      }
      return false
    })
  )
  return filteredValues
}

const addDashValueToEmptyAlwaysFields = (obj, offeringTypeAbbreviation): any[] => {
  getMainQuestionsConfig(offeringTypeAbbreviation, true, obj.primarySecondaryString, obj.pricingDate, false).forEach((item) => {
    if (obj[item.name] === '' || obj[item.name] === undefined || obj[item.name] === null) {
      return (obj[item.name] = '-')
    }
  })
  return obj
}

const convertLabelToAlternative = (label): string => {
  if (label === 'Footer Disclaimer') {
    return 'Disclaimer'
  }
  return label
}

const termsheetValues = (termsheetObject, offeringTypeAbbreviation): any[] => {
  const getQuestions = (obj): any[] =>
    obj.map((item) => ({
      name: item.name,
      label: item.label,
      type: item.termsheetRowType,
      rank: item.termsheetRank?.[offeringTypeAbbreviation],
      tooltipText: item.termsheetTooltipText
    }))
  return [
    ...getQuestions(
      getMainQuestionsConfig(offeringTypeAbbreviation, true, termsheetObject.primarySecondaryString, isPricingDate(termsheetObject.itemPricedAtDate ?? termsheetObject.pricingDate), false)
    ),
    ...getQuestions(
      getAdditionalQuestionsConfig(offeringTypeAbbreviation, termsheetObject.primarySecondaryString, isPricingDate(termsheetObject.itemPricedAtDate ?? termsheetObject.pricingDate), termsheetObject.region)
    )
  ]
}

export const getSortedTermsheetEntries = (termsheetObject, offeringTypeAbbreviation): any[] =>
  getKeysFromTermsheetObject(termsheetObject, offeringTypeAbbreviation)
    .filter((a) => a.rank > 0 || a.rank === null)
    .sort((a, b) => (a.rank ?? 1e9) - (b.rank ?? 1e9))
    // TODO: Rewrite into normal fields in templates with deterministic order instead of sorting numbers.

export function GenerateTermsheetTableRows ({
  jointGlobalCoordinatorArray, termsheetObject,
  handleUnderwritingSyndicateModal, offeringTypeAbbreviation
}): JSX.Element {
  const selectTypeToRender = (termsheetKey, termsheetValue,
    termsheetLabel, termsheetRowType, tooltipText, isChanged: boolean): null | boolean|JSX.Element => {
    switch (termsheetRowType) {
      case 'singleColumnRow':
        return (
          <SingleColumnRow
            key={uuidv4()} label={termsheetLabel} termsheetValue={termsheetValue}
            tooltipText={tooltipText} isChanged={isChanged}
          />
        )
      case 'fullWidthRow':
        return (
          <FullWidthRow
            key={uuidv4()} termsheetObject={termsheetObject}
            label={termsheetLabel} name={termsheetKey} isChanged={isChanged}
          />
        )
      case 'sellingShareHolders':
        return <SellingShareholdersRow key={uuidv4()} termsheetObject={termsheetObject} isChanged={isChanged} />
      case 'otherSyndicateBanks':
        return (
          <BankSyndicatesRow
            key={uuidv4()}
            jointGlobalCoordinatorArray={jointGlobalCoordinatorArray}
            handleUnderwritingSyndicateModal={handleUnderwritingSyndicateModal}
            isChanged={isChanged}
          />
        )
      case 'issuerName':
        return (
          <SingleColumnRow
            key={uuidv4()}
            label={termsheetLabel}
            termsheetValue={termsheetValue}
            tooltipText={termsheetObject.issuerLegalName}
            isChanged={isChanged}
          />
        )
      case 'rumpOffering':
        return (
          termsheetValue === 'Yes' && (
            <SingleColumnRow
              key={uuidv4()}
              label={termsheetLabel}
              termsheetValue={termsheetValue}
              tooltipText={termsheetObject.issuerLegalName}
              isChanged={isChanged}
            />
          )
        )

      case 'distribution':
        return (
          <UsaDistributionRow
            key={uuidv4()}
            label={termsheetLabel}
            termsheetValue={termsheetValue}
            tooltipText={tooltipText[termsheetValue]}
            isChanged={isChanged}
          />
        )
      case 'listingExchange':
        return (
          <ListingExchangeRow
            key={uuidv4()}
            label={termsheetLabel}
            exchanges={termsheetValue}
            isChanged={isChanged}
            tooltipText={tooltipText}
          />
        )

      default:
        return null
    }
  }

  return (
    <>
      <TermsheetTableBodyStyled>
        {getSortedTermsheetEntries(termsheetObject, offeringTypeAbbreviation).map((termsheetEntry) =>
          selectTypeToRender(
            termsheetEntry.key,
            termsheetEntry.value,
            convertLabelToAlternative(termsheetEntry.label),
            termsheetEntry.type,
            termsheetEntry.tooltipText,
            termsheetEntry.isChanged
          )
        )}
      </TermsheetTableBodyStyled>
    </>
  )
}

GenerateTermsheetTableRows.propTypes = {
  jointGlobalCoordinatorArray: PropTypes.string.isRequired,
  termsheetObject: PropTypes.object.isRequired,
  handleUnderwritingSyndicateModal: PropTypes.func.isRequired,
  offeringTypeAbbreviation: PropTypes.string.isRequired
}
